import React, { useCallback, useEffect, useState } from 'react'
import { message, Modal } from 'antd'
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import ManageFiltersPage from './components/ManageFiltersPage'
import { SlpFilters } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import CreateFilter from './components/CreateFilter'

const getPageBreadcrumbs = () => [
    {
        path: '/monetisation/offerings/home-page',
        breadcrumbName: 'Home page',
    },
    {
        path: '/monetisation/offerings/manage-filters',
        breadcrumbName: 'Manage Filters',
    },
]

const ManageFilterScreen: React.FC = () => {
    const { networkInstance } = useAppContext()
    const { monetisationOfferingsApi } = networkInstance.clientWithHeaders
    const [showFilterCreation, setShowFilterCreation] = React.useState(false)
    const [isEditJourney, setIsEditJourney] = React.useState(false)

    const [loading, setLoading] = useState(false);
    const [filterList, setFilterList] = useState<SlpFilters[]>([])
    const [filter, setFilter] = useState<SlpFilters>()


    const getFilterList = useCallback(async () => {
        setLoading(true);
        try {
            const filters = await monetisationOfferingsApi?.slpFilterApi?.getFilters()
            setFilterList(filters?.data?.data)
        } catch (error) {
            message.error({
                content: 'Error while fetching filter List',
                duration: 3,
            })
            console.log(error)
        } finally {
            setLoading(false);
        }
    }, [])

    useEffect(() => {
        getFilterList();
    }, [getFilterList])

    const onCreateNew = () => {
        setShowFilterCreation(true)
        setIsEditJourney(false)
        setFilter(undefined)
    }

    const closeFilterCreationForm = () => {
        setShowFilterCreation(false)
        isEditJourney && setIsEditJourney(false)
        setFilter(undefined)
        getFilterList()
    }

    const triggerEdit = (filter: SlpFilters) => {
        setFilter(filter)
        setIsEditJourney(true)
        setShowFilterCreation(true)
    }

    return (
        <div style={{ padding: '0 40px' }}>
            <AdminBreadcrumb routes={getPageBreadcrumbs()} />
            <ManageFiltersPage
                loading={loading}
                onCreateNew={onCreateNew}
                triggerEdit={triggerEdit}
                dataSource={filterList}
            />
            {showFilterCreation && (
                <CreateFilter
                    showFilterCreation={showFilterCreation}
                    closeFilterCreation={closeFilterCreationForm}
                    isEditJourney={isEditJourney}
                    formData={filter}
                    filterList={filterList}
                />
            )
            }
        </div>
    )
}

export default withDefaultLayout(ManageFilterScreen)
