import { extractMediaUrls } from '@/utils/helper'
import { title } from 'process'

import {
  CreateStore,
  Product,
} from '@a4b/api/src/modules/Monetisation/Puja/types'

import {
  festivalHeroSectionMediaKeys,
  festivalSMExperienceMediaKeys,
  festivalServicesMediaKeys,
  festivalTrustMarkerMediaKeys,
  topCategoriesMediaKeys,
  valuePropsMediaKeys,
} from '../../../utils/sections-helper'

export const parseCreateSectionData = (
  formData: any,
  storeList: CreateStore[],
) => {
  console.log({ formData })
  let parsedFormData: any = {}
  const offeringStoreId = storeList
    ?.filter(store =>
      formData?.data?.today_importance?.offering_store_ids?.includes(store?.id),
    )
    ?.map(item => item?.id)
  const poojaStoreId = storeList
    ?.filter(store =>
      formData?.data?.today_importance?.pooja_store_ids?.includes(store?.id),
    )
    ?.map(item => item?.id)
  parsedFormData = {
    title: formData?.title,
    section_type: formData?.section_type,
    item_type: formData?.item_type,
    active: true,
    position: formData?.position,
    ...(formData?.target_config && {
      target_config: JSON.parse(formData?.target_config),
    }),
    ...(formData?.variant && { variant: formData?.variant }),
    ...(formData?.min_app_version && {
      min_app_version: Number(formData?.min_app_version),
    }),
    description: formData?.description,
    ...(formData?.data?.type === 'info_media' && {
      data: {
        type: formData?.data?.type,
        info_media: formData?.data?.info_media?.map(
          (info: any, index: number) => ({
            media_url: info?.media_url?.[0],
            media_type: info?.media_type,
            deeplink: info?.deeplink,
            thumbnail: info?.thumbnail?.[0] || '',
          }),
        ),
      },
    }),
    ...(formData?.data?.type === 'info_media_web' && {
      data: {
        type: formData?.data?.type,
        info_media_web: {
          title: formData?.data?.info_media_web?.title,
          sub_title: formData?.data?.info_media_web?.sub_title,
          media_list: formData?.data?.info_media_web?.media_list?.map(
            (info: any, index: number) => ({
              media_url: info?.media_url?.[0],
              media_url_desktop: info?.media_url_desktop?.[0],
              media_type: info?.media_type,
              deeplink: info?.deeplink,
              thumbnail: info?.thumbnail?.[0] || '',
            }),
          ),
        },
      },
    }),
    ...(formData?.data?.type === 'previous_pooja_media' && {
      data: {
        type: formData?.data?.type,
        media_cards: formData?.data?.media_cards?.map(
          (info: any, index: number) => ({
            media: {
              media_url:
                info?.media?.media_type === 'image'
                  ? info?.media?.media_url?.[0]
                  : info?.media?.media_url,
              media_type: info?.media?.media_type,
              media_thumbnail: info?.media?.media_thumbnail?.[0],
            },
            title: info?.title,
            sub_title: info?.sub_title,
          }),
        ),
      },
    }),
    ...(formData?.data?.type === 'banner_items' && {
      data: {
        type: formData?.data?.type,
        pooja_items: formData?.data?.pooja_items?.map(
          (info: any, index: number) => ({
            ...info,
            media_url: info?.media_url?.[0],
          }),
        ),
        offering_items: formData?.data?.offering_items?.map(
          (info: any, index: number) => ({
            ...info,
            media_url: info?.media_url?.[0],
          }),
        ),
      },
    }),
    ...(formData?.data?.type === 'today_importance' && {
      data: {
        type: formData?.data?.type,
        today_importance: {
          title: formData?.data?.today_importance?.title,
          sub_title: formData?.data?.today_importance?.sub_title,
          media: {
            media_type: formData?.data?.today_importance?.media?.media_type,
            media_url: formData?.data?.today_importance?.media?.media_url?.[0],
          },
          offerings_header: formData?.data?.today_importance?.offerings_header,
          pooja_header: formData?.data?.today_importance?.pooja_header,
          offering_store_ids: offeringStoreId,
          pooja_store_ids: poojaStoreId,
        },
      },
    }),
    ...(formData?.data?.type === 'srimandir_services' && {
      data: {
        type: formData?.data?.type,
        srimandir_services: formData?.data?.srimandir_services?.map(
          (item: any, index: number) => ({
            title: item?.title,
            sub_title: item?.sub_title,
            deeplink: item?.deeplink,
            media: {
              media_type: item?.media?.media_type,
              media_url: item?.media?.media_url?.[0],
            },
          }),
        ),
      },
    }),
    ...(formData?.data?.type === 'glimpses_of_services' && {
      data: {
        type: formData?.data?.type,
        media_cards: formData?.data?.media_cards?.map(
          (item: any, index: number) => ({
            title: item?.title,
            sub_title: item?.sub_title,
            media: {
              media_url: item?.media?.media_url?.[0],
              media_type: item?.media?.media_type,
              media_thumbnail: item?.media?.media_thumbnail?.[0],
            },
            service_type: item?.service_type,
            deeplink: item?.deeplink,
            icon_url: item?.icon_url?.[0],
          }),
        ),
      },
    }),
    ...((formData?.data?.type === 'associated_temples' ||
      formData?.data?.type === 'associated_pilgrims') && {
      data: {
        type: formData?.data?.type,
        media_cards: formData?.data?.media_cards?.map(
          (item: any, index: number) => ({
            title: item?.title,
            sub_title: item?.sub_title,
            media: {
              media_url: item?.media?.media_url?.[0],
              media_type: item?.media?.media_type,
            },
            service_type: item?.service_type,
            deeplink: item?.deeplink,
            icon_url: item?.icon_url?.[0],
          }),
        ),
      },
    }),
    ...(formData?.data?.type === 'previous_order_recommendation_card' && {
      data: {
        type: formData?.data?.type,
        banner_large_items: formData?.data?.banner_large_items?.map(
          (item: any, index: number) => {
            return {
              day: item?.day,
              day_number: item?.day_number,
              media: {
                media_url: item?.media?.media_url?.[0],
                media_thumbnail: '',
                media_type: item?.media?.media_type,
              },
              deeplink: item?.deeplink,
              god_name: item?.god_name,
              card_type: item?.card_type,
            }
          },
        ),
      },
    }),
    ...(formData?.data?.type === 'banner_large' && {
      data: {
        type: formData?.data?.type,
        banner_large: formData?.data?.banner_large?.map((item: any) => {
          return {
            media: {
              media_url: item?.media?.media_url?.[0],
              media_type: item?.media?.media_type,
            },
            deeplink: item?.deeplink,
          }
        }),
      },
    }),
    ...(formData?.data?.type === 'sanakalp_banner' && {
      data: {
        type: formData?.data?.type,
        sankalp_banner_items: formData?.data?.sankalp_banner?.map(
          (item: any) => {
            return {
              store_id: item?.store_id,
              inventory_id: item?.inventory_id,
            }
          },
        ),
      },
    }),
    ...(formData?.data?.type === 'highlighted_pooja' && {
      data: {
        type: formData?.data?.type,
        highlighted_pooja: formData?.data?.highlighted_pooja?.map(
          (item: any) => {
            return {
              deeplink: item?.deeplink,
              media: {
                media_url: item?.media?.media_url?.[0],
                media_type: item?.media?.media_type,
              },
            }
          },
        ),
      },
    }),
    ...(formData?.section_type === 'static_ui' &&
      formData?.item_type === 'festival_hero_section' && {
        data: extractMediaUrls(formData?.data, festivalHeroSectionMediaKeys),
      }),
    ...(formData?.section_type === 'static_ui' &&
      formData?.item_type === 'festival_sm_experience' && {
        data: extractMediaUrls(formData?.data, festivalSMExperienceMediaKeys),
      }),
    ...(formData?.section_type === 'static_ui' &&
      formData?.item_type === 'value_props' && {
        data: extractMediaUrls(formData?.data, valuePropsMediaKeys),
      }),
    ...(formData?.section_type === 'static_ui' &&
      formData?.item_type === 'festival_trust_marker' && {
        data: extractMediaUrls(formData?.data, festivalTrustMarkerMediaKeys),
      }),
    ...(formData?.section_type === 'static_ui' &&
      formData?.item_type === 'festival_services' && {
        data: extractMediaUrls(formData?.data, festivalServicesMediaKeys),
      }),
    ...(formData?.section_type === 'static_ui' &&
      formData?.item_type === 'text_section' && {
        data: {
          title: formData?.data?.title,
          description: formData?.data?.description,
        },
      }),
    ...(formData?.section_type === 'static_ui' &&
      formData?.item_type === 'top_categories_static' && {
        data: extractMediaUrls(formData?.data, topCategoriesMediaKeys),
      }),
  }
  console.log({ parsedFormData })
  return parsedFormData
}

export const parseInventoryList = (storeId: string, inventories: Product[]) => {
  const parsedInventories = inventories
    ?.filter((inventory: Product) => inventory?.item_type === 'sankalp')
    ?.map((inventory: Product) => ({
      value: inventory?.id,
      label: inventory?.title,
    }))
  return parsedInventories
}
