import { Form, Input, Row } from "antd"
import React from "react"
import TrustMedias from "./TrustMedias"
import Pointers from "./Pointers"
import S3Upload from "@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload"

interface Props {
    cdnUrl: string
    preSignUrl: string
    type: string
}

const FestivalTrustMarker: React.FC<Props> = ({ cdnUrl, preSignUrl, type }) => {
    return (
        <div style={{
            border: '1px dashed #e0dcdc',
            marginRight: '0px',
            width: '100%',
            padding: '10px',
        }}>
            <Row style={{ width: '100%' }} justify="space-between">
                <Form.Item
                    label={`Title`}
                    name={['data', 'title']}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ flexBasis: '40%' }}
                    rules={[
                        {
                            required: false,
                            message: 'Enter Title',
                        },
                    ]}
                >
                    <Input placeholder='Enter Title' />
                </Form.Item>

                <Form.Item
                    label={`Pointer Icon`}
                    name={['data', 'pointer_icon_url']}
                    labelCol={{ span: 32 }}
                    wrapperCol={{ span: 32 }}
                    rules={[
                        {
                            required: false,
                            min: 0,
                            max: 1,
                            validator: async (rules: any, values: any) => {
                                if (values?.length < rules.min) {
                                    return Promise.reject('Please upload Media')
                                }
                            },
                        },
                    ]}
                    valuePropName='fileList'
                >
                    <S3Upload
                        cdnUrl={cdnUrl}
                        preSignUrl={preSignUrl}
                        min={0}
                        max={1}
                    />
                </Form.Item>
            </Row>

            <div style={{ marginTop: '20px' }}>
                <span>Cards:</span>
                <Form.List name={['data', 'pointers']}>
                    {(fields, { add, remove, move }) => (
                        <>
                            <Row justify="space-between" style={{ width: '100%', marginTop: '10px' }}>
                                <Pointers
                                    onSortEnd={({ oldIndex, newIndex }) => {
                                        console.log('oldIndex', oldIndex, 'newIndex', newIndex)
                                        move(oldIndex, newIndex)
                                    }}
                                    distance={1}
                                    helperClass='sortableHelper'
                                    fields={fields}
                                    add={add}
                                    remove={remove}
                                    cdnUrl={cdnUrl}
                                    preSignUrl={preSignUrl}
                                    datatype='Pointers'
                                />
                            </Row>
                        </>
                    )}
                </Form.List>
                <Form.List name={['data', 'trust_medias']}>
                    {(fields, { add, remove, move }) => (
                        <>
                            <Row justify="space-between" style={{ width: '100%', marginTop: '10px' }}>
                                <TrustMedias
                                    onSortEnd={({ oldIndex, newIndex }) => {
                                        console.log('oldIndex', oldIndex, 'newIndex', newIndex)
                                        move(oldIndex, newIndex)
                                    }}
                                    distance={1}
                                    helperClass='sortableHelper'
                                    fields={fields}
                                    add={add}
                                    remove={remove}
                                    cdnUrl={cdnUrl}
                                    preSignUrl={preSignUrl}
                                    datatype='Trust Medias'
                                />
                            </Row>
                        </>
                    )}
                </Form.List>

                <hr style={{ marginTop: '20px', marginBottom: '20px', border: '1px dashed #e0dcdc' }} />
                <Form.Item
                    label={`BG Color`}
                    name={['data', 'metadata', 'bg_color']}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ flexBasis: '40%' }}
                    rules={[
                        {
                            required: false,
                            message: 'Enter BG Color',
                        },
                    ]}
                >
                    <Input placeholder='Enter BG Color' />
                </Form.Item>
                <Form.Item
                    label={`Top Info text`}
                    name={['data', 'metadata', 'top_info_text']}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ flexBasis: '40%' }}
                    rules={[
                        {
                            required: false,
                            message: 'Enter Top Info text',
                        },
                    ]}
                >
                    <Input placeholder='Enter Top Info text' />
                </Form.Item>
            </div>
        </div>
    )
}

export default FestivalTrustMarker