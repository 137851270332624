import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Spin, Col, Table, Tag } from 'antd'
import { useAppContext } from '@/components/AppContext'
import Error from '@/components/Error'
import { message } from 'antd'
import useTableConfig from './hooks/useTableConfig'
import { Category } from '@a4b/api/src/modules/Monetisation/Puja/types/categories'
import CategoryForm from './components/CategoryForm'
import ManageFilters from './components/ManageFilters'
import { useSearchParams } from 'react-router-dom'
import { A4B_APPS } from '@/utils/constants'
import CategoryListHeader from './components/CategoryListHeader'
import { TableProps } from 'antd/es/table'
import { CustomLoader } from '@/components/Loader'

interface DataSourceItem {
    id: string | number;
    view_type: string;
    [key: string]: any;
}

const ManageCategories = () => {
    const { networkInstance, app } = useAppContext()
    const [searchParams, setSearchParams] = useSearchParams()
    const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi
    const isSrimandir = app === A4B_APPS.SRIMANDIR

    const [categories, setCategories] = useState<Category[]>([])
    const [currentCategory, setCurrentCategory] = useState<Category>()

    const [errorPage, setErrorPage] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [showCategoryDrawer, setShowCategoryDrawer] = useState<boolean>(false)
    const [showFilterDrawer, setShowFilterDrawer] = useState<boolean>(false)

    const tableConfig = useTableConfig(categories, isLoading, setShowCategoryDrawer, setShowFilterDrawer, setCurrentCategory)

    const getPageBreadcrumbs = useMemo(() => {
        return [
            {
                path: isSrimandir ? '/monetisation/puja/temples' : '/astro/puja/temples',
                breadcrumbName: 'Manage Stores',
            },
            {
                path: isSrimandir ? '/monetisation/puja/filters' : '/astro/puja/filters',
                breadcrumbName: 'Manage Categories — Filters',
            },
        ];
    }, [isSrimandir])

    const getCategories = useCallback((async () => {
        try {
            setIsLoading(true)
            const categoryList = await monetisationPujaApi.categoriesApi.getCategories()
            setCategories(categoryList?.data?.data?.categories)
        } catch (error) {
            setErrorPage(true)
            message.error({
                content: 'Error while fetching Categories',
                duration: 3,
            })
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }), [monetisationPujaApi.categoriesApi])

    useEffect(() => {
        getCategories()
    }, [getCategories])

    const handleCloseCategoryForm = useCallback(((updateCategoryList = false) => {
        setShowCategoryDrawer(false)
        const newSearchParams = new URLSearchParams(searchParams.toString())
        newSearchParams.set('edit', 'false')
        setSearchParams(newSearchParams)
        updateCategoryList &&
            setTimeout(() => {
                getCategories()
            }, 500)
    }), [getCategories, searchParams, setSearchParams])

    const handleCloseFilterForm = useCallback(((updateCategoryList = false) => {
        setShowFilterDrawer(false)
        const newSearchParams = new URLSearchParams(searchParams.toString())
        newSearchParams.set('edit-filter', 'false')
        setSearchParams(newSearchParams)
        updateCategoryList &&
            setTimeout(() => {
                getCategories()
            }, 500)
    }), [getCategories, searchParams, setSearchParams])

    const dataSource = tableConfig?.dataSource || [];
    // Grouping data by `view_type`
    const groupedByViewType = dataSource.reduce<Record<string, DataSourceItem[]>>((acc, data) => {
        const viewType = data?.view_type;
        if (!acc[viewType]) acc[viewType] = [];
        acc[viewType].push(data);
        return acc;
    }, {});

    return (
        <div style={{ padding: '0 40px' }}>
            {errorPage ?
                <Error text='Error While fetching Category list' />
                :
                (isLoading ?
                    <Col style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '20%' }}>
                        <Spin indicator={<CustomLoader />} />
                    </Col>
                    :
                    <>
                        <AdminBreadcrumb routes={getPageBreadcrumbs} />
                        <CategoryListHeader
                            setShowCategoryDrawer={setShowCategoryDrawer}
                        />
                        {Object?.entries?.(groupedByViewType)?.map(([viewType, groupedData]) => {
                            const config: TableProps<any> = {
                                ...tableConfig,
                                pagination: false,
                                dataSource: groupedData,
                            }
                            return (
                                <div style={{ marginBottom: '20px' }}>
                                    <Tag color='#108ee9'>{viewType}</Tag>
                                    <Table
                                        key={viewType}
                                        {...config}
                                    />
                                </div>
                            )
                        })}
                        {
                            showCategoryDrawer && <CategoryForm showForm={showCategoryDrawer} currentCategory={currentCategory!} handleCloseForm={handleCloseCategoryForm} />
                        }
                        {
                            showFilterDrawer && <ManageFilters showForm={showFilterDrawer} handleCloseForm={handleCloseFilterForm} category={{ id: currentCategory?.id!, name: currentCategory?.name!, viewType: currentCategory?.view_type! }} />
                        }
                    </>
                )
            }
        </div>
    )
}
export default withDefaultLayout(ManageCategories)
