import { useCallback, useEffect, useState } from 'react';
import { Drawer, Row, Col, Button, Spin, Tag, message } from 'antd';
import FilterForm from '../FilterForm';
import FiltersList from '../FiltersList';
import { Filter } from '@a4b/api/src/modules/Monetisation/Puja/types/filters';
import { useSearchParams } from 'react-router-dom';
import { useAppContext } from '@/components/AppContext';
import { A4B_APPS } from '@/utils/constants';
import { CustomLoader } from '@/components/Loader';

interface ManageFiltersProps {
    showForm: boolean;
    handleCloseForm: (updateCategoryList?: boolean) => void;
    category: { id: string; name: string; viewType: string };
}

const ManageFilters: React.FC<ManageFiltersProps> = ({ showForm, handleCloseForm, category }) => {
    const { networkInstance, app } = useAppContext();
    const [currentFilter, setCurrentFilter] = useState<Filter | undefined>(undefined);
    const [activeFilterId, setActiveFilterId] = useState<string | null>(null)
    const [searchParams, setSearchParams] = useSearchParams()

    const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi;

    const [filters, setFilters] = useState<Filter[]>([]);
    const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(false);

    const getFilters = useCallback(async () => {
        try {
            setIsLoadingFilters(true);
            if (category?.id) {
                const filterList = await monetisationPujaApi.filtersApi.getFilters(category.id);
                setFilters(filterList.data.data.filters);
                setIsLoadingFilters(false);
            }
        } catch (error) {
            setIsLoadingFilters(false);
            message.error('Error while fetching Filters');
            console.log(error);
        }
    }, [category.id, monetisationPujaApi.filtersApi]);

    useEffect(() => {
        getFilters();
    }, [getFilters]);

    const handleFetchFilters = useCallback((() => {
        setIsLoadingFilters(true);
        setTimeout(() => {
            getFilters()
        }, 2000)
    }
    ), [getFilters])

    const handleResetFilter = useCallback(((fetchFilters: boolean) => {
        setCurrentFilter(undefined)
        const newSearchParams = new URLSearchParams(searchParams.toString())
        newSearchParams.set('edit-filter', 'false')
        setSearchParams(newSearchParams)
        setActiveFilterId(null)
        if (fetchFilters) {
            handleFetchFilters()
        }
    }), [handleFetchFilters, searchParams, setSearchParams])

    return (
        <Drawer
            title={
                <Row justify="space-between" style={{ width: '100%', alignItems: 'center' }}>
                    <span>Manage Filters</span>
                    <Tag color='blue'>{category?.name}</Tag>
                    <Button type="primary" onClick={() => handleResetFilter(false)}>New filter</Button>
                </Row>
            }
            placement="right"
            onClose={() => {
                const newSearchParams = new URLSearchParams(searchParams.toString())
                newSearchParams.set('edit-filter', 'false')
                setSearchParams(newSearchParams)
                handleCloseForm()
            }}
            visible={showForm}
            destroyOnClose={true}
            width={1300}
        >
            <Row justify="space-between" style={{ height: '100%', overflow: 'hidden' }}>
                {
                    isLoadingFilters ?
                        <Row justify='center' style={{ width: '100%', alignItems: 'center', height: '50vh' }}>
                            <Spin indicator={<CustomLoader />} />
                        </Row> :
                        <>
                            <Col span={10} style={{
                                height: '100%',
                                overflowY: 'auto',
                            }}>
                                <FiltersList category={category} filters={filters} isLoadingFilters={isLoadingFilters} updateCurrentFilter={setCurrentFilter} activeFilterId={activeFilterId} setActiveFilterId={setActiveFilterId} />
                            </Col>
                            <Col
                                span={12}
                                style={{
                                    height: '100%',
                                    overflowY: 'auto',
                                }}>
                                <FilterForm category={category} currentFilter={currentFilter} handleCloseForm={handleResetFilter} />
                            </Col>
                        </>
                }
            </Row>
        </Drawer>
    );
};

export default ManageFilters;
