/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { SelectOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Form, Input, Row, Select } from 'antd'
import { CreateStore, StoreCard } from '@a4b/api/src/modules/Monetisation/offerings/types'
import InfoMedia from './InfoMedia';
import PreviousPoojaMedia from './PreviousPoojaMedia';
import BannerItems from './BannerItems';
import TodayImportance from './TodayImportance';
import SrimandirService from './SrimandirService';
import GlimpsesOfServices from './GlimpsesOfServices';
import AssociatedTemples from './AssociatedTemples';
import PreviousOrderRecommendations from './PreviousOrderRecommendations';
import BannerLarge from './BannerLarge';
import SanakalpBanner from './SankalpBanner';
import HighlightedPooja from './HighlightedPooja';
import { arrayMove } from 'react-sortable-hoc';
import BannerItemsV2 from './BannerItemsV2';
import InfoMediaWeb from './InfoMediaWeb';

const { TextArea } = Input
const { Option } = Select
interface Props {
  onClose: () => void
  visible: boolean
  cdnUrl: string
  preSignUrl: string
  formSubmithandler: (values: any) => void
  form: any
  initialValues: StoreCard
  isEditJourney: boolean
  storeList: CreateStore[]
  onFormChange: (formValues: any) => void
  openSelectionWindow: () => void
  sortedData: any[]
  componentRender: any
  getInventoryList: (storeId: string) => void
  inventoryList: any
}
const dataTypes = [
  { value: 'info_media', label: 'Info media' },
  { value: 'info_media_web', label: 'Info media web' },
  { value: 'previous_pooja_media', label: 'Previous pooja media' },
  { value: 'banner_items', label: 'Banner items' },
  { value: 'today_importance', label: 'Today Importance' },
  { value: 'srimandir_services', label: 'Srimandir Service' },
  { value: 'glimpses_of_services', label: 'Glimpses of services' },
  { value: 'associated_temples', label: 'Associated temples' },
  { value: 'associated_pilgrims', label: 'Associated pilgrims' },
  { value: 'previous_order_recommendation_card', label: 'Previous order recommendation' },
  { value: 'banner_large', label: 'Banner Large' },
  { value: 'sankalp_banner', label: 'Sankalp banner' },
  { value: 'highlighted_pooja', label: 'Highlighted Pooja' },
  { value: 'promotional_banners', label: 'Promotional Banners' },
];

const sectionTypes = [
  { value: 'hero', label: 'Hero' },
  { value: 'category', label: 'Category' },
  { value: 'data', label: 'Data' },
  { value: 'list_item_small', label: 'List_Item_Small' },
  { value: 'list_item_large', label: 'List_Item_Large' },
];

const SectionCreation: React.FC<Props> = ({
  onClose,
  visible,
  cdnUrl,
  preSignUrl,
  formSubmithandler,
  form,
  initialValues,
  isEditJourney,
  storeList,
  onFormChange,
  openSelectionWindow,
  componentRender,
  sortedData,
  getInventoryList,
  inventoryList
}) => {
  return (
    <Drawer
      title={`${isEditJourney ? "Update" : "Create New"}  Section`}
      placement='right'
      onClose={onClose}
      visible={visible}
      destroyOnClose={true}
      width={900}
      size='large'
    >
      <Form
        form={form}
        layout='vertical'
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        initialValues={initialValues}
        onValuesChange={onFormChange}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label='Title'
              name='title'
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 22 }}
              rules={[{ required: true, message: 'Enter title' }]}
            >
              <Input placeholder='Enter title' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Description'
              name='description'
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 22 }}
              rules={[
                {
                  required: true,
                  message: 'Enter description of the category',
                },
              ]}
            >
              <TextArea placeholder='Enter description' rows={3} />
            </Form.Item>
          </Col>
          {isEditJourney ? (<>
            <Col span={12}>
              <Form.Item
                label='Section Type'
                name='section_type'
                rules={[
                  { required: true, message: 'Please select section type!' },
                ]}
              >
                <Select placeholder='Please select section type' disabled>
                  {sectionTypes.map(item => (
                    <Option key={item.value} value={item.value}>{item.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>) : (<>
            <Col span={12}>
              <Form.Item
                label='Section Type'
                name='section_type'
                rules={[
                  { required: true, message: 'Please select section type!' },
                ]}
              >
                <Select placeholder='Please select section type'>
                  {sectionTypes.map(item => (
                    <Option key={item.value} value={item.value}>{item.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>)}

          <Col span={12}>
            <Form.Item
              label={`Position`}
              name='position'
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 22 }}
              rules={[{ required: true, message: 'Please input position!' }]}
            >
              <Input type={'number'} placeholder={`position`} />
            </Form.Item>
          </Col>
          {isEditJourney ? (<>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.section_type !== currentValues.section_type
              }
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 22 }}
            >
              {({ getFieldValue }) =>
                (getFieldValue('section_type') === 'hero' || getFieldValue('section_type') === 'list_item_small' || getFieldValue('section_type') === 'list_item_large') && (
                  <Col span={12}>
                    <Form.Item
                      label='Item Type'
                      name='item_type'
                      rules={[
                        { required: true, message: 'Please select item type!' },
                      ]}
                    >
                      <Select placeholder='Please select item type' allowClear disabled>
                        <Option value={'sku'} key={'sku'}>
                          SKU
                        </Option>
                        <Option value={'store'} key={'store'}>
                          Store
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                )
              }
            </Form.Item>
          </>) : (<>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.section_type !== currentValues.section_type
              }
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 22 }}
            >
              {({ getFieldValue }) =>
                (getFieldValue('section_type') === 'hero' || getFieldValue('section_type') === 'list_item_small' || getFieldValue('section_type') === 'list_item_large') && (
                  <Col span={12}>
                    <Form.Item
                      label='Item Type'
                      name='item_type'
                      rules={[
                        { required: true, message: 'Please select item type!' },
                      ]}
                    >
                      <Select placeholder='Please select item type' allowClear>
                        <Option value={'sku'} key={'sku'}>
                          SKU
                        </Option>
                        <Option value={'store'} key={'store'}>
                          Store
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                )
              }
            </Form.Item>
          </>)}

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return prevValues.section_type !== currentValues.section_type
            }
            }
            labelCol={{ span: 22 }}
            wrapperCol={{ span: 22 }}
          >
            {({ getFieldValue }) =>
              getFieldValue('section_type') === 'data' && (
                <Col span={12}>
                  <Form.Item
                    label='Item Type'
                    name='item_type'
                  >
                    <Input value="data" defaultValue="data" key={"data"} disabled />
                  </Form.Item>
                </Col>
              )
            }
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.section_type !== currentValues.section_type
            }
            labelCol={{ span: 22 }}
            wrapperCol={{ span: 22 }}
          >
            {({ getFieldValue }) =>
              getFieldValue('section_type') === 'data' && (
                <Col span={12}>
                  <Form.Item
                    label='Data type'
                    name={['data', 'type']}
                    rules={[{ required: true, message: 'Enter Data type' }]}
                  >
                    <Select placeholder='Please Data type' style={{ maxHeight: '200px', overflowY: 'auto', width: '183%' }}>
                      {dataTypes.map(item => (
                        <Option key={item.value} value={item.value}>{item.label}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )
            }
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.section_type !== currentValues.section_type
            }
            labelCol={{ span: 22 }}
            wrapperCol={{ span: 22 }}
          >
            {({ getFieldValue }) =>
              (getFieldValue('section_type') === 'data') && (
                <>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => {
                      return prevValues?.data?.type !== currentValues?.data?.type
                    }

                    }
                    labelCol={{ span: 22 }}
                    wrapperCol={{ span: 22 }}
                  >{({ getFieldValue }) => {
                    if (getFieldValue('data')?.type === 'info_media') {
                      return <>
                        <Form.List name={['data', 'info_media']}>
                          {(fields, { add, remove }) => {
                            return (
                              <InfoMedia fields={fields} add={add} remove={remove} cdnUrl={cdnUrl} preSignUrl={preSignUrl} />
                            )
                          }}
                        </Form.List>
                      </>
                    } else if (getFieldValue('data')?.type === 'info_media_web') {
                      return (
                        <InfoMediaWeb cdnUrl={cdnUrl} preSignUrl={preSignUrl} type={'info_media_web'} />
                      )
                    } else if (getFieldValue('data')?.type === 'previous_pooja_media') {
                      return <>
                        <Form.List name={['data', 'media_cards']}>
                          {(fields, { add, remove }) => {
                            return (
                              <PreviousPoojaMedia fields={fields} add={add} remove={remove} cdnUrl={cdnUrl} preSignUrl={preSignUrl} />
                            )
                          }}
                        </Form.List>
                      </>
                    } else if (getFieldValue('data')?.type === 'banner_items') {
                      return <>
                        <Form.List name={['data', 'pooja_items']}>
                          {(fields, { add, remove }) => {
                            return (
                              <>
                                <BannerItems fields={fields} add={add} remove={remove} cdnUrl={cdnUrl} preSignUrl={preSignUrl} type='Add Pooja Items' />
                              </>
                            )
                          }}
                        </Form.List>
                        <Form.List name={['data', 'offering_items']}>
                          {(fields, { add, remove }) => {
                            return (
                              <>
                                <BannerItems fields={fields} add={add} remove={remove} cdnUrl={cdnUrl} preSignUrl={preSignUrl} type='Add Offering Items' />
                              </>
                            )
                          }}
                        </Form.List>
                      </>
                    } else if (getFieldValue('data')?.type === 'today_importance') {
                      return <TodayImportance storeList={storeList} cdnUrl={cdnUrl} preSignUrl={preSignUrl} />
                    } else if (getFieldValue('data')?.type === 'srimandir_services') {
                      return <>
                        <Form.List name={['data', 'srimandir_services']}>
                          {(fields, { add, remove }) => {
                            return (
                              <SrimandirService fields={fields} add={add} remove={remove} cdnUrl={cdnUrl} preSignUrl={preSignUrl} />
                            )
                          }}
                        </Form.List>
                      </>
                    } else if (getFieldValue('data')?.type === 'glimpses_of_services') {
                      return <>
                        <Form.List name={['data', 'media_cards']}>
                          {(fields, { add, remove }) => {
                            return (
                              <GlimpsesOfServices fields={fields} add={add} remove={remove} cdnUrl={cdnUrl} preSignUrl={preSignUrl} datatype='Glimpses of services' />
                            )
                          }}
                        </Form.List>
                      </>
                    } else if (getFieldValue('data')?.type === 'associated_temples') {
                      return <>
                        <Form.List name={['data', 'media_cards']}>
                          {(fields, { add, remove }) => {
                            return (
                              <AssociatedTemples fields={fields} add={add} remove={remove} cdnUrl={cdnUrl} preSignUrl={preSignUrl} datatype='Associated temples' />
                            )
                          }}
                        </Form.List>
                      </>
                    } else if (getFieldValue('data')?.type === 'associated_pilgrims') {
                      return <>
                        <Form.List name={['data', 'media_cards']}>
                          {(fields, { add, remove }) => {
                            return (
                              <AssociatedTemples fields={fields} add={add} remove={remove} cdnUrl={cdnUrl} preSignUrl={preSignUrl} datatype='Associated pilgrims' />
                            )
                          }}
                        </Form.List>
                      </>
                    } else if (getFieldValue('data')?.type === 'previous_order_recommendation_card') {
                      return <>
                        <Form.List name={['data', 'banner_large_items']}>
                          {(fields) => {
                            return (
                              <PreviousOrderRecommendations fields={fields} cdnUrl={cdnUrl} preSignUrl={preSignUrl} />
                            )
                          }}
                        </Form.List>
                      </>
                    }
                    else if (getFieldValue('data')?.type === 'banner_large') {
                      return <>
                        <Form.List name={['data', 'banner_large_items']}>
                          {(fields, { add, remove }) => {
                            return (
                              <BannerLarge fields={fields} add={add} remove={remove} cdnUrl={cdnUrl} preSignUrl={preSignUrl} datatype='Banner Large' />
                            )
                          }}
                        </Form.List>
                      </>
                    } else if (getFieldValue('data')?.type === 'highlighted_pooja') {
                      return <>
                        <Form.List name={['data', 'highlighted_pooja']}>
                          {(fields, { add, remove, move }) => {
                            return (
                              <HighlightedPooja
                                onSortEnd={({ oldIndex, newIndex }) => {
                                  console.log('oldIndex', oldIndex, 'newIndex', newIndex)
                                  move(oldIndex, newIndex)
                                }}
                                distance={1}
                                helperClass='sortableHelper'
                                form={form}
                                fields={fields}
                                add={add}
                                remove={remove}
                                cdnUrl={cdnUrl}
                                preSignUrl={preSignUrl}
                                datatype='Highlighted Pooja'
                              />
                            )
                          }}
                        </Form.List>
                      </>
                    }
                    else if (getFieldValue('data')?.type === 'sankalp_banner') {
                      return <>
                        <Form.List name={['data', 'sankalp_banner_items']}>
                          {(fields, { add, remove }) => {
                            return (
                              <SanakalpBanner fields={fields} add={add} remove={remove} storeList={storeList} getInventoryList={getInventoryList} inventoryList={inventoryList} />
                            )
                          }}
                        </Form.List>
                      </>
                    }
                    else if (getFieldValue('data')?.type === 'promotional_banners') {
                      return <>
                        <Form.List name={['data', 'promotional_banners']}>
                          {(fields, { add, remove, move }) => {
                            return (
                              <BannerItemsV2
                                onSortEnd={({ oldIndex, newIndex }) => {
                                  console.log('oldIndex', oldIndex, 'newIndex', newIndex)
                                  move(oldIndex, newIndex)
                                }}
                                distance={1}
                                helperClass='sortableHelper'
                                form={form}
                                fields={fields}
                                add={add}
                                remove={remove}
                                cdnUrl={cdnUrl}
                                preSignUrl={preSignUrl}
                                datatype='Promotional Banners' />
                            )
                          }}
                        </Form.List>
                      </>
                    }
                  }}
                  </Form.Item>
                </>
              )
            }

          </Form.Item>

          <Col span={12}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.item_type !== currentValues.item_type ||
                prevValues.section_type !== currentValues.section_type
              }
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 22 }}
            >
              {({ getFieldValue }) => {
                return (getFieldValue('section_type') === 'hero' || getFieldValue('section_type') === 'list_item_small' || getFieldValue('section_type') === 'list_item_large') &&
                  getFieldValue('item_type') === 'store' && (
                    <Button
                      onClick={openSelectionWindow}
                      style={{ marginTop: '20px' }}
                      type='primary'
                      icon={<SelectOutlined />}
                    >
                      Select Store
                    </Button>
                  )
              }
              }
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.item_type !== currentValues.item_type ||
                prevValues.section_type !== currentValues.section_type
              }
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 22 }}
            >
              {({ getFieldValue }) =>
                (getFieldValue('section_type') === 'hero' || getFieldValue('section_type') === 'list_item_small' || getFieldValue('section_type') === 'list_item_large') &&
                getFieldValue('item_type') == 'sku' && (
                  <Button
                    onClick={openSelectionWindow}
                    style={{ marginTop: '20px' }}
                    type='primary'
                    icon={<SelectOutlined />}
                  >
                    Select Store Card
                  </Button>
                )
              }
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.section_type !== currentValues.section_type
              }
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 22 }}
            >
              {({ getFieldValue }) =>
                getFieldValue('section_type') == 'category' && (
                  <Button
                    onClick={openSelectionWindow}
                    style={{ marginTop: '20px' }}
                    type='primary'
                    icon={<SelectOutlined />}
                  >
                    Select Category
                  </Button>
                )
              }
            </Form.Item>
          </Col>

        </Row>

        {sortedData?.length > 0 && <Form.Item
          labelCol={{ span: 22 }}
          wrapperCol={{ span: 22 }}
        >
          <Row>
            <Col span={6}></Col>
            <Col span={18} style={{ height: '300px', overflow: 'scroll' }}>
              {sortedData?.map(item => (
                <Col key={item?.id} span={24}>
                  {React.cloneElement(componentRender, { data: item })}
                </Col>
              ))}
            </Col>
          </Row>
        </Form.Item>}


        <Form.Item
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ paddingTop: '30px' }}
        >
          {isEditJourney ? (
            <Button type='primary' onClick={formSubmithandler}>
              Update Item
            </Button>
          ) : (
            <Button type='primary' onClick={formSubmithandler}>
              Create Item
            </Button>
          )}
        </Form.Item>
      </Form>
    </Drawer >
  )
}
export default SectionCreation
