/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import type { TablePaginationConfig } from 'antd/es/table'
import { ExclamationCircleOutlined, EyeOutlined, UploadOutlined, WarningTwoTone, CalendarOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
  Tag,
  Typography,
} from 'antd'
import {
  Batch,
  BatchMapData,
  ManageTemple,
  Product,
  MetaData,
  SKUFilters,
  Users,
  GenieVideoQCResponse,
  GenieVideoQCData,
  Store
} from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants'
import S3Upload, { AllowedFormats } from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'

const { Option } = Select;
const { Search } = Input

interface Props {
  itemList: Batch[]
  meta_data: MetaData | undefined
  onSearch: (value: string) => void
  onViewBatchClick: (batch: Batch) => void
  generateBatch: (isForcefully: boolean) => void
  handleTableChange: (paginationParams: TablePaginationConfig) => void
  pagination: TablePaginationConfig
  total: number
  skuFilters: SKUFilters[] | undefined
  onTabChange: (activeTab: string) => void
  isComplete: boolean
  onCompleteChange: () => void
  reducedItemCount: any
  batchCount: number
  handleCompletedVideoPreview: (videoUrl: string, product_type: string, record: any) => void
  languageSelected: string
  isGenerateBatch: boolean
  handleClickUploadMedia: () => void
  showUploadButton: boolean
  selectionType: string
  rowSelection: any
  handleCreateDelivery: (record: any) => void
  isDeliveryOrderCreated: boolean
  hubAssociates: any
  updateAssociateForBatch: (batchId: string, associateId: string) => void
  handleStoreMediaModal: () => void
}

interface DataType {
  key: React.Key
  name: string
  age: number
  address: string
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const BatchListPage: React.FC<Props> = ({
  itemList,
  onSearch,
  onViewBatchClick,
  generateBatch,
  handleTableChange,
  pagination,
  total,
  skuFilters,
  onTabChange,
  isComplete,
  onCompleteChange,
  reducedItemCount,
  batchCount,
  handleCompletedVideoPreview,
  meta_data,
  languageSelected,
  isGenerateBatch,
  handleClickUploadMedia,
  showUploadButton,
  selectionType,
  rowSelection,
  handleCreateDelivery,
  isDeliveryOrderCreated,
  hubAssociates,
  updateAssociateForBatch,
  handleStoreMediaModal,
}) => {

  const ShowClickPostError = ({ error }: any) => {
    const [visible, setVisible] = useState(false)
    const showErrorDetailsModal = () => {
      setVisible(true)
    }

    const hideErrorDetailsModal = () => {
      setVisible(false)
    }

    const subTableColumns: any = [
      {
        title: 'Order Id',
        dataIndex: 'order_id',
        key: 'order_id',
        align: 'center',
      },
      {
        title: 'Booking Id',
        dataIndex: 'booking_id',
        key: 'booking_id',
        align: 'center',
      },
      {
        title: 'Error',
        dataIndex: 'click_post_error',
        key: 'click_post_error',
        align: 'center',
      },
    ]
    return (
      <>
        {(error && error?.length) ? <div style={{ width: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '5px', cursor: 'pointer' }} onClick={showErrorDetailsModal}><WarningTwoTone style={{ fontSize: '24px' }} twoToneColor="red" /></div> : <div></div>}
        <Modal
          title="Click Post Error"
          visible={visible}
          onCancel={hideErrorDetailsModal}
          footer={null}
        >
          <Table
            columns={subTableColumns}
            dataSource={error}
            pagination={false} />
        </Modal>
      </>
    );
  }

  const [isVideoQCModalVisible, setIsVideoQCModalVisible] = React.useState<boolean>(false)
  const [videoQCResponseData, setVideoQCResponseData] = React.useState<GenieVideoQCResponse[]>([])
  const [selectedBatch, setSelectedBatch] = React.useState<string | null>(null)

  const showVideoQCModal = (batch: Batch) => () => {
    const genieVideoQCData = (batch?.meta_data as { genie_video_qc_data?: GenieVideoQCData })?.genie_video_qc_data
    const genieVideoQCResponse = genieVideoQCData?.genie_video_qc_response ?? []

    setSelectedBatch(batch.batch_code)
    setVideoQCResponseData(genieVideoQCResponse)
    setIsVideoQCModalVisible(true);
  }

  const handleCancel = () => {
    setIsVideoQCModalVisible(false)
  }

  const commonColumns = [
    {
      title: 'Batch Id/Batch Code',
      dataIndex: 'batch_id',
      key: 'batch_id',
      width: '9%',
      filtered: true,
      // fixed: 'left',
      render: (text: any, record: Batch) => <span>{record?.batch_code || record?.batch_id}</span>,
    },
    {
      title: 'No of Items',
      dataIndex: 'no_of_items',
      key: 'no_of_items',
      width: '4%',
    },
    {
      title: 'SKU Name',
      dataIndex: 'product_name',
      key: 'product_name',
      // width: '9%',
      render: (text: any, record: Batch) => <span>{record?.inventory_names?.[languageSelected] || text}</span>,
    },
    // {
    //   title: 'Unbatched Count',
    //   width: '10%',
    //   ellipsis: true,
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   render: (text: any, record: Batch) => {
    //     const filterInventory: any = Object.keys(meta_data?.batch_map || {})?.map((id: string) => { return id?.includes(record?.inventory_id) && meta_data?.batch_map?.[id] }).filter((item: any) => item)
    //     return <span>{filterInventory?.[0]?.count ? <Tag color='red'>{filterInventory?.[0]?.count}</Tag> : <Tag color='green'>Empty</Tag>}</span>
    //   },
    // },
    {
      title: 'SLA Breach Status',
      dataIndex: 'is_sla_breached',
      key: 'is_sla_breached',
      width: '9%',
      render: (text: boolean, record: Batch) => {
        if (text) {
          return <Tag icon={<ExclamationCircleOutlined />} color='#f50'>SLA Breach</Tag>
        } else {
          return 'N/A'
        }
      }
    },
    {
      title: 'Temple Name',
      dataIndex: 'store_name',
      key: 'store_name',
      width: '13%',
      render: (text: any, record: Batch) => <span>{record?.store_names?.[languageSelected] || text}</span>,
    },
    {
      title: 'Product Type',
      dataIndex: 'product_type',
      key: 'product_type',
      width: '6%',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '8%',
      render: (text: any) => (
        <span>{moment.unix(text).format('ddd DD-MMM-YYYY, hh:mm A')}</span>
      ),
    },
    {
      title: 'Schedule Date',
      dataIndex: 'schedule_date_unix',
      key: 'schedule_date_unix',
      width: '8%',
      render: (text: any, record: Batch) => {
        const unixTimestamp = record?.schedule_date_unix;
        const now = moment().unix();
        return unixTimestamp !== 0 ? (
          <div>
            <span>{moment.unix(unixTimestamp)?.format('ddd DD-MMM-YYYY, hh:mm A')}</span>
            {unixTimestamp > now && (
              <Tag icon={<CalendarOutlined />} color='green' style={{ marginTop: '4px' }}>PreBooking</Tag>
            )}
          </div>
        ) : (
          <span>-</span>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '7%',
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: 'Hub Associate',
      dataIndex: 'associate_id',
      key: 'associate_id',
      width: '11%',
      render: (text: any, record: Batch, index: number) => (
        <Select value={text} onChange={(value) => updateAssociateForBatch(record?.batch_id, value)} style={{ width: '100%', maxWidth: '180px' }} disabled={record?.status !== 'created'}>
          {hubAssociates?.map((associate: Users) => (
            <Option key={associate?.id} value={associate?.id}>
              {associate?.name} {associate?.user_type === 'hub_manager' && (
                <span style={{ color: 'orange' }}>(Hub Manager)</span>
              )}
            </Option>
          ))}
        </Select>
      )
    },
    {
      title: 'Video QC Status',
      key: 'video_qc_status',
      render: (text: any, record: Batch) => {
        const genieVideoQCData = (record?.meta_data as { genie_video_qc_data?: GenieVideoQCData })?.genie_video_qc_data
        const videoQCStatus = genieVideoQCData?.video_qc_status

        if (!videoQCStatus) {
          return null;
        }

        let statusTag;
        if (videoQCStatus === 'invalid_video') {
          statusTag = <Tag color="red" style={{ cursor: 'pointer' }} onClick={showVideoQCModal(record)}>Invalid Video</Tag>
        } else if (videoQCStatus === 'semi_valid_video') {
          statusTag = <Tag color="orange" style={{ cursor: 'pointer' }} onClick={showVideoQCModal(record)}>Semi-Valid Video</Tag>
        } else if (videoQCStatus === 'valid_video') {
          statusTag = <Tag color="green" style={{ cursor: 'pointer' }} onClick={showVideoQCModal(record)}>Valid Video</Tag>
        } else if (videoQCStatus === 'super_invalid_video') {
          statusTag = <Tag color="#820014" style={{ cursor: 'pointer' }} onClick={showVideoQCModal(record)}>Super Invalid Video</Tag>
        }

        const columns = [
          {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
          },
          {
            title: 'Status',
            dataIndex: 'is_covered',
            key: 'status',
            render: (isCovered: boolean) => (isCovered ? 'Covered' : 'Not Covered'),
          },
          {
            title: 'Timestamp',
            dataIndex: 'timestamp',
            key: 'timestamp',
          }
        ]

        return (
          <>
            {statusTag}
            <Modal
              title={`Offering Usernames QC Details for Batch Code: ${selectedBatch}`}
              visible={isVideoQCModalVisible}
              onCancel={handleCancel}
              footer={null}
              centered
              width={600}
              closable={true}
            >
              <Table
                bordered
                columns={columns}
                dataSource={videoQCResponseData}
              />
            </Modal>
          </>
        )
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (text: any, record: any, index: any) => {
        const videoUrl = record?.meta_data?.processed_video_url || record?.video_url;
        return (
          <Row>
            <Col span={12}>
              {record.status === 'completed' ? (
                videoUrl ? (
                  <Button
                    type='link'
                    onClick={() => handleCompletedVideoPreview(videoUrl, record?.media_type, record)}
                  >
                    <EyeOutlined />
                    Preview Video
                  </Button>
                ) : (
                  <span>Video not available</span>
                )
              ) : (
                <Button type='link' onClick={() => onViewBatchClick(record)}>
                  <EyeOutlined />
                  View Order
                </Button>
              )}
            </Col>
          </Row>
        )
      },
    },
    // {
    //   title: 'Create Delivery Orders',
    //   dataIndex: 'Create Delivery Orders',
    //   key: 'Create Delivery Orders',
    //   width: '9%',
    //   render: (text: string, record: any) => {
    //     const clickPostErrors = record?.meta_data?.click_post_errors?.length
    //     if (!record?.delivery_initiated && record?.product_type === 'pooja' && clickPostErrors) {
    //       return <Row style={{ width: '100%' }} justify='space-between'>
    //         <Button type='primary' onClick={() => handleCreateDelivery(record)}>Generate</Button>
    //         <ShowClickPostError error={record?.meta_data?.click_post_errors} />
    //       </Row>
    //     } else if (!record?.delivery_initiated && record?.product_type === 'pooja') {
    //       return <Button type='primary' onClick={() => handleCreateDelivery(record)}>Generate</Button>
    //     } else {
    //       return <span>-</span>
    //     }
    //   },
    // }
  ]

  return (
    <Container style={{ width: '100%', height: '100%' }}>
      {showUploadButton && <Button
        type="primary"
        shape="circle"
        icon={<UploadOutlined />}
        size='large'
        style={{
          position: 'fixed', bottom: 40, right: 30,
          zIndex: 100,
          height: '50px',
          width: '50px'
        }}
        onClick={handleClickUploadMedia}
      />}
      <Space direction={'vertical'} style={{
        width: '100%'
      }}>
      </Space>
      <Row>
        <Col span={12}></Col>
        <Col span={12}>
          <Row justify='end'></Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs
            onChange={onTabChange}
            type='card'
            tabBarExtraContent={{
              left: (
                <Search
                  placeholder='search by batch code'
                  onSearch={(searchCrieteria: string) => {
                    onSearch(searchCrieteria)
                  }}

                  style={{ width: 380 }}
                  allowClear
                />
              ),
              right: (
                <Row style={{ width: 420 }} justify={'center'}>
                  <Col span={14}>
                    <Space direction='horizontal'>
                      <Typography.Title level={5}>
                        Show Completed Batch :{' '}
                        <Switch
                          checked={isComplete}
                          onChange={onCompleteChange}
                        ></Switch>
                      </Typography.Title>
                    </Space>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={9}>
                    <Button type="primary" onClick={handleStoreMediaModal} style={{marginBottom: 5}}>
                      Manage Store Media
                    </Button>
                    <Button
                      type='primary'
                      onClick={() => generateBatch(true)}
                      disabled={isGenerateBatch}
                    >
                      Generate Batch
                    </Button>
                  </Col>
                </Row>
              ),
            }}
            centered
            defaultActiveKey='1'
          >
            <Tabs.TabPane tab={`(${batchCount})All SKU`} key='all_sku'>
              <Row style={{ paddingTop: '10px' }}>
                <Col span={24}>
                  <Table
                    rowSelection={{
                      type: selectionType,
                      ...rowSelection,
                    }}
                    rowKey={(record: Batch) => record?.batch_id}
                    columns={commonColumns}
                    dataSource={itemList}
                    pagination={pagination}
                    onChange={handleTableChange}
                  // scroll={{ x: 2400 }}
                  // size='large'
                  />
                </Col>
              </Row>
            </Tabs.TabPane>
            {skuFilters?.map((product: SKUFilters) => (
              <Tabs.TabPane
                tab={
                  reducedItemCount?.[product?.sku_id]
                    ? `(${reducedItemCount?.[product?.sku_id]})${product?.sku_name}`
                    : product?.sku_name
                }
                key={product.sku_id}
              >
                <Row style={{ paddingTop: '10px' }}>
                  <Col span={24}>
                    <Table
                      rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                      }}
                      rowKey={(record: Batch) => record?.batch_id}
                      columns={commonColumns}
                      dataSource={itemList}
                      pagination={pagination}
                      onChange={handleTableChange}
                    // scroll={{ x: 2400 }}
                    // size='large'
                    />
                  </Col>
                </Row>
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </Container>
  )
}

export default BatchListPage
