import React from "react";
import { Form, Input, Row, Select, Col, Button } from "antd";
import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload';
import { DeleteOutlined, HolderOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

const { Option } = Select;

interface InfoMediaWebProps {
    cdnUrl: string;
    preSignUrl: string;
    type: string;
}

interface BannersProps {
    fields: any;
    cdnUrl: string;
    preSignUrl: string;
    add: () => void;
    remove: (item: any) => void;
    datatype: string;
}

interface FormItemsProps {
    index: number;
    field: any;
    remove: (item: any) => void;
    cdnUrl: string;
    preSignUrl: string;
    itemIndex: number;
}

const FormItems: React.FC<FormItemsProps> = (props) => {
    const { itemIndex, field, remove, cdnUrl, preSignUrl } = props;

    return (
        <div style={{
            border: '1px solid #e0dcdc',
            marginBottom: '10px',
            padding: '10px 0px',
            width: '100%',
            position: 'relative',
            backgroundColor: 'white',
            cursor: 'grab',
        }}>
            <Row style={{ width: '100%', justifyContent: 'space-between', alignItems: "start", paddingLeft: '10px', paddingRight: "10px" }}>
                <HolderOutlined />
                <Row style={{ justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
                    <Form.Item
                        label={`Deeplink ${itemIndex + 1}`}
                        name={[field.name, 'deeplink']}
                        labelCol={{ span: 28 }}
                        wrapperCol={{ span: 28 }}
                        style={{ flexBasis: '85%' }}
                    >
                        <Input placeholder='Enter deeplink' />
                    </Form.Item>
                    <Button
                        style={{ flexBasis: '8%' }}
                        onClick={() => remove(field.name)}
                        type='ghost'
                        icon={<DeleteOutlined />}
                        size='middle'
                        danger
                    ></Button>
                </Row>

                <Row style={{ width: '100%' }} justify="space-between">
                    <Col span={4}>
                        <Form.Item
                            label={`Media type ${itemIndex + 1}`}
                            name={[field.name, 'media_type']}
                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 28 }}
                        >
                            <Select placeholder='Please Select Media type'>
                                <Option value={'image'}>Image</Option>
                                <Option value={'video'}>Video</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Form.Item
                        label={`Media`}
                        name={[field.name, 'media_url']}
                        labelCol={{ span: 28 }}
                        rules={[
                            {
                                required: true,
                                min: 1,
                                max: 1,
                                validator: async (rules: any, values: any) => {
                                    if (values?.length < rules.min) {
                                        return Promise.reject('Please upload Media');
                                    }
                                },
                            },
                        ]}
                        valuePropName='fileList'
                    >
                        <S3Upload
                            cdnUrl={cdnUrl}
                            preSignUrl={preSignUrl}
                            min={1}
                            max={1}
                        />
                    </Form.Item>

                    <Form.Item
                        label={`Media Desktop`}
                        name={[field.name, 'media_url_desktop']}
                        labelCol={{ span: 28 }}
                        rules={[
                            {
                                required: true,
                                min: 1,
                                max: 1,
                                validator: async (rules: any, values: any) => {
                                    if (values?.length < rules.min) {
                                        return Promise.reject('Upload Media');
                                    }
                                },
                            },
                        ]}
                        valuePropName='fileList'
                    >
                        <S3Upload
                            cdnUrl={cdnUrl}
                            preSignUrl={preSignUrl}
                            min={1}
                            max={1}
                        />
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues?.data?.info_media_web?.media_list?.[itemIndex]?.media_type !==
                            currentValues?.data?.info_media_web?.media_list?.[itemIndex]?.media_type
                        }
                    >
                        {({ getFieldValue }) =>
                            getFieldValue(['data'])?.info_media_web?.media_list?.[itemIndex]?.media_type === 'video' && (
                                <Form.Item
                                    label={`Thumbnail`}
                                    name={[field.name, 'thumbnail']}
                                    labelCol={{ span: 28 }}
                                    rules={[
                                        {
                                            required: true,
                                            min: 0,
                                            max: 1,
                                            validator: async (rules: any, values: any) => {
                                                if (values?.length < rules.min) {
                                                    return Promise.reject('Please upload Thumbnail');
                                                }
                                            },
                                        },
                                    ]}
                                    valuePropName='fileList'
                                >
                                    <S3Upload
                                        cdnUrl={cdnUrl}
                                        preSignUrl={preSignUrl}
                                        min={1}
                                        max={1}
                                    />
                                </Form.Item>
                            )
                        }
                    </Form.Item>
                </Row>
            </Row>
        </div>
    );
};

const SortableFormItems = SortableElement(FormItems);

const Banners: React.FC<BannersProps> = ({ fields, add, remove, cdnUrl, preSignUrl, datatype }) => {
    return (
        <>
            <Row justify="space-between" style={{ width: '100%' }}>
                {fields.map((field: any, index: number) => (
                    <SortableFormItems
                        key={field.key}
                        index={index}
                        itemIndex={index}
                        field={field}
                        remove={remove}
                        cdnUrl={cdnUrl}
                        preSignUrl={preSignUrl}
                    />
                ))}
                <Col span={24} style={{ paddingTop: '10px' }}>
                    <Row justify='start'>
                        <Col span={4}>
                            <Button
                                onClick={() => add()}
                                type='ghost'
                                icon={<PlusCircleOutlined />}
                                size='middle'
                            >
                                {datatype}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

const SortableBanners = SortableContainer(Banners);

const InfoMediaWeb: React.FC<InfoMediaWebProps> = ({ cdnUrl, preSignUrl, type }) => {
    return (
        <>
            <Form.Item
                label="Title"
                name={['data', 'info_media_web', 'title']}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ flexBasis: '40%' }}
                rules={[
                    {
                        required: true,
                        message: 'Enter Title',
                    },
                ]}
            >
                <Input placeholder='Enter Title' />
            </Form.Item>

            <Form.Item
                label="Sub Title"
                name={['data', 'info_media_web', 'sub_title']}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ flexBasis: '45%' }}
            >
                <Input placeholder='Please type Sub Title' />
            </Form.Item>

            <Form.List name={['data', 'info_media_web', 'media_list']}>
                {(fields, { add, remove, move }) => (
                    <>
                        <Row justify="space-between" style={{ width: '100%' }}>
                            <SortableBanners
                                onSortEnd={({ oldIndex, newIndex }) => {
                                    move(oldIndex, newIndex);
                                }}
                                distance={1}
                                helperClass='sortableHelper'
                                fields={fields}
                                add={add}
                                remove={remove}
                                cdnUrl={cdnUrl}
                                preSignUrl={preSignUrl}
                                datatype="Info media web"
                            />
                        </Row>
                    </>
                )}
            </Form.List>
        </>
    );
};

export default InfoMediaWeb;
