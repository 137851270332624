import { Form, Row, Col, Button, Input, Select } from "antd"
import React from "react"
import S3Upload from '@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload'
import { DeleteOutlined, HolderOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import { YODA_CDN_URL, PUJA_PRESIGN_URL } from '@/utils/constants'

interface Props {
    fields: any
    add: () => void
    remove: (item: any) => void
    datatype: string
}

interface FormItemsProps {
    index: number
    field: any
    remove: (item: any) => void
    itemIndex: number
}
const FormItems = (props: FormItemsProps) => {
    const { itemIndex, field, remove } = props;
    return (
        <div style={{
            border: '1px solid #e0dcdc',
            marginRight: '0px',
            marginBottom: '10px',
            padding: '10px 0px',
            width: '100%',
            position: 'relative',
            zIndex: 10000000,
            backgroundColor: 'white',
            cursor: 'grab'
        }}>
            <Row style={{ width: '100%', justifyContent: 'space-between', alignItems: "start", paddingLeft: '10px', paddingRight: "10px" }}>
                <HolderOutlined />
                <Row style={{ justifyContent: 'space-between', width: '100%', marginTop: '20px', marginBottom: '10px' }}>
                    <Col span={10}>
                        <strong>Media:</strong>
                        <Form.Item
                            label={`Deeplink`}
                            name={[field.name, 'media', 'deeplink']}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ flexBasis: '40%', marginTop: '20px' }}
                            rules={[
                                {
                                    required: false,
                                    message: 'Enter Deeplink',
                                },
                            ]}
                        >
                            <Input placeholder='Enter Deeplink' />
                        </Form.Item>
                        <Form.Item
                            label={`Media type`}
                            name={[field.name, 'media', 'media_type']}
                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 28 }}
                            style={{ flexBasis: '20%' }}
                        >
                            <Select placeholder='Please Select Media type' style={{ width: '100%' }}>
                                <Select.Option value={'image'} key={'image'}>
                                    Image
                                </Select.Option>
                                <Select.Option value={'video'} key={'video'}>
                                    Video
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        <Row justify="space-between">
                            <Form.Item
                                label={`Media`}
                                name={[field.name, 'media', 'media_url']}
                                labelCol={{ span: 32 }}
                                wrapperCol={{ span: 32 }}
                                rules={[
                                    {
                                        required: false,
                                        min: 0,
                                        max: 1,
                                        validator: async (rules: any, values: any) => {
                                            if (values?.length < rules.min) {
                                                return Promise.reject(' Media')
                                            }
                                        },
                                    },
                                ]}
                                valuePropName='fileList'
                            >
                                <S3Upload
                                    cdnUrl={YODA_CDN_URL}
                                    preSignUrl={PUJA_PRESIGN_URL}
                                    min={0}
                                    max={1}
                                />
                            </Form.Item>
                            <Form.Item
                                label={`Thumbnail`}
                                name={[field.name, 'media', 'thumbnail']}
                                labelCol={{ span: 32 }}
                                wrapperCol={{ span: 32 }}
                                rules={[
                                    {
                                        required: false,
                                        min: 0,
                                        max: 1,
                                        validator: async (rules: any, values: any) => {
                                            if (values?.length < rules.min) {
                                                return Promise.reject(' Thumbnail')
                                            }
                                        },
                                    },
                                ]}
                                valuePropName='fileList'
                            >
                                <S3Upload
                                    cdnUrl={YODA_CDN_URL}
                                    preSignUrl={PUJA_PRESIGN_URL}
                                    min={0}
                                    max={1}
                                />
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col span={10}>
                        <strong>Media desktop:</strong>
                        <Form.Item
                            label={`Deeplink`}
                            name={[field.name, 'media_desktop', 'deeplink']}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ flexBasis: '40%', marginTop: '20px' }}
                            rules={[
                                {
                                    required: false,
                                    message: 'Enter Deeplink',
                                },
                            ]}
                        >
                            <Input placeholder='Enter Deeplink' />
                        </Form.Item>
                        <Form.Item
                            label={`Media type`}
                            name={[field.name, 'media_desktop', 'media_type']}
                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 28 }}
                            style={{ flexBasis: '20%' }}
                        >
                            <Select placeholder='Please Select Media type' style={{ width: '100%' }}>
                                <Select.Option value={'image'} key={'image'}>
                                    Image
                                </Select.Option>
                                <Select.Option value={'video'} key={'video'}>
                                    Video
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        <Row justify="space-between">
                            <Form.Item
                                label={`Media`}
                                name={[field.name, 'media_desktop', 'media_url']}
                                labelCol={{ span: 32 }}
                                wrapperCol={{ span: 32 }}
                                rules={[
                                    {
                                        required: false,
                                        min: 0,
                                        max: 1,
                                        validator: async (rules: any, values: any) => {
                                            if (values?.length < rules.min) {
                                                return Promise.reject(' Media')
                                            }
                                        },
                                    },
                                ]}
                                valuePropName='fileList'
                            >
                                <S3Upload
                                    cdnUrl={YODA_CDN_URL}
                                    preSignUrl={PUJA_PRESIGN_URL}
                                    min={0}
                                    max={1}
                                />
                            </Form.Item>
                            <Form.Item
                                label={`Thumbnail`}
                                name={[field.name, 'media_desktop', 'thumbnail']}
                                labelCol={{ span: 32 }}
                                wrapperCol={{ span: 32 }}
                                rules={[
                                    {
                                        required: false,
                                        min: 0,
                                        max: 1,
                                        validator: async (rules: any, values: any) => {
                                            if (values?.length < rules.min) {
                                                return Promise.reject(' Thumbnail')
                                            }
                                        },
                                    },
                                ]}
                                valuePropName='fileList'
                            >
                                <S3Upload
                                    cdnUrl={YODA_CDN_URL}
                                    preSignUrl={PUJA_PRESIGN_URL}
                                    min={0}
                                    max={1}
                                />
                            </Form.Item>
                        </Row>
                    </Col>
                    <Button
                        style={{ flexBasis: '8%' }}
                        onClick={() => {
                            remove(field.name)
                        }}
                        type='ghost'
                        icon={<DeleteOutlined />}
                        size='middle'
                        danger
                    ></Button>
                </Row>
            </Row>
        </div>
    )
}

const SortableFormItems = SortableElement(FormItems);

const Categories: React.FC<Props> = ({ fields, add, remove, datatype }) => {
    return (
        <>
            <Row justify="space-between" style={{ width: '100%' }}>
                {fields && fields.map((field: any, index: number) => {
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <SortableFormItems
                            index={index}
                            itemIndex={index}
                            field={field}
                            remove={remove}
                        />
                    )
                })}
                <Col span={24} style={{ paddingTop: '10px' }}>
                    <Row justify='start'>
                        <Col span={4}>
                            <Button
                                onClick={() => {
                                    add()
                                }}
                                type='ghost'
                                icon={<PlusCircleOutlined />}
                                size='middle'
                            >
                                {datatype}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default SortableContainer(Categories)