//hooks
import { useEffect, useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

//context
import { useAppContext } from '@/components/AppContext'

//hoc
import withDefaultLayout from '@/hoc/WithDefaultLayout'

import { Space, Card, Button, message, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form'
import { PlusSquareOutlined } from '@ant-design/icons';
import styled from 'styled-components';

//types
import { CreateStore, StoreContent } from '@a4b/api/src/modules/Monetisation/Offerings/types'

//organisms
import ManageSchedulerPage from '@a4b/ui/src/modules/monetisation/organisms/ManageSchedulerPage'
import { parseStoreData, parseStorList } from './helper'
import CreateScheduler from '../CreateScheduler';


const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
  .ant-card-body {
    flex-grow: 1;
  }
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`
const CreateButon = styled(Button)`
  width: 12%;
  display: flex;
  margin-left: 10px;
  background-color: #1677ff;
  color: white;
  justify-content: center;

  &:hover {
    border-color: #1677ff;
    color: #1677ff;
  }
`

const ManageScheduler = () => {
    const { networkInstance, languageState, countryState } = useAppContext()
    const { monetisationOfferingsApi } = networkInstance.clientWithHeaders
    const [StoreList, setStoreList] = useState<any>([])
    const [showUserSchedulerCreation, setShowUserSchedulerCreation] = useState<boolean>(false)
    const [editScheduler, setEditScheduler] = useState<any>()
    const [isEdit, setEdit] = useState<boolean>(false)
    const [selectedValue, setSelectedValue] = useState({
        value: null,
        state: false
    });
    const [storeContentListData, setStoreContentListData] = useState<StoreContent>()
    const location = useLocation();
    const { pathname } = location;
    const [createSchedulerForm] = useForm()

    const getStoreList = useCallback((async () => {
        try {
            const storeList = await monetisationOfferingsApi.storesApi.getStoreList(null, 'dropdown')
            const parsedStoreList = parseStorList(storeList?.data?.data?.store_list?.sort((item1: any, item2: any) => item1.position - item2.position))
            setStoreList(parsedStoreList)
            const positionData: any = {}
            storeList?.data?.data?.store_list.map(
                (item: CreateStore) => (positionData[item.id] = item?.position || 0),
            )
        } catch (error) {
            message.error({
                content: 'Error while fetching store list',
                duration: 3,
            })
            console.log(error)
        }
    }), [monetisationOfferingsApi.storesApi])

    const getStoreContent = useCallback((async () => {
        try {
            const storeContentList = await monetisationOfferingsApi.schedulerApi.getStoreContent(
                languageState?.monetisationLanguage,
                pathname.split("/")[5],
                countryState?.countryCode
            )
            setStoreContentListData(storeContentList?.data?.data)
        } catch (error) {
            if (typeof error === 'object' && error !== null && 'response' in error && typeof (error as any).response === 'object') {
                const response = (error as any).response;
                if (response.status === 500) {
                    message.error({
                        content: 'Error while fetching store content list',
                        duration: 3,
                    });
                }
            }
            console.log(error)
        }
    }), [countryState?.countryCode, languageState?.monetisationLanguage, monetisationOfferingsApi.schedulerApi, pathname])

    const triggerCreate = async (storeId: string) => {
        try {
            const storeResponse = await monetisationOfferingsApi.storesApi.getStoreById(
                storeId,
                countryState?.countryCode
            )
            const parsedData = parseStoreData(storeResponse?.data?.data, languageState)
            createSchedulerForm.setFieldsValue(parsedData)
            setEditScheduler(parsedData)
            setShowUserSchedulerCreation(true)
            setEdit(false)
        } catch (error) {
            console.log("Error: ", error)
        }
    }

    const triggerEdit = (record: any) => {
        const parsedData = parseStoreData(record, languageState)
        createSchedulerForm.setFieldsValue(parsedData)
        setEditScheduler(parsedData)
        setShowUserSchedulerCreation(true)
        setEdit(true)
    }

    useEffect(() => {
        getStoreList()
    }, [getStoreList, languageState.monetisationLanguage])

    useEffect(() => {
        getStoreContent()
    }, [getStoreContent])

    const closeItemCreationForm = () => {
        setShowUserSchedulerCreation(false)
        createSchedulerForm.resetFields()
        setEditScheduler(undefined)
        setTimeout(() => {
            getStoreContent()
        }, 500)
    }

    const onCreateNew = () => {
        if (selectedValue?.value) {
            triggerCreate(selectedValue?.value)
            setShowUserSchedulerCreation(true)
            setEditScheduler(undefined)
        }
    }

    return (
        <div style={{ padding: '0px 40px' }}>
            <Container>
                <Space direction={'vertical'} style={{ width: '100%' }}></Space>
                <BodyContainer>
                    <div style={{ display: 'flex', marginBottom: '15px' }}>
                        <Select
                            showSearch
                            style={{ width: '25%' }}
                            placeholder="Search to Select Store"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label as string)?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                ((optionA?.name as string) || '')?.toLowerCase()?.localeCompare((optionB?.name as string) || '')
                            }
                            options={StoreList}
                            onChange={(value) => {
                                setSelectedValue({
                                    ...selectedValue,
                                    value: value,
                                    state: true
                                });
                            }}
                        />
                        <CreateButon type='text' onClick={onCreateNew} disabled={!selectedValue?.state}>
                            <span><PlusSquareOutlined /></span>
                            <span style={{ marginLeft: '5px' }}>Create Scheduler</span>
                        </CreateButon>
                    </div>
                    <ManageSchedulerPage
                        storeContentList={storeContentListData}
                        triggerEdit={triggerEdit}
                    />
                </BodyContainer>
            </Container>
            <CreateScheduler
                showItemCreation={showUserSchedulerCreation}
                closeItemCreation={closeItemCreationForm}
                form={createSchedulerForm}
                initialValues={editScheduler}
                isEdit={isEdit}
                selectedValue={selectedValue}
            />
        </div>
    )
}

export default withDefaultLayout(ManageScheduler)
