import moment from 'moment'

export const parseDataOnEdit = (sectionResponse: any) => {
  switch (sectionResponse?.data?.data?.data?.type) {
    case 'info_media':
      const infoMedia = sectionResponse?.data?.data?.data?.info_media.map(
        (item: any) => ({
          ...item,
          media_url: [item?.media_url],
          thumbnail: [item?.thumbnail],
        }),
      )
      const parsedInfoMedia = {
        ...sectionResponse?.data?.data,
        ...{
          data: { ...sectionResponse?.data?.data?.data, info_media: infoMedia },
        },
      }
      if (parsedInfoMedia?.data?.data?.section_type === 'data') {
        parsedInfoMedia.data.data.item_type = 'data'
      }
      return parsedInfoMedia

    case 'info_media_web':
      const infoMediaWeb = {
        title: sectionResponse?.data?.data?.data?.info_media_web?.title,
        sub_title: sectionResponse?.data?.data?.data?.info_media_web?.sub_title,
        media_list:
          sectionResponse?.data?.data?.data?.info_media_web?.media_list?.map((item: any) => ({
            ...item,
            media_url_desktop: [item?.media_url_desktop],
            media_url: [item?.media_url],
            thumbnail: [item?.thumbnail],
          })) || [],
      }
      const parsedInfoMediaWeb = {
        ...sectionResponse?.data?.data,
        ...{
          data: { ...sectionResponse?.data?.data?.data, info_media_web: infoMediaWeb },
        },
      }
      if (parsedInfoMediaWeb?.data?.data?.section_type === 'data') {
        parsedInfoMediaWeb.data.data.item_type = 'data'
      }
      console.log('parsedInfoMediaWeb', parsedInfoMediaWeb)
      return parsedInfoMediaWeb

    case 'previous_pooja_media':
      const previousMedia = sectionResponse?.data?.data?.data?.media_cards.map(
        (item: any, i: number) => ({
          ...item,
          media: {
            ...item?.media,
            media_url: [item?.media?.media_url],
            media_thumbnail: [item?.media?.media_thumbnail],
          },
        }),
      )
      const parsedPreviousPoojaMedia = {
        ...sectionResponse?.data?.data,
        ...{
          data: {
            ...sectionResponse?.data?.data?.data,
            media_cards: previousMedia,
          },
        },
      }
      if (parsedPreviousPoojaMedia?.data?.data?.section_type === 'data') {
        parsedPreviousPoojaMedia.data.data.item_type = 'data'
      }
      return parsedPreviousPoojaMedia

    case 'banner_items':
      const poojaItems = sectionResponse?.data?.data?.data?.pooja_items?.map(
        (item: any) => ({ ...item, media_url: [item?.media_url] }),
      )
      const offeringItems =
        sectionResponse?.data?.data?.data?.offering_items?.map((item: any) => ({
          ...item,
          media_url: [item?.media_url],
        }))
      const parsedBannerItems = {
        ...sectionResponse?.data?.data,
        ...{
          data: {
            ...sectionResponse?.data?.data?.data,
            pooja_items: poojaItems,
            offering_items: offeringItems,
          },
        },
      }
      if (parsedBannerItems?.data?.data?.section_type === 'data') {
        parsedBannerItems.data.data.item_type = 'data'
      }
      return parsedBannerItems

    case 'today_importance':
      const parsedTodayImportance = {
        ...sectionResponse?.data?.data,
        ...{
          data: {
            ...sectionResponse?.data?.data?.data,
            today_importance: {
              ...sectionResponse?.data?.data?.data?.today_importance,
              media: {
                ...sectionResponse?.data?.data?.data?.today_importance?.media,
                media_url: [
                  sectionResponse?.data?.data?.data?.today_importance?.media
                    ?.media_url,
                ],
              },
            },
          },
        },
      }
      return parsedTodayImportance

    case 'srimandir_services':
      const srimandirServices =
        sectionResponse?.data?.data?.data?.srimandir_services.map(
          (item: any, i: number) => ({
            ...item,
            media: {
              ...item?.media,
              media_url: [item?.media?.media_url],
              media_type: [item?.media?.media_type],
            },
            icon_url: [item?.icon_url],
          }),
        )
      const parsedSrimandirServices = {
        ...sectionResponse?.data?.data,
        ...{
          data: {
            ...sectionResponse?.data?.data?.data,
            srimandir_services: srimandirServices,
          },
        },
      }
      if (parsedSrimandirServices?.data?.data?.section_type === 'data') {
        parsedSrimandirServices.data.data.item_type = 'data'
      }
      return parsedSrimandirServices

    case 'glimpses_of_services':
      const glimpsesOfServices =
        sectionResponse?.data?.data?.data?.media_cards.map(
          (item: any, i: number) => ({
            ...item,
            media: {
              ...item?.media,
              media_url: [item?.media?.media_url],
              media_thumbnail: [item?.media?.media_thumbnail],
            },
          }),
        )
      const parsedGlimpsesOfServices = {
        ...sectionResponse?.data?.data,
        ...{
          data: {
            ...sectionResponse?.data?.data?.data,
            media_cards: glimpsesOfServices,
          },
        },
      }
      if (parsedGlimpsesOfServices?.data?.data?.section_type === 'data') {
        parsedGlimpsesOfServices.data.data.item_type = 'data'
      }
      return parsedGlimpsesOfServices

    case 'associated_temples':
      const associatedTemples =
        sectionResponse?.data?.data?.data?.media_cards.map(
          (item: any, i: number) => ({
            ...item,
            media: {
              ...item?.media,
              media_url: [item?.media?.media_url],
            },
          }),
        )
      const parcedAssociatedTemples = {
        ...sectionResponse?.data?.data,
        ...{
          data: {
            ...sectionResponse?.data?.data?.data,
            media_cards: associatedTemples,
          },
        },
      }
      if (parcedAssociatedTemples?.data?.data?.section_type === 'data') {
        parcedAssociatedTemples.data.data.item_type = 'data'
      }
      return parcedAssociatedTemples

    case 'associated_pilgrims':
      const associatedPilgrims =
        sectionResponse?.data?.data?.data?.media_cards.map(
          (item: any, i: number) => ({
            ...item,
            media: {
              ...item?.media,
              media_url: [item?.media?.media_url],
            },
          }),
        )
      const parsedAssociatedPilgrims = {
        ...sectionResponse?.data?.data,
        ...{
          data: {
            ...sectionResponse?.data?.data?.data,
            media_cards: associatedPilgrims,
          },
        },
      }
      if (parsedAssociatedPilgrims?.data?.data?.section_type === 'data') {
        parsedAssociatedPilgrims.data.data.item_type = 'data'
      }
      return parsedAssociatedPilgrims

    case 'previous_order_recommendation_card':
      const previousOrder =
        sectionResponse?.data?.data?.data?.banner_large_items?.map(
          (item: any, i: number) => ({
            ...item,
            media: {
              ...item?.media,
              media_url: [item?.media?.media_url],
              media_thumbnail: [item?.media?.media_thumbnail],
            },
          }),
        )
      const parsedPreviousOrder = {
        ...sectionResponse?.data?.data,
        ...{
          data: {
            ...sectionResponse?.data?.data?.data,
            banner_large_items: previousOrder,
          },
        },
      }
      if (parsedPreviousOrder?.data?.data?.section_type === 'data') {
        parsedPreviousOrder.data.data.item_type = 'data'
      }
      return parsedPreviousOrder

    case 'banner_large':
      const bannerLarge = sectionResponse?.data?.data?.data?.banner_large_items?.map(
        (item: any) => ({
          ...item,
          media: {
            ...item?.media,
            media_url: [item?.media?.media_url],
            media_type: item?.media?.media_type,
          },
        }),
      )

      const parsedBannerLarge = {
        ...sectionResponse?.data?.data,
        ...{
          data: {
            ...sectionResponse?.data?.data?.data,
            banner_large_items: bannerLarge,
          },
        },
      }
      if (parsedBannerLarge?.data?.data?.section_type === 'data') {
        parsedBannerLarge.data.data.item_type = 'data'
      }
      return parsedBannerLarge

    case 'promotional_banners':
      const bannerItemsV2 =
        sectionResponse?.data?.data?.data?.promotional_banners?.map((item: any) => ({
          ...item?.media,
          media_url: [item?.media_url],
          media_type: item?.media_type,
          deeplink: item?.deeplink,
          expire_by: moment(
            moment(item?.expire_by).format('YYYY-MM-DD HH:mm:ss'),
          ).utcOffset(0),
        }))

      const parsedBannerItemsV2 = {
        ...sectionResponse?.data?.data,
        ...{
          data: {
            ...sectionResponse?.data?.data?.data,
            promotional_banners: bannerItemsV2,
          },
        },
      }
      if (parsedBannerItemsV2?.data?.data?.section_type === 'data') {
        parsedBannerItemsV2.data.data.item_type = 'data'
      }

      return parsedBannerItemsV2

    default:
      if (sectionResponse?.data?.data?.section_type === 'data') {
        sectionResponse.data.data.item_type = 'data'
      }
      return sectionResponse?.data?.data
  }
}
