import { useAppContext } from '@/components/AppContext'
import Property from '@/entities/feeds/Property'
import Schedule from '@/entities/feeds/Schedule'
import {
  Action,
  ActionInstance,
  ActionList,
  ActionShortcuts,
  ActionV2,
  AppShare,
  ContentCollection,
  HeroArticle,
  HeroContent,
  HeroSong,
  HeroVideo,
  MenuAppVersion,
  MenuLogin,
  MenuNotification,
  MiniMandir,
  Panchang,
  SongPlaylist,
  YoutubeLive,
  YoutubeSchedule,
} from '@/entities/feeds/Widgets'
import Greeting from '@/entities/feeds/Widgets/Greeting'
import Social from '@/entities/feeds/Widgets/Social'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import {
  ActionListWidget,
  ActionShortcutsWidget,
  ActionV2Widget,
  ActionWidget,
  AppShareWidget,
  ContentCollectionWidget,
  HeroArticleWidget,
  HeroContentWidget,
  HeroSongWidget,
  HeroVideoWidget,
  ImageWidget,
  MenuAppVersionWidget,
  MenuLoginWidget,
  MenuNotificationWidget,
  MiniMandirWidget,
  PanchangWidget,
  SongPlaylistWidget,
  YoutubeLiveWidget,
  YoutubeScheduleWidget,
} from '@/modules/feeds/pages/widgets'
import useFetch from '@/modules/social/hooks/useFetch'
// import { getPlatforms, getProperties } from '@/usecases/views/common'
// import {
//   createSchedule,
//   editSchedulesById,
//   getCtaActionType,
//   getCtaRedirectionType,
//   getSchedulesById,
// } from '@/usecases/views/schedules'
// import { searchByWidgetCode } from '@/usecases/views/widgets'
import {
  getAllDaysSelectionValues,
  getWidgetEntityInstance,
  getWidgetsNameAndClass,
} from '@/utils/helper'
import { Content, SelectOptions } from '@/utils/types'
import { RollbackOutlined } from '@ant-design/icons'
import { Button, Divider, Form, Popconfirm, message, notification } from 'antd'
import styled from 'styled-components'

import { useCallback, useEffect, useRef, useState } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

import PageLayout from '../../components/PageLayout'
import CreateScheduleForm from '../../pages/CreateScheduleForm'
import GreetingWidget from '../../pages/widgets/homeFeeds/GreetingWidget'
import SocialWidget from '../../pages/widgets/homeFeeds/SocialWidget'
import { CreateStore } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { SEARCH_BY_UC_CODE_FOR_UPLOAD } from '../../../../network/apiRoutes'

interface Props { }
const WidgetWrapper = styled.div`
  padding-right: 30px;
  padding-left: 30px;
`

const CreateSchedule: React.FC<Props> = () => {
  const ScheduleInstance = useRef<Schedule>(new Schedule())

  const { userProfile, networkInstance } = useAppContext()
  const { clientWithHeaders,
    // community
  } = networkInstance
  const { riddler } = clientWithHeaders;
  const routeMatch = useMatch('/feeds/schedule/edit/:id')

  const [widgetNameAndClass, setWidgetNameAndClass] = useState<any>()

  const [autoCompleteOptions, setAutoCompleteOptions] = useState<any>([])

  const [platformList, setPlatformList] = useState<SelectOptions[]>([])
  const [propertyList, setPropertyList] = useState<SelectOptions[]>([])

  const [isEditJourney, setPageJourneyType] = useState(false)

  const [selectedWidgetId, setWidgetId] = useState('')
  const [validDaysPreData, setValidDaysPreData] = useState<string[]>([])
  const [isScheduleActive, setScheduleStatus] = useState(false)
  const [ctaActionType, setCtaActionType] = useState<any[]>([])
  const [ctaRedirectionType, setCtaRedirectionType] = useState<any[]>([])
  const [storeList, setStoreList] = useState<CreateStore[]>([])
  const [scheduleFormData, setScheduleFormData] = useState<Schedule>()
  const {
    data: communities,
    error,
    loading,
  } = useFetch(networkInstance.clientWithHeaders.communityOldApi.fetchCommunities)

  const editSchedulesById = (data: any, id: string) => {
    let schedulesPromise = new Promise(async (resolve, reject) => {
      try {
        let schedulesResponse =
          await networkInstance.clientWithHeaders.feeds.editSchedulesById(data, id);
        resolve(true);
      } catch (error: any) {
        reject(error?.response?.data.error || error);
      }
    });
    return schedulesPromise;
  };
  const createSchedule = (data: any) => {
    let schedulesPromise = new Promise(async (resolve, reject) => {
      try {
        let schedulesResponse = await networkInstance.clientWithHeaders.feeds.createSchedules(
          data
        );
        resolve(true);
      } catch (error: any) {
        reject(error?.response?.data.error || error);
      }
    });
    return schedulesPromise;
  };

  const searchByWidgetCode = (code: string) => {
    let widgetPromise = new Promise(async (resolve, reject) => {
      try {
        let widgetResponse = await networkInstance.clientWithHeaders.feeds.searchByWidgetCode(
          code.toUpperCase(),
        )
        resolve(widgetResponse?.data?.data?.widgets)
      } catch (error: any) {
        reject(error?.response?.data.error || error)
      }
    })
    return widgetPromise
  }


  let scheduleForm = Form.useForm<Schedule>()[0]

  const navigate = useNavigate()

  const createScheduleHandler = async (formData: Schedule) => {
    const id = routeMatch?.params.id || ''
    try {
      formData.widget_id = selectedWidgetId

      let scheduleFormData =
        ScheduleInstance.current.getSerializedData(formData)

      if (isEditJourney) {
        scheduleFormData.schedule.updated_by = userProfile?.email

        scheduleFormData.schedule.active = isScheduleActive
        delete scheduleFormData?.schedule?.widget_id
        delete scheduleFormData?.schedule?.platform_property_code

        let editScheduleResponse = await editSchedulesById(scheduleFormData, id)
        if (editScheduleResponse && editScheduleResponse) {
          let widget_code = scheduleForm.getFieldValue('widget_code')
          notification.success({
            message: 'Schedule updated sucessfully.',
            description: `ID : ${id} and Widget Code : ${widget_code}`,
            duration: 0,
            onClick: () => { },
          })
          navigate('/feeds/schedule')
        }
      } else {
        scheduleFormData.schedule.created_by = userProfile?.email
        let createScheduleResponse = await createSchedule(scheduleFormData)

        notification.success({
          message: 'Schedule created sucessfully.',
          description: '',
          duration: 0,
          onClick: () => { },
        })
        navigate('/feeds/schedule')
      }
    } catch (error: any) {
      console.log('Error Occured:', error)
      let errorMessage = error?.message || 'Something went wrong.'
      message.error(errorMessage)
    }
  }
  const getCtaRedirectionList = async () => {
    try {
      let ctaRedirection: any = await networkInstance.clientWithHeaders.feeds.getCtaRedirectionType()

      let selectList = ctaRedirection.data.data.cta_redirection_types?.map(
        (item: string) => {
          return { label: item, value: item }
        },
      )
      setCtaRedirectionType(selectList)
    } catch (error: any) {
      console.log('Error Occured:', error)
      let errorMessage =
        error?.message || 'Error occured while fetching Cta Redirection Type.'
      message.error(errorMessage)
    }
  }

  const getStoreList = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.monetisationOfferingsApi.storesApi.getStoreList(null, 'dropdown', undefined, true)
      setStoreList(res.data.data.store_list)
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.monetisationOfferingsApi.storesApi])
  const getCtaActionList = async () => {
    try {
      let ctaAction: any = await networkInstance.clientWithHeaders.feeds.getCtaActionType()
      let selectList = ctaAction?.data.data.cta_action_types?.map((item: string) => {
        return { label: item, value: item }
      })
      setCtaActionType(selectList)
    } catch (error: any) {
      console.log('Error Occured:', error)
      let errorMessage =
        error?.message || 'Error occured while fetching Cta Action Type.'
      message.error(errorMessage)
    }
  }

  const setWidgetClassType = (widget_type: string) => {
    let [widgetName, widgetClass] = getWidgetsNameAndClass(widget_type)
    setWidgetNameAndClass({ widgetName, widgetClass })
  }



  const getWidgetsByType = () => {
    let widget_type =
      scheduleForm.getFieldValue('widget_type') || scheduleFormData?.widget_type
    let actionInstance: Content = getWidgetEntityInstance(widget_type)
    console.log({ widget_type })
    switch (widget_type) {
      case 'ACTION':
        return (
          <ActionWidget
            contentInstance={actionInstance as Action}
            ctaActionType={ctaActionType}
            ctaRedirectionType={ctaRedirectionType}
            riddlerInstance={riddler}
          />
        )
      case 'APP_SHARE':
        return (
          <AppShareWidget
            riddlerInstance={riddler}
            contentInstance={actionInstance as AppShare}
          />
        )
      case 'YOUTUBE_SCHEDULE':
        return (
          <YoutubeScheduleWidget
            riddlerInstance={riddler}
            contentInstance={actionInstance as YoutubeSchedule}
          />
        )
      case 'PANCHANG':
        return <PanchangWidget contentInstance={actionInstance as Panchang} />
      case 'MINI_MANDIR':
        return (
          <MiniMandirWidget contentInstance={actionInstance as MiniMandir} />
        )
      case 'HERO_SONG':
        return (
          <HeroSongWidget
            riddlerInstance={riddler}
            contentInstance={actionInstance as HeroSong}
          />
        )
      case 'HERO_ARTICLE':
        return (
          <HeroArticleWidget contentInstance={actionInstance as HeroArticle} />
        )
      case 'HERO_CONTENT':
        return (
          <HeroContentWidget
            ctaRedirectionType={ctaRedirectionType}
            riddlerInstance={riddler}
            contentInstance={actionInstance as HeroContent}
            storeList={storeList}
          />
        )
      case 'SONG_PLAYLIST':
        return (
          <SongPlaylistWidget
            contentInstance={actionInstance as SongPlaylist}
          />
        )
      case 'YOUTUBE_LIVE':
        return (
          <YoutubeLiveWidget
            riddlerInstance={riddler}
            contentInstance={actionInstance as YoutubeLive}
          />
        )
      case 'CONTENT_COLLECTION':
        return (
          <ContentCollectionWidget
            ctaActionType={ctaActionType}
            ctaRedirectionType={ctaRedirectionType}
            riddlerInstance={riddler}
            communities={communities}
            contentInstance={actionInstance as ContentCollection}
          />
        )

      case 'HERO_VIDEO':
        return (
          <HeroVideoWidget
            riddlerInstance={riddler}
            contentInstance={actionInstance as HeroVideo}
            form={scheduleForm}
          />
        )
      case 'MENU_NOTIFICATION':
        return (
          <MenuNotificationWidget
            contentInstance={actionInstance as MenuNotification}
          />
        )
      case 'ACTION_LIST':
        return (
          <ActionListWidget
            ctaActionType={ctaActionType}
            ctaRedirectionType={ctaRedirectionType}
            riddlerInstance={riddler}
            contentInstance={actionInstance as ActionList}
          />
        )
      case 'ACTION_SHORTCUTS':
        return (
          <ActionShortcutsWidget
            ctaActionType={ctaActionType}
            ctaRedirectionType={ctaRedirectionType}
            riddlerInstance={riddler}
            contentInstance={actionInstance as ActionShortcuts}
          />
        )
      case 'MENU_APP_VERSION':
        return (
          <MenuAppVersionWidget
            riddlerInstance={riddler}
            contentInstance={actionInstance as MenuAppVersion}
          />
        )
      case 'MENU_LOGIN':
        return (
          <MenuLoginWidget
            riddlerInstance={riddler}
            contentInstance={actionInstance as MenuLogin}
          />
        )
      case 'ACTION_V2':
        return (
          <ActionV2Widget
            ctaActionType={ctaActionType}
            ctaRedirectionType={ctaRedirectionType}
            riddlerInstance={riddler}
            contentInstance={actionInstance as ActionV2}
          />
        )
      case 'HERO_SOCIAL_COMMUNITY':
        return (
          <SocialWidget
            riddlerInstance={riddler}
            contentInstance={actionInstance as Social}
          />
        )
      case 'GREETING':
        return (
          <GreetingWidget
            riddlerInstance={riddler}
            contentInstance={actionInstance as Greeting}
          />
        )
      case 'IMAGE':
        return (
          <ImageWidget
            form={scheduleForm}
            riddlerInstance={riddler}
            contentInstance={actionInstance as ActionInstance}
          />
        )
      default:
        return
    }
  }
  const getScheduleById = async (id: string) => {
    try {
      let getScheduleResponse: any = await networkInstance.clientWithHeaders.feeds.getSchedulesById(id)

      let scheduleListSanitized = getScheduleResponse.data.data?.schedules.length
        ? getScheduleResponse.data.data?.schedules.map((schedule: any) => {
          setScheduleStatus(schedule?.active)
          let deSerializedData =
            ScheduleInstance.current.getDeSerializedData(schedule)
          return { ...deSerializedData, key: schedule.id }
        })
        : []
      let schedule = scheduleListSanitized[0]
      setWidgetClassType(schedule?.widget?.widget_type)
      setWidgetId(schedule?.widget?.widget_id)
      let { code, widget_type } = schedule?.widget
      setWidgetId(schedule?.widget?.id)
      setWidgetClassType(widget_type)

      const scheduleFormData = {
        ...schedule,
        widget_code: code,
        widget_type: widget_type,
        widget_id: schedule?.widget?.id,
        language_mode: schedule.language_mode
      }
      setScheduleFormData(scheduleFormData)
      scheduleForm.setFieldsValue(scheduleFormData)
    } catch (error: any) {
      console.log('Error Occured:', error)
      let errorMessage = error?.message || 'Something went wrong.'
      message.error(errorMessage)
    }
  }

  useEffect(() => {
    let scheduleForClone = localStorage.getItem('scheduleClone')
    const id = routeMatch?.params.id
    if (id) {
      setPageJourneyType(true)
      getScheduleById(id)
    } else if (scheduleForClone) {
      try {
        getScheduleById(scheduleForClone)
        localStorage.removeItem('scheduleClone')
      } catch (error) {
        console.log('CLONE ERROR:', error)
        message.error('Error occured while cloning.')
      }
    } else {
      scheduleForm?.setFieldsValue({})
    }
    return () => {
      localStorage.getItem('removeItem')
    }
  }, [])

  /* Load platform and properties initially */
  useEffect(() => {
    const getPlatformList = async () => {
      networkInstance.clientWithHeaders.feeds.getPlatforms()
        .then((platform: any) => {
          let platformOption = platform?.data.data.platforms.map((item: string) => ({
            value: item,
            label: item,
          }))
          setPlatformList(platformOption)
        })
        .catch((error: any) => {
          console.log('Error Occured:', error)
          let errorMessage = error?.message || 'Something went wrong.'
          message.error(errorMessage)
        })
    }
    const getPropertiesList = async () => {
      networkInstance.clientWithHeaders.feeds.getProperties()
        .then((propertiesList: any) => {
          let propertiesOption = propertiesList?.data.data.platform_properties.map(
            (item: Property) => ({
              value: item.code,
              label: item.code,
            }),
          )
          setPropertyList(propertiesOption)
        })
        .catch((error: any) => {
          console.log('Error Occured:', error)
          let errorMessage = error?.message || 'Something went wrong.'
          message.error(errorMessage)
        })
    }
    getPlatformList()
    getPropertiesList()
    getCtaActionList()
    getCtaRedirectionList()
    getStoreList();
  }, [])
  const getCancelButton = () => {
    let createNewHandler = () => {
      navigate('/feeds/schedule')
    }
    return (
      <Button
        key='create-new'
        type='dashed'
        htmlType='button'
        onClick={createNewHandler}
        icon={<RollbackOutlined />}
      >
        Cancel
      </Button>
    )
  }
  const submitHandler = () => {
    scheduleForm?.submit()
  }
  const handleSearch = async (value: any) => {
    scheduleForm.setFieldsValue({ widget_type: undefined })
    if (!value) {
      setAutoCompleteOptions([])
      return
    }
    try {
      let autocompleteRes: any = await searchByWidgetCode(
        String(value).toUpperCase(),
      )

      if (autocompleteRes.length) {
        let options = autocompleteRes.map((item: any) => ({
          label: item.code,
          value: `${item.id}=${item.widget_type}`,
        }))

        setAutoCompleteOptions(options)
      } else {
        setAutoCompleteOptions([])

        scheduleForm.setFieldsValue({ widget_type: undefined })
      }
    } catch (error: any) {
      console.log('Error Occured:', error)
      let errorMessage = error?.message || 'Something went wrong.'
      message.error(errorMessage)
    }
  }
  const handleSelect = (value: string, optionValue: any) => {
    let [widget_id, widget_type] = optionValue?.key.split('=')

    scheduleForm?.setFieldsValue({
      widget_code: value,
      widget_type: widget_type,
      widget_id: widget_id,
    })

    setWidgetId(widget_id)
    setWidgetClassType(widget_type)
  }

  const handleAllSelection = (value: string[]) => {
    let result = getAllDaysSelectionValues(value, validDaysPreData)
    scheduleForm.setFieldsValue({ daysOfTheWeek: result?.validDays })
    setValidDaysPreData(result?.validDays || [])
    if (result?.returnFlag) {
      return
    } else {
      setValidDaysPreData([...value])
    }
  }
  const language = Form.useWatch('language', scheduleForm);
  const languageMode = Form.useWatch('language_mode', scheduleForm);
  const getFormSubmitButton = () => {
    return (
      <Popconfirm
        placement="topLeft"
        title={() => { return <div style={{ fontSize: 16 }}> <i>language</i>: <strong>{language}</strong> and <i>language mode</i>:  <strong>{languageMode}</strong> <br /> Click confirm to continue!! <br></br></div> }}
        onConfirm={submitHandler}
        okText="Confirm"
        cancelText="Cancel"
      >
        <Button
          key='submit'
          type='primary'
          htmlType='button'
        >
          {isEditJourney ? 'Update' : 'Submit'}
        </Button>
      </Popconfirm>
    )
  }
  return (
    <PageLayout
      title='Create Schedules'
      backButtonClickHandler={() => {
        navigate(-1)
      }}
      extra={[getCancelButton(), getFormSubmitButton()]}
    >
      <Form
        form={scheduleForm}
        name='schedule'
        scrollToFirstError
        onValuesChange={value => { }}
        initialValues={scheduleFormData}
        preserve={false}
        onFinish={values => {
          createScheduleHandler(values)
        }}
      >
        <CreateScheduleForm
          autoCompleteOptions={autoCompleteOptions}
          ScheduleInstance={ScheduleInstance.current}
          widgetCodeSearchHandler={handleSearch}
          widgetCodeSelectHandler={handleSelect}
          platformList={platformList}
          propertyList={propertyList}
          isEditJourney={isEditJourney}
          updateDaysSelection={handleAllSelection}
        />

        <Form.Item name='content' noStyle>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.widget_type !== curValues.widget_type
            }
          >
            <div style={{ height: '30px' }}>
              <Divider orientation='left' plain>
                <b>{widgetNameAndClass?.widgetName}</b>
              </Divider>
              {getWidgetsByType()}
            </div>
          </Form.Item>
        </Form.Item>
      </Form>
    </PageLayout>
  )
}

export default withDefaultLayout(CreateSchedule)
