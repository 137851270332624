import { useAppContext } from "@/components/AppContext";
import { REQUIRED_RULE } from "@/utils/form.validation";
import { Button, Checkbox, Form, FormInstance, Input, InputNumber, Row, Select, Switch } from "antd"
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

// This interface is just a example for AntdFormWrapper 
interface RechargePackageProps {
    form: FormInstance<string>,
    formData?: any,
    initValues?: any
}

const SubmitButton = styled(Button)`
    position: absolute;
    top: 10px;
    right: 10px;
`

const RechargePackageForm = ({ form, formData, initValues }: RechargePackageProps) => {
    const [campaignList, setCampaignList] = useState<any[]>()
    const { networkInstance } = useAppContext()

    const getCampaignList = useCallback(async () => {
        try {
            const campaignListResponse = await networkInstance.clientWithHeaders.monetisationOfferingsApi.campaignsAPi.getCampaignList({
                limit: 1000,
                offset: 0,
            }, true)
            setCampaignList(campaignListResponse?.data?.data?.campaigns)
        } catch (error) {
            console.log("Error: ", error)
        }
    }, [networkInstance])

    useEffect(() => {
        getCampaignList()
    }, [getCampaignList])

    return <div>
        {
            formData &&
            <Form.Item hidden name="active" >
                <Checkbox></Checkbox>
            </Form.Item>
        }
        {
            (initValues?.variant !== undefined || formData?.variant !== undefined) &&
            <Form.Item name={"variant"} label={"Variant"} valuePropName="checked">
                <Checkbox value={true} disabled></Checkbox>
            </Form.Item>
        }
        <Form.Item rules={REQUIRED_RULE} name={"code"} label={"CODE"}>
            <Input disabled={!!formData}>
            </Input>
        </Form.Item>
        <Form.Item rules={REQUIRED_RULE} name={"name"} label={"Name"}>
            <Input ></Input>
        </Form.Item>
        <Form.Item rules={REQUIRED_RULE} name={"app_code"} label={"App code"}>
            <Input disabled ></Input>
        </Form.Item>
        <Row justify="space-between" style={{ width: '100%' }}>
            <Form.Item rules={REQUIRED_RULE} name={"language"} label={"Language"} style={{ flexBasis: '30%' }}>
                <Select>
                    <Select.Option value={"hi"}>Hindi</Select.Option>
                    <Select.Option value={"en"}>English</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item rules={REQUIRED_RULE} name={"language_mode"} label={"Language Mode"} style={{ flexBasis: '30%' }}>
                <Select>
                    <Select.Option value={"hi"}>Hindi</Select.Option>
                    <Select.Option value={"en"}>English</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item rules={REQUIRED_RULE} name={"currency_code"} label={"Currency code"} style={{ flexBasis: '30%' }}>
                <Select>
                    <Select.Option value={"INR"}>INR</Select.Option>
                    <Select.Option value={"USD"}>USD</Select.Option>
                </Select>
            </Form.Item>
        </Row>
        <Row justify="space-between" style={{ width: '100%' }}>
            <Form.Item rules={REQUIRED_RULE} name={"recharge_amount"} label={"Recharge Amount"} style={{ flexBasis: '30%' }}>
                <InputNumber disabled={!!formData} ></InputNumber>
            </Form.Item>
            <Form.Item rules={REQUIRED_RULE} name={"offer_amount"} label={"Offer Amount"} style={{ flexBasis: '30%' }}>
                <InputNumber disabled={!!formData} ></InputNumber>
            </Form.Item>
            <Form.Item rules={REQUIRED_RULE} name={"order"} label={"Order"} style={{ flexBasis: '30%' }}>
                <InputNumber></InputNumber>
            </Form.Item>
        </Row>
        <Row justify="space-between" style={{ width: '100%' }}>
            <Form.Item
                rules={REQUIRED_RULE}
                name={"min_app_ver"}
                label={"Min App version"}
                style={{ flexBasis: '30%' }}
            >
                <InputNumber />
            </Form.Item>
            <Form.Item
                name={"max_app_ver"}
                label={"Max App version"}
                style={{ flexBasis: '30%' }}
                dependencies={['min_app_ver']}
                rules={[
                    ...REQUIRED_RULE,
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('min_app_ver') < value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Max App version must be greater than Min App version'));
                        },
                    }),
                ]}
            >
                <InputNumber />
            </Form.Item>
            <Form.Item name={"active"} label={"Active"} valuePropName="checked" style={{ flexBasis: '30%' }}>
                <Switch />
            </Form.Item>
            <Form.Item name={"label"} label={"Label"}>
                <Input ></Input>
            </Form.Item>
        </Row>

        <Form.Item rules={REQUIRED_RULE} name={"recharge_type"} label={"Package Type"}>
            <Select disabled={!!formData}>
                <Select.Option value={"default"}>default</Select.Option>
                <Select.Option value={"special"}>Special</Select.Option>
            </Select>
        </Form.Item>
        <Form.Item name={"campaign_id"} label={"Campaigns"}>
            <Select>
                {campaignList?.map((campaign) => {
                    return <Select.Option key={campaign.id} value={campaign.id}>{campaign.name}</Select.Option>
                })}
            </Select>
        </Form.Item>

        {
            formData ?
                <SubmitButton type="primary" htmlType="submit"> Update </SubmitButton> :
                <SubmitButton type="primary" htmlType="submit"> Create </SubmitButton>
        }

    </div>
}




export default RechargePackageForm;