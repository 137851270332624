import moment from 'moment'

import {
  CreateInventory,
  CreateStore,
  LanguageState,
  StoreSessionDay,
} from '@a4b/api/src/modules/Monetisation/Offerings/types'

export const formatCreateTempleApi = (
  formData: any,
  languageState: any,
  isActive: boolean,
  isEditJourney: boolean,
  countryCode: string,
  addressRemoved: boolean,
): CreateStore => {
  const certificateImage = formData?.certificate_image?.[0]
  const closedInfoMedia = formData?.closed_info_media_image.map(
    (item: any) => ({
      media_url: item,
      type: 'image',
    }),
  )
  if (formData?.closed_info_media_video?.length) {
    closedInfoMedia.unshift({
      media_url: formData?.closed_info_media_video?.[0],
      type: 'video',
    })
  }

  const infoMedia = formData?.info_media_image.map((item: any) => ({
    media_url: item,
    type: 'image',
  }))
  if (formData?.info_media_video?.length) {
    infoMedia.unshift({
      media_url: formData?.info_media_video?.[0],
      type: 'video',
    })
  }

  const slug: string = formData?.slug?.replace(/\s+/g, '-')

  const deliveryPickupAddress =
    formData?.location_details?.delivery_pickup_address

  const formObject: CreateStore = {
    slug: slug,
    title: formData?.title,
    display_title: formData?.display_title,
    sub_title: formData?.sub_title,
    short_description: formData?.short_description,
    long_description: formData?.long_description,
    hub_id: formData?.hub_id,
    icon_url: formData?.icon_url[0],
    cover_media: {
      media_url: formData?.media_url[0],
      type: formData?.coverMediaType,
      media_thumbnail: formData?.thumbnail?.[0] ?? null,
    },
    info_media: infoMedia,
    closed_cover_media: {
      media_url: formData?.closed_media_url[0],
      type: formData?.closedCoverMediaType,
    },
    closed_info_media: closedInfoMedia,
    cta: {
      type: 'redirection',
      color: 'green',
      text: formData?.cta?.text,
      media: {
        media_type: formData?.cta?.media?.media_type,
        media_url: formData?.cta?.media?.media_url?.[0],
        visible: formData?.cta?.media?.visible,
      },
    },
    position: formData?.position || 1,
    item_image_url: formData?.item_image_url?.[0],
    city: formData?.city,
    state: formData?.state,
    id: formData?.id,
    active: isActive,
    certificate_image: certificateImage,
    service_type: formData?.service_type,
    language_code: languageState.monetisationLanguage,
    location_details: {
      temple_details: {
        name: formData?.location_details?.temple_details?.name,
        location: formData?.location_details?.temple_details?.location,
        description: formData?.location_details?.temple_details?.description,
        image: formData?.location_details?.temple_details?.image?.[0],
      },
      pandit_details: {
        name: formData?.location_details?.pandit_details?.name,
        location: formData?.location_details?.pandit_details?.location,
        description: formData?.location_details?.pandit_details?.description,
        image: formData?.location_details?.pandit_details?.image?.[0],
      },
      show_location_details: formData?.location_details?.show_location_details,
      delivery_pickup_address: !addressRemoved
        ? {
            name: deliveryPickupAddress?.name,
            city: deliveryPickupAddress?.city,
            pickup_email: deliveryPickupAddress?.pickup_email,
            phone:
              typeof deliveryPickupAddress?.phone === 'string'
                ? deliveryPickupAddress?.phone
                : String(deliveryPickupAddress?.phone),
            state: deliveryPickupAddress?.state,
            address: deliveryPickupAddress?.address,
            district: deliveryPickupAddress?.district,
            landmark: deliveryPickupAddress?.landmark,
            pincode:
              typeof deliveryPickupAddress?.pincode === 'string'
                ? deliveryPickupAddress?.pincode
                : String(deliveryPickupAddress?.pincode),
            country: deliveryPickupAddress?.country,
          }
        : null,
    },
    country_code: countryCode,
    txn_success_media: formData?.txn_success_media?.map((item: any) => ({
      media_type: item?.media_type,
      media_url: item?.media_url?.[0],
      deeplink: '',
      visible: true,
    })),
    ...(formData?.temple_media?.media_type &&
      formData?.temple_media?.media_url?.[0] && {
        temple_media: [
          {
            media_type: formData?.temple_media?.media_type,
            media_url: formData?.temple_media?.media_url?.[0],
            deeplink: '',
            visible: true,
          },
        ],
      }),
    order_intro_media: {
      media_type: formData?.order_intro_media?.media_type,
      media_url: formData?.order_intro_media?.media_url?.[0],
      deeplink: '',
      visible: true,
    },
    astro_pooja_list: formData?.astro_pooja_list?.length
      ? formData?.astro_pooja_list
      : null,
    tags: formData?.tags?.length ? formData?.tags : null,
    show_reorder_card_l2: formData?.show_reorder_card_l2 ? true : false,
    show_reorder_card_l1: formData?.show_reorder_card_l1 ? true : false,
    god_details: {
      god_name: formData?.god_details?.god_name,
      god_image: formData?.god_details?.god_image?.[0],
    },
    weekday: formData?.weekday,
    process_date: formatDateStr(formData?.process_date) || null,
    store_type: formData?.store_type,
    store_code: formData?.store_code,
    android: formData?.android,
    ios: formData?.ios,
    web: formData?.web,
    temple_id: formData?.temple_id,
    metadata: {
      pooja_specificity: formData?.pooja_specificity,
      pooja_usecase: formData?.pooja_usecase,
      pooja_title: formData?.pooja_title,
      temple_exploration: {
        media: {
          media_type: formData?.temple_exploration?.media?.media_type,
          media_url: formData?.temple_exploration?.media?.media_url?.[0],
        },
        position: formData?.temple_exploration?.position,
      },
    },
    subscription_details: {
      recommend_subscription:
        formData?.subscription_details?.recommend_subscription,
      subscription_config: {
        interval: formData?.subscription_details?.subscription_config?.interval,
        weekday: formData?.subscription_details?.subscription_config?.weekday,
        date:
          formatDate(
            formData?.subscription_details?.subscription_config?.date,
          ) || '',
      },
      subscription_discount: {
        is_subscription_discount:
          formData?.subscription_details?.subscription_discount
            ?.is_subscription_discount,
        type: formData?.subscription_details?.subscription_discount?.type,
        value: formData?.subscription_details?.subscription_discount?.value,
      },
    },
    filter_tags: formData?.filter_tags?.length ? formData?.filter_tags : null,
  }
  if (formData?.whatsapp_share_media?.[0]) {
    formObject['whatsapp_share_media'] = {
      media_url: formData?.whatsapp_share_media?.[0],
      type: formData?.whatsappMediaType,
    }
  } else {
    formObject['whatsapp_share_media'] = { media_url: '', type: '' }
  }
  return formObject
}
export const parseCreateTempleApiEdit = (
  formData: any,
  languageState: LanguageState,
): CreateStore => {
  const infoMedia = formData?.info_media
    ?.map((item: any) => item.type === 'image' && item.media_url)
    .filter((item: string) => item)

  const video = formData?.info_media.filter(
    (item: any) => item?.type === 'video',
  )
  const closedInfoMedia = formData?.closed_info_media
    ?.map((item: any) => item.type === 'image' && item.media_url)
    .filter((item: string) => item)

  const closedVideo = formData?.closed_info_media?.filter(
    (item: any) => item?.type === 'video',
  )
  const slug: string = formData?.slug?.replace(/\s+/g, '-')
  const formObject: any = {
    store_id: formData?.store_id,
    slug: slug,
    title: formData?.title,
    sub_title: formData?.sub_title,
    display_title: formData?.display_title,
    short_description: formData?.short_description,
    long_description: formData?.long_description,
    hub_id: formData?.hub_id,
    icon_url: [formData?.icon_url],
    media_url: [formData?.cover_media?.media_url],
    coverMediaType: formData?.cover_media?.type,
    thumbnail: [formData?.cover_media?.media_thumbnail],
    closedCoverMediaType: formData?.closed_cover_media?.type,
    closed_media_url: [formData?.closed_cover_media?.media_url],
    position: formData?.position || 1,
    info_media_image: infoMedia,
    closed_info_media_image: closedInfoMedia,
    service_type: formData?.service_type,
    cta: {
      text: formData?.cta?.text,
      media: {
        media_type: formData?.cta?.media?.media_type,
        media_url: [formData?.cta?.media?.media_url],
        visible: formData?.cta?.media?.visible,
      },
    },
    item_image_url: [formData?.item_image_url],
    city: formData?.city,
    state: formData?.state,
    id: formData?.id,
    whatsapp_share_media: formData?.whatsapp_share_media?.media_url
      ? [formData?.whatsapp_share_media?.media_url]
      : null,
    whatsappMediaType: formData?.whatsapp_share_media?.type
      ? formData?.whatsapp_share_media?.type
      : null,
    language_code: languageState.monetisationLanguage,
    location_details: {
      temple_details: {
        ...formData?.location_details?.temple_details,
        image: [formData?.location_details?.temple_details?.image],
      },
      pandit_details: {
        ...formData?.location_details?.pandit_details,
        image: [formData?.location_details?.pandit_details?.image],
      },
      show_location_details: formData?.location_details?.show_location_details,
      delivery_pickup_address: {
        ...formData?.location_details?.delivery_pickup_address,
      },
    },
    country_code: formData?.country_code,
    txn_success_media: formData?.txn_success_media?.map((item: any) => ({
      media_type: item?.media_type,
      media_url: [item?.media_url],
    })),
    temple_media: formData?.temple_media?.map((item: any) => ({
      media_type: item?.media_type,
      media_url: [item?.media_url],
    }))?.[0],
    order_intro_media: {
      media_type: formData?.order_intro_media?.media_type,
      media_url: [formData?.order_intro_media?.media_url],
    },
    astro_pooja_list: formData?.astro_pooja_list?.length
      ? formData?.astro_pooja_list
      : [],
    tags: formData?.tags?.length ? formData?.tags : [],
    show_reorder_card_l2: formData?.show_reorder_card_l2 ? true : false,
    show_reorder_card_l1: formData?.show_reorder_card_l1 ? true : false,
    god_details: {
      god_name: formData?.god_details?.god_name,
      god_image: [formData?.god_details?.god_image],
    },
    weekday: formData?.weekday,
    process_date: formData?.process_date
      ? moment(
          moment(formData?.process_date).format('YYYY-MM-DD HH:mm:ss'),
        ).utcOffset(0)
      : undefined,
    store_type: formData?.store_type,
    pooja_specificity: formData?.pooja_specificity,
    pooja_usecase: formData?.pooja_usecase,
    pooja_title: formData?.pooja_title,
    store_code: formData?.store_code,
    android: formData?.android,
    ios: formData?.ios,
    web: formData?.web,
    temple_exploration: {
      media: {
        media_type: formData?.meta_data?.temple_exploration?.media?.media_type,
        media_url: [formData?.meta_data?.temple_exploration?.media?.media_url],
      },
      position: formData?.meta_data?.temple_exploration?.position,
    },
    subscription_details: {
      recommend_subscription:
        formData?.subscription_details?.recommend_subscription,
      subscription_config: {
        interval: formData?.subscription_details?.subscription_config?.interval,
        weekday: formData?.subscription_details?.subscription_config?.weekday,
        date: formData?.subscription_details?.subscription_config?.date
          ? moment(
              moment(
                formData?.subscription_details?.subscription_config?.date,
              ).format('YYYY-MM-DD HH:mm:ss'),
            ).utcOffset(0)
          : undefined,
      },
      subscription_discount: {
        is_subscription_discount:
          formData?.subscription_details?.subscription_discount
            ?.is_subscription_discount,
        type: formData?.subscription_details?.subscription_discount?.type,
        value: formData?.subscription_details?.subscription_discount?.value,
      },
    },
    filter_tags: formData?.filter_tags?.length ? formData?.filter_tags : [],
  }
  if (formData?.closed_cover_media?.media_url) {
    formObject['closed_media_url'] = [formData?.closed_cover_media?.media_url]
  }
  if (formData?.certificate_image) {
    formObject['certificate_image'] = [formData?.certificate_image]
  }
  if (video?.[0]?.media_url) {
    formObject['info_media_video'] = [video?.[0]?.media_url]
  }
  if (closedVideo?.[0]?.media_url) {
    formObject['closed_info_media_video'] = [closedVideo?.[0]?.media_url]
  }
  if (formData?.temple_id) {
    formObject['temple_id'] = formData?.temple_id
  }

  return formObject
}
export const parseCreateTempleApi = (formData: any) => {
  const formObject = {
    title: formData?.title,
    sub_title: formData?.sub_title,
    short_description: formData?.short_description,
    long_description: formData?.long_description,
    hub_id: formData?.hub_id,
    icon_url: [formData?.icon_url],
    media_url: [formData?.cover_media?.media_url],
    coverMediaType: formData?.cover_media?.type,
    thumbnail: [formData?.cover_media?.thumbnail],
    cta: JSON.stringify(formData?.cta),
    item_image_url: [formData?.item_image_url],
    info_media_image: formData?.info_media_image
      ? [formData?.info_media_image]
      : [],
    info_media_video: formData?.info_media_video
      ? [formData?.info_media_video]
      : [],
    city: formData?.city,
    state: formData?.state,
  }
  return formObject
}

const parseAdditionalInfo = (additionInfo: string) => {
  const snippet = additionInfo
  const parser = new DOMParser()
  const doc = parser.parseFromString(snippet, 'text/html')
  const spans = doc.querySelectorAll('span.item span')
  const images = doc.querySelectorAll('img')

  const list = Array.from(spans).map(span => span.innerHTML.trim())
  const iconImg = [Array.from(images).map(img => img.getAttribute('src'))?.[0]]

  return list.length <= 0
    ? { error: true, additionInfo, iconImg }
    : { list, iconImg }
}

export const parseStoreProductList = (
  formData: any,
  campaignList: any,
  storeList: any,
  languageState: LanguageState,
) => {
  let campaignArray: any[] = []
  campaignList?.map((campaign: any) =>
    campaignArray.push([campaign?.id, campaign.name]),
  )
  const stores = storeList?.map((store: any) => ({
    label: store?.title,
    value: store?.id,
  }))

  const productList = formData?.products
    ?.map((item: any) => ({
      id: item?.id,
      title: item?.title,
      description: item?.description,
      image_url: [item?.image_url],
      bottomsheet_image_url: [item?.bottomsheet_image_url],
      price: item?.price,
      master_product_id: item?.master_product_id,
      intro_video_url: item?.intro_video_url ? [item?.intro_video_url] : [],
      outro_video_url: item?.outro_video_url ? [item?.outro_video_url] : [],
      position: item?.position || 0,
      active: item?.active,
      batch_size: item?.batch_size || 20,
      short_name: item?.short_name,
      display_name: item?.display_name,
      quantities: item?.quantities || 0,
      additional_info: item?.additional_info
        ? parseAdditionalInfo(item?.additional_info)
        : null,
      member_size: item?.member_size ? item?.member_size : null,
      item_type: item?.item_type,
      language_code: item?.language_code,
      multi_add: item?.multi_add,
      cta: {
        text: item?.cta?.text,
        color: item?.cta?.color,
        icon: {
          url: [item?.cta?.icon?.url],
        },
        visible: item?.cta?.visible,
      },
      specificity: item?.specificity,
      country_code: item?.country_code,
      currency: item?.currency,
      bestseller: item?.bestseller,
      deliverable: item?.deliverable,
      process_date: moment(
        moment(item?.process_date).format('YYYY-MM-DD HH:mm:ss'),
      ).utcOffset(0),
      campaignList: item?.campaigns
        ?.map((id: any) =>
          campaignList
            ?.filter((obj: any) => obj?.id === id)
            ?.map((obj: any) => obj?.name),
        )
        ?.flat(),
      campaignArray: campaignArray,
      origin_store_id: item?.origin_store_id,
      storeList: stores,
      sankalp_bg_image: item?.sankalp_bg_image ? [item?.sankalp_bg_image] : [],
      offering_config: {
        ...item?.offering_config,
        date: item?.offering_config?.date
          ? moment(item?.offering_config?.date)
          : moment(new Date()),
      },
      offering_sku_id: item?.offering_sku_id,
      recommend: item?.recommend
    }))
    .sort((item_1: any, item_2: any) => item_1?.position - item_2?.position)
  return productList
}
export const formatAssignItemsApi = (formData: any): CreateInventory => {
  const inventoryList = formData.added.map((item: any, index: number) => ({
    master_product_id: item.id,
    name: item.title,
    image_url: item.image_url,
    bottomsheet_image_url: item.bottomsheet_image_url,
    description: item.description,
    price: item.price,
    quantities: 0,
    position: index,
    multi_add: item.multi_add || false,
    bestseller: false,
    cta: {
      type: 'whatsapp_share',
      color: 'green',
      text: 'Add to cart',
    },
    active: true,
    type: item.type,
    specificity: item.specificity,
    recommend: item?.recommend
  }))
  return { products: inventoryList }
}
export const formatFaqsAndreviewsApi = (
  formData: any,
  storeId: string,
  storeSession: StoreSessionDay[] | [],
): any => {
  //Current changes
  const formObject: any = {}
  formObject['faqs'] = {
    create: {
      faq_type: 'store',
      entity_id: storeId,
      faqs: formData?.faqs
        ?.filter((item: any) => !item?.id)
        ?.map((item: any, index: number) => ({
          question: item?.question,
          answer: item?.answer,
          position: item?.position,
          active: true,
        })),
    },
    update: {
      faq_type: 'store',
      entity_id: storeId,
      faqs: formData?.faqs
        ?.filter((item: any) => item?.id)
        ?.map((item: any, index: number) => ({
          id: item?.id,
          question: item?.question,
          answer: item?.answer,
          position: item?.position,
          active: true,
        })),
    },
  }
  formObject['reviews'] = {
    create: {
      store_id: storeId,
      reviews: formData?.reviews
        ?.filter((item: any) => !item?.id)
        ?.map((item: any, index: number) => ({
          review: item?.review,
          user_name: item?.user_name,
          user_image_url: item?.user_image_url?.[0],
          position: item?.position,
          rating: item?.rating,
        })),
    },
    update: {
      store_id: storeId,
      reviews: formData?.reviews
        ?.filter((item: any) => item?.id)
        ?.map((item: any, index: number) => ({
          id: item?.id,
          review: item?.review,
          user_name: item?.user_name,
          user_image_url: item?.user_image_url?.[0],
          position: item?.position,
          rating: item?.rating,
        })),
    },
  }
  formObject['pooja_benefits'] = {
    create: {
      page_type: 'pooja_benefits',
      entity_id: storeId,
      steps: formData?.pooja_benefits
        ?.filter((item: any) => !item?.id)
        ?.map((item: any, index: number) => ({
          step: item?.step,
          icon: item?.pooja_benefit_icon?.[0],
          description: item?.description,
          position: item?.position,
        })),
    },
    update: {
      page_type: 'pooja_benefits',
      entity_id: storeId,
      steps: formData?.pooja_benefits
        ?.filter((item: any) => item?.id)
        ?.map((item: any, index: number) => ({
          id: item?.id,
          step: item?.step,
          icon: item?.pooja_benefit_icon?.[0],
          description: item?.description,
          position: item?.position,
        })),
    },
  }
  formObject['pooja_process'] = {
    create: {
      page_type: 'pooja_process',
      entity_id: storeId,
      steps: formData?.pooja_process
        ?.filter((item: any) => !item?.id)
        ?.map((item: any, index: number) => ({
          step: item?.step,
          icon: item?.pooja_process_icon?.[0],
          description: item?.description,
          position: item?.position,
        })),
    },
    update: {
      page_type: 'pooja_process',
      entity_id: storeId,
      steps: formData?.pooja_process
        ?.filter((item: any) => item?.id)
        ?.map((item: any, index: number) => ({
          id: item?.id,
          step: item?.step,
          icon: item?.pooja_process_icon?.[0],
          description: item?.description,
          position: item?.position,
        })),
    },
  }

  const days = [0, 1, 2, 3, 4, 5, 6]
  const storeSessions = days?.map((day: any, index: number) => ({
    weekday: index,
    opening_hour: formData?.session?.[0]?.format('HH:mm'),
    closing_hour: formData?.session?.[1]?.format('HH:mm'),
    id: storeSession?.[index]?.id,
  }))

  return {
    faqs: { faqs: formObject['faqs'] },
    reviews: { reviews: formObject['reviews'] },
    pooja_benefits: {
      pooja_benefits: formObject['pooja_benefits'],
    },
    pooja_process: {
      pooja_process: formObject['pooja_process'],
    },
    status: formData.status,
    session: {
      sessions: storeSessions,
    },
  }
}
export const formatInventoryForEditAdd = (
  formData: any,
  countryCode: string,
  languageCode: string,
) => {
  return {
    master_product_id: formData?.id,
    title: formData?.title,
    image_url: formData?.image_url,
    bottomsheet_image_url: formData?.bottomsheet_image_url,
    description: formData?.description,
    price: formData?.price,
    archived: formData?.archived,
    item_type: formData?.item_type,
    multi_add: formData?.multi_add,
    bestseller: false,
    active: formData?.active || false,
    position: formData?.position || 0,
    quantities: formData?.quantities || 0,
    cta: {
      type: 'redirection',
      color: 'green',
      text: 'Add to cart',
    },
    language_code: languageCode,
    currency: countryCode !== 'IN' ? 'USD' : 'INR',
    country_code: countryCode,
    recommend: formData?.recommend
  }
}

export const formatDate = (formDate: any) => {
  const processDate = new Date(formDate)
  let processed_date
  if (isNaN(processDate.getTime())) {
    processed_date = new Date()
  } else {
    processed_date = new Date(formDate)
  }
  return processed_date.toISOString()
}

export const formatDateStr = (formDate: any) =>{
  if (isNaN(new Date(formDate).getTime()) || new Date(formDate).getTime() === 0){
    return null
  }

  return new Date(formDate).toISOString()
}

export const formatDatesList = (datesList: any) => {
  if (!Array.isArray(datesList)) {
    throw new Error("Expected an array as the argument")
  }

  return datesList.map((formDate) => {
    const processDate = new Date(formDate);
    let processed_date;

    if (isNaN(processDate.getTime())) {
      processed_date = new Date();
    } else {
      processed_date = new Date(formDate);
    }

    return processed_date.toISOString();
  });
};

export const formatInventoryForEdit = (
  formData: any,
  campaignList?: any,
  languageCode?: string,
  countryCode?: string,
  serviceType?: string,
  storeType?: string,
  processDate?: any
) => {

  if (formData?.item_type === 'sankalp' && formData?.offering_config && formData?.offering_config?.interval === 'Day') {
    formData.offering_config.date = processDate || null;
  }

  const selectedCampaignIds = formData?.campaignList
    ?.map((name: any) =>
      campaignList
        ?.filter((obj: any) => obj?.name === name)
        ?.map((obj: any) => obj?.id),
    )
    ?.flat()

  console.log('additional info', formData.additional_info)

  const divOpeningTag = `<div style="display: flex; flex-direction: column">`
  const divClosingTag = `</div>`
  const dynamicValues = formData.additional_info?.list
    ?.map(
      (info: string, index: number, array: string[]) => `
      <span style="display: flex; flex-direction: row; align-items: start; width: 100%;" class="item">
        <img width="24" height="24" src=${
          formData?.additional_info?.iconImg?.[0]
        } />
        <span>
        ${info}
        </span>
      </span>
      ${index !== array.length - 1 ? '<br />' : ''}
    `,
    )
    .join('')
  let result = divOpeningTag.concat(dynamicValues, divClosingTag)

  let payload: any = {
    title: formData?.title,
    image_url: formData?.image_url?.[0],
    bottomsheet_image_url: formData?.bottomsheet_image_url?.[0],
    description: formData?.description,
    price: formData?.price,
    archived: formData?.archived,
    item_type: formData?.item_type,
    multi_add: formData?.multi_add,
    bestseller: formData?.bestseller || false,
    deliverable: formData?.deliverable,
    active: formData?.active,
    quantities: formData?.quantities || 0,
    position: formData?.position || 0,
    batch_size: formData?.batch_size,
    short_name: formData.short_name,
    display_name: formData?.display_name,
    cta: {
      type: 'redirection',
      color: formData?.cta?.color || 'green',
      text: formData?.cta?.text,
      icon: {
        url: formData?.cta?.icon?.url?.[0],
      },
      visible: true,
    },
    language_code: languageCode,
    campaigns: selectedCampaignIds,
    country_code: countryCode,
    currency: formData?.currency,
    origin_store_id: formData?.origin_store_id,
    recommend: formData?.recommend
  }
  if (serviceType === 'pooja' && formData?.item_type === 'item') {
    delete payload?.campaigns
  }

  if (storeType === 'preBooking' || formData?.item_type === 'pooja') {
    payload['process_date'] = formatDate(formData?.process_date) || ''
  }
  if (formData?.item_type === 'pooja') {
    payload['member_size'] = formData?.member_size
    payload['additional_info'] = result
    payload['specificity'] = formData?.specificity || ''
    payload['intro_video_url'] = formData?.intro_video_url?.[0] || ' '
    payload['outro_video_url'] = formData?.outro_video_url?.[0] || ' '
  } else {
    payload['intro_video_url'] = formData?.intro_video_url?.[0] || ' '
    payload['outro_video_url'] = formData?.outro_video_url?.[0] || ' '
  }
  if (formData?.item_type === 'sankalp' || true) {
    payload['sankalp_bg_image'] = formData?.sankalp_bg_image?.[0]
    payload['offering_sku_id'] = formData?.offering_sku_id
    payload['offering_config'] = {
      ...formData?.offering_config,
      occurrence: 'every',
      ...(formData?.offering_config?.date && {
        date: formatDate(formData?.offering_config?.date),
      }),
      ...(formData?.offering_config?.dates && {
        dates: formatDatesList(formData?.offering_config?.dates)
      })
    }
  }

  return payload
}
