//context
import { useAppContext } from '@/components/AppContext'
import DragAndDropModal from '@/hoc/DragAndDropModal'
import { YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants'
import { Col, Image, Row, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'

//hoc
//organisms
//hooks
//types
import React, { useCallback, useEffect, useState } from 'react'

import CategoryCreation from '@a4b/ui/src/modules/monetisation/molecules/CategoryCreation'

import {
  CreateStore,
  EditCategory,
  ManageStoreCard,
  StoreCard,
} from '@a4b/api/src/modules/Monetisation/Offerings/types'
import _ from 'lodash'



interface Props {
  showItemCreation: boolean
  isEditJourney: boolean
  createItemCallback?: (status: boolean) => void
  UpdateItemCallback?: (status: boolean) => void
  closeItemCreation: () => void
  formData: EditCategory | undefined
}
interface ShowStoreInfoProps {
  data?: Partial<CreateStore>
}
const ShowStoreInfo: React.FC<ShowStoreInfoProps> = ({ data }) => {
  return (
    <Row>
      <Col span={14}>{data?.title}</Col>
      <Col span={6}>No of SKU's:{data?.no_of_items}</Col>
      <Col span={4}>
        <Image width={80} height={60} src={data?.icon_url} />
      </Col>
    </Row>
  )
}
const ShowStoreCardInfo: React.FC<ShowStoreInfoProps> = ({ data }) => {
  return (
    <Row>
      <Col span={14}>{data?.title}</Col>
      <Col span={4}>
        <Image width={80} height={60} src={data?.icon_url} />
      </Col>
    </Row>
  )
}

const CreateCategory: React.FC<Props> = ({
  showItemCreation,
  closeItemCreation,
  createItemCallback,
  UpdateItemCallback,
  isEditJourney,
  formData,
}) => {
  const { networkInstance } = useAppContext()
  const { monetisationOfferingsApi } = networkInstance.clientWithHeaders

  const [editFormData, setEditFormData] = useState<any>()
  const [createItemForm] = useForm()

  const [storeList, setStoreList] = useState<CreateStore[]>([])
  const [storeCards, setStoreCards] = useState<ManageStoreCard[]>([])
  const [sortedSkuLists, setSortedSkuLists] = useState<ManageStoreCard[]>([])
  const [sortedStoreLists, setSortedStoreLists] = useState<CreateStore[]>([])
  const [showDandDModal, setDandDModalVisibility] = useState(false)

  const getStoreList = useCallback(async () => {
    try {
      const storeList = await monetisationOfferingsApi.storesApi.getStoreList(null, 'dropdown')
      setStoreList(
        storeList?.data?.data?.store_list.sort(
          (item1: any, item2: any) => item1.position - item2.position,
        ),
      )
    } catch (error) {
      message.error({
        content: 'Error while fetching store list',
        duration: 3,
      })
      console.log(error)
    }
  }, [monetisationOfferingsApi.storesApi])

  const getStoreCards = useCallback(async () => {
    try {
      const storeCards = await monetisationOfferingsApi.storesApi.getStoreCards({
        limit: 1000,
        offset: 0,
        active: true,
      })

      setStoreCards(storeCards?.data?.data?.store_cards)
    } catch (error) {
      message.error({
        content: 'Error while fetching store list',
        duration: 3,
      })
      console.log(error)
    }
  }, [monetisationOfferingsApi.storesApi])

  useEffect(() => {
    getStoreList()
    getStoreCards()
  }, [getStoreList, getStoreCards, isEditJourney])

  useEffect(() => {
    if (isEditJourney) {
      const editForm = {
        ...formData,
        title: formData?.title,
        description: formData?.description,
        item_type: formData?.card_type,
        template: formData?.template,
        position: formData?.position,
        id: formData?.id,
      }
      if (formData?.card_type === 'sku') {
        const filteredStoreCard: any = storeCards?.filter(
          (storeCard: ManageStoreCard) =>
            formData?.card_items?.find((card: any) => card.id === storeCard.id),
        )
        const sortedItems = _.sortBy(filteredStoreCard, (item: ManageStoreCard) => formData?.card_items?.indexOf(item?.id))
        setSortedSkuLists(sortedItems)
        setSortedStoreLists([])
      }
      if (formData?.card_type === 'store') {
        const filteredStoreList: any = storeList?.filter((store: CreateStore) =>
          formData?.card_items?.find((card: any) => card.id === store.id),
        )
        setSortedStoreLists(filteredStoreList)
        setSortedSkuLists([])
      }
      setEditFormData(editForm)
      createItemForm.setFieldsValue(editForm)
    } else {
      setTimeout(() => {
        createItemForm.resetFields()
      }, 300)
      setEditFormData({})
    }
  }, [isEditJourney, createItemForm, storeList, storeCards])

  const handleCreateOrUpdate = async (values: any) => {
    if (isEditJourney) {
      const id = formData?.id || ''
      const updateItem = await monetisationOfferingsApi.storesApi.updateCategory(id, {
        ...values,
        active: formData?.active || false,
        id: id,
      })
      if (updateItem) {
        message.success('Category updated successfully')
        if (UpdateItemCallback) {
          UpdateItemCallback(true)
        }
      } else {
        message.error('Category update failed')
      }
      createItemForm.resetFields()
      closeItemCreation()
    } else {
      message.loading({ content: 'Creating item...', key: 'create-category' })
      await monetisationOfferingsApi.storesApi.createCategory(values)
      message.success({
        content: 'Category created successfully',
        duration: 2,
        key: 'create-category',
      })
    }
    createItemForm.resetFields()
    setSortedSkuLists([])
    setSortedStoreLists([])
    closeItemCreation()
  }

  const formSubmithandler = () => {
    createItemForm
      .validateFields()
      .then(values => {
        const formData = { ...values, position: Number(values?.position) }
        if (values?.item_type === 'sku') {
          if (!sortedSkuLists?.length) {
            message.error({
              content: 'Please select some Store cards.',
              duration: 3,
            })
            return
          } else {
            formData['data_uuids'] = sortedSkuLists?.map(
              (item: any) => item?.id,
            )
          }
        }
        if (values?.item_type === 'store') {
          if (!sortedStoreLists?.length) {
            message.error({
              content: 'Please select some Stores.',
              duration: 3,
            })
            return
          } else {
            formData['data_uuids'] = sortedStoreLists?.map(
              (item: any) => item?.id,
            )
          }
        }

        handleCreateOrUpdate(formData)
      })
      .catch(errorInfo => { })
  }
  const onFormChange = (formValues: any) => {
    if (formValues?.item_type === 'sku') {
      setSortedStoreLists([])
    }
    if (formValues?.item_type === 'store') {
      setSortedSkuLists([])
    }
  }

  const handleOnCancel = () => {
    setDandDModalVisibility(false)
  }

  const handleOnOk = (sortedList: any[]) => {
    setDandDModalVisibility(false)
    if (createItemForm.getFieldValue('item_type') === 'sku') {
      setSortedSkuLists(sortedList)
      setSortedStoreLists([])
    }
    if (createItemForm.getFieldValue('item_type') === 'store') {
      setSortedStoreLists(sortedList)
      setSortedSkuLists([])
    }
  }

  const openSelectionWindow = () => {
    setDandDModalVisibility(true)
  }
  return (
    <>
      <CategoryCreation
        visible={showItemCreation}
        onClose={closeItemCreation}
        cdnUrl={YODA_CDN_URL}
        preSignUrl={YODA_PRESIGN_URL}
        formSubmithandler={formSubmithandler}
        form={createItemForm}
        initialValues={editFormData}
        isEditJourney={isEditJourney}
        storeList={storeList}
        onFormChange={onFormChange}
        openSelectionWindow={openSelectionWindow}
        sortedData={
          createItemForm.getFieldValue('item_type') === 'sku'
            ? sortedSkuLists
            : createItemForm.getFieldValue('item_type') === 'store'
              ? sortedStoreLists
              : []
        }
        componentRender={
          createItemForm.getFieldValue('item_type') === 'sku' ? (
            <ShowStoreCardInfo key={'sku'} />
          ) : createItemForm.getFieldValue('item_type') === 'store' ? (
            <ShowStoreInfo key={'store'} />
          ) : (
            ''
          )
        }
      />
      <DragAndDropModal
        showModal={showDandDModal}
        handleOnCancel={handleOnCancel}
        handleOnOk={handleOnOk}
        sortedData={
          createItemForm.getFieldValue('item_type') === 'sku'
            ? sortedSkuLists
            : createItemForm.getFieldValue('item_type') === 'store'
              ? sortedStoreLists
              : []
        }
        itemList={
          createItemForm.getFieldValue('item_type') === 'sku'
            ? storeCards
            : createItemForm.getFieldValue('item_type') === 'store'
              ? storeList
              : []
        }
        title={`Select and arrange ${createItemForm.getFieldValue(
          'item_type',
        )}`}
      >
        {createItemForm.getFieldValue('item_type') === 'sku' && (
          <ShowStoreCardInfo key={'sku'} />
        )}
        {createItemForm.getFieldValue('item_type') === 'store' && (
          <ShowStoreInfo key={'store'} />
        )}
      </DragAndDropModal>
    </>
  )
}

export default React.memo(CreateCategory)
