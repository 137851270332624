import { useEffect, useState } from 'react'
import { useCallback } from 'react'
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import { message, Modal } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import ManageTemplesPage from './components/ManageTemplesPage'
import { CreateStore, HubList, HubListItem } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import type { TablePaginationConfig } from 'antd/es/table'
import Error from '@/components/Error'
import { openErrorNotification, openNotification } from '../../../../utils'
import { useSearchParams } from 'react-router-dom'

const getPageBreadcrumbs = () => [
  {
    path: '/monetisation/offerings/home-page',
    breadcrumbName: 'Home page',
  },
  {
    path: '/monetisation/offerings/temples',
    breadcrumbName: 'Manage Stores',
  },
]

const ManageTempleScreen = () => {
  const [formInstance] = useForm()
  const { networkInstance, languageState, countryState, userProfile } = useAppContext()
  const { monetisationOfferingsApi } = networkInstance.clientWithHeaders
  const [showStoreEnableModal, setShowStoreEnableModal] = useState(false)
  const [enableEditPosition, setEnableEditPosition] = useState(false)
  const [showStoreDisableModal, setShowStoreDisableModal] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 15,
  })
  const [storeList, setStoreList] = useState<CreateStore[]>([])
  const [currentItem, setCurrentItem] = useState<CreateStore>()
  const [initialValues, setInitialValues] = useState<any>()
  const [errorPage, setErrorPage] = useState<boolean>(false)
  const [activeCloneId, setActiveCloneId] = useState<string>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hubList, setHubList] = useState<HubListItem[]>([])
  const [isLastPage, setIsLastPage] = useState<boolean>(false)

  const updateFilters = (updateType: 'sortBy' | 'isArchive' | 'isActive' | 'serviceType' | 'title' | 'id', value?: 'true' | 'false' | 'created_at' | 'position' | 'pooja' | 'offerings' | 'promoter' | 'mandir_connect' | 'all' | string) => {
    const paramMapping: Record<string, string> = {
      sortBy: 'sort_by',
      isArchive: 'is_archive',
      isActive: 'is_active',
      serviceType: 'service_type',
      id: 'id',
      title: 'title',
    };

    const paramName = paramMapping[updateType]

    if (paramName && value !== undefined && value !== '') {
      searchParams?.set(paramName, value)
    } else if (paramName) {
      searchParams?.delete(paramName)
    }
    setSearchParams(searchParams)
  }

  const getHubList = useCallback((async () => {
    try {
      const hubList = await monetisationOfferingsApi.hub.getHubList({
        limit: 1000,
        offset: 0,
      })

      setHubList(hubList?.data?.data.hubs)
    } catch (error) {
      console.log(error)
    }
  }), [monetisationOfferingsApi])

  useEffect(() => {
    getHubList()
  }, [getHubList])


  const getStoreList = useCallback((async () => {
    try {
      setIsLoading(true)

      const title = searchParams.get('title') || undefined;
      const limit = title ? 0 : pagination.pageSize || 15;
      const offset = title ? 0 : (pagination.current! - 1) * limit;

      const storeList = await monetisationOfferingsApi.storesApi.getStoreList({
        limit: limit,
        offset: offset,
      }, 'manage_stores',countryState?.countryCode, undefined, undefined,
        searchParams.get('sort_by') as ('created_at' | 'position') || 'position',
        searchParams.get('is_archive'),
        searchParams.get('is_active') !== 'all' ? searchParams.get('is_active') as ('true' | 'false') || 'true' : null,
        searchParams.get('service_type' as ('promoter' | 'pooja' | 'offerings' | 'mandir_connect')) || 'offerings',
        searchParams.get('id') || undefined,
        title
      )

      if (!storeList?.data?.data?.store_list?.length) {
        setIsLoading(false);
        setStoreList([]);
        message.info({
          content: 'No store found',
          duration: 3,
        });
        return;
      }

      setStoreList(storeList?.data?.data?.store_list)
      setIsLastPage(storeList?.data?.data?.is_last_page)

      const positionData: any = {}
      storeList?.data?.data?.store_list.map(
        (item: CreateStore) => (positionData[item.id] = item?.position || 0),
      )
      setInitialValues(positionData)
      formInstance.setFieldsValue(positionData)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setErrorPage(true)
      message.error({
        content: 'Error while fetching store list',
        duration: 3,
      })
      console.log(error)
    }
  }), [formInstance, monetisationOfferingsApi.storesApi, pagination, countryState?.countryCode, searchParams])

  useEffect(() => {
    getStoreList()
  }, [getStoreList, languageState.monetisationLanguage, countryState?.countryCode, pagination])

  const cloneStore = useCallback(async (storeId, hubId: string) => {
    setActiveCloneId(storeId);
    try {
      const res = await monetisationOfferingsApi.storesApi.cloneStore(storeId, userProfile?.email || 'not found', hubId)
      getStoreList();
      openNotification("success", { message: res.data.data })
    } catch (error) {
      console.log(error)
      openErrorNotification(error);
    }
    setActiveCloneId(undefined);
  }, [getStoreList, monetisationOfferingsApi.storesApi, userProfile?.email])

  const onCreateNew = () => { }

  const onStatusChange = (item: CreateStore) => {
    setCurrentItem(item)
    if (item.active) {
      setShowStoreDisableModal(true)
    } else {
      setShowStoreEnableModal(true)
    }
  }
  const triggerEdit = (temple: CreateStore) => { }
  const onModalOk = async () => {
    message.loading({
      content: 'Updating store status',
      duration: 1,
      key: 'update_status',
    })
    try {
      await monetisationOfferingsApi.storesApi.updateStoreStatus(
        currentItem?.id || '',
        !currentItem?.active,
        languageState.monetisationLanguage,
        countryState?.countryCode
      )
      setShowStoreEnableModal(false)
      setShowStoreDisableModal(false)
      message.success({
        content: 'Store status updated sucessfully',
        duration: 3,
        key: 'update_status',
      })
      getStoreList()
    } catch (error) {
      setShowStoreEnableModal(false)
      setShowStoreDisableModal(false)
      message.error({
        content: 'Error while updating store status',
        duration: 2,
        key: 'update_status',
      })

      console.log(error)
    }
  }

  const onModalCancel = () => {
    setShowStoreEnableModal(false)
    setShowStoreDisableModal(false)
  }

  const updatePosition = async () => {
    const formValues = formInstance.getFieldsValue()
    const updatedFields = storeList.map((store: CreateStore) => {
      const updatedPosition = formValues[store.id]
      if (store.position !== updatedPosition && updatedPosition !== undefined && updatedPosition !== null)
        return { store_id: store.id, position: formValues[store.id] }
    }).filter((item: any) => item)

    const payload = {
      stores: updatedFields,
      language_code: languageState?.monetisationLanguage,
      country_code: countryState?.countryCode
    };

    try {
      await monetisationOfferingsApi.storesApi.updateStorePosition(
        payload
      )
      setEnableEditPosition(false)
      message.success({
        content: 'Store position updated sucessfully',
        duration: 3,
        key: 'update_position',
      })
      getStoreList()
    } catch (error) {
      setEnableEditPosition(false)

      message.error({
        content: 'Error while updating store position',
        duration: 2,
        key: 'update_position',
      })

      console.log(error)
    }

  }
  const enableUpdate = (status: boolean) => {
    setEnableEditPosition(status)
  }

  const archiveStore = async (storeId: string) => {
    try {
      await networkInstance.clientWithHeaders.monetisationOfferingsApi.storesApi.archiveStore(storeId, {
        email: userProfile.email,
        archive: true
      })
      getStoreList();
      openNotification("success", { message: "Archive status updated." })
    } catch (error) {
      openNotification("error", { message: "Unable to archive store something went worng" })
    }
  }

  const unarchiveStore = async (storeId: string) => {
    try {
      await networkInstance.clientWithHeaders.monetisationOfferingsApi.storesApi.archiveStore(storeId, {
        email: userProfile.email,
        archive: false
      })
      getStoreList()
      openNotification("success", { message: "Archive status updated." })
    } catch (error) {
      openNotification("error", { message: "Unable to archive store something went worng" })
    }
  }

  return (
    <div style={{ padding: '0 40px' }}>
      {errorPage ?
        <Error text='Error while fetching Stores!' /> :
        <>
          <AdminBreadcrumb routes={getPageBreadcrumbs()} />
          <ManageTemplesPage
            onCreateNew={onCreateNew}
            onStatusChange={onStatusChange}
            triggerEdit={triggerEdit}
            templeList={storeList}
            updatePosition={updatePosition}
            initialValues={initialValues}
            formInstance={formInstance}
            enableEditPosition={enableEditPosition}
            enableUpdate={enableUpdate}
            archiveStore={archiveStore}
            unarchiveStore={unarchiveStore}
            cloneStore={cloneStore}
            activeCloneId={activeCloneId}
            sortBy={searchParams.get('sort_by') as ('created_at' | 'position') || 'position'}
            isArchive={searchParams.get('is_archive') as ('true' | 'false')}
            isActive={searchParams.get('is_active') as ('true' | 'false' | 'all') || 'true'}
            serviceType={searchParams.get('service_type') as ('promoter' | 'pooja' | 'offerings' | 'mandir_connect') || 'offerings'}
            updateFilters={updateFilters}
            loading={isLoading}
            hubList={hubList}
            pagination={pagination}
            onPageChange={(current: any) => setPagination((prev) => ({ ...prev, current }))}
            isLastPage={isLastPage}
          />
          <Modal
            title='Activate Store'
            visible={showStoreEnableModal}
            onOk={onModalOk}
            onCancel={onModalCancel}
            okText=' Yes, Activate'
          >
            <p>Are you sure to activate this store ?</p>
          </Modal>
          <Modal
            title='Deactivate Store'
            visible={showStoreDisableModal}
            onOk={onModalOk}
            onCancel={onModalCancel}
            okText='Yes, Deactivate'
          >
            <p>Are you sure to Deactivate this store ?</p>
          </Modal>
        </>
      }
    </div>
  )
}

export default withDefaultLayout(ManageTempleScreen)
