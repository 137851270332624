import React, { useEffect, useState, useRef, useCallback } from "react";
import { useAppContext } from "@/components/AppContext";
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Spin, Switch, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { YODA_CDN_URL, PUJA_PRESIGN_URL, A4B_APPS } from "@/utils/constants";
import S3Upload from "@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload";
import { Filter } from "@a4b/api/src/modules/Monetisation/Puja/types/filters";
import { prepareFilterPayload } from "../../utils/helper";
import { useSearchParams } from "react-router-dom";
import JourneyMetaData from "./JourneyMetaData";
import { CreateStore } from "@a4b/api/src/modules/Monetisation/Puja/types";
import LinkedStoreIds from "./LinkedStoreIds";
import { CustomLoader } from "@/components/Loader";

interface FilterFormProps {
    category: { id: string; name: string; viewType: string };
    currentFilter: Filter | undefined;
    handleCloseForm: (fetchFilters: boolean) => void;
}

const FilterForm: React.FC<FilterFormProps> = ({ category, currentFilter, handleCloseForm }) => {
    const [filterForm] = useForm();
    const { networkInstance, app } = useAppContext();
    const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi;
    const [searchParams] = useSearchParams()
    const [loading, setLoading] = useState(true)

    const isSubmittingRef = useRef<boolean>(false);
    const [isEnabled, setIsEnabled] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isImageRequired, setIsImageRequired] = useState<boolean>(false)
    const [storeList, setStoreList] = useState<CreateStore[]>([])

    const getStoreList = useCallback((async () => {
        try {
            const storeList = await monetisationPujaApi.storesApi.getStoreList(
                {
                    limit: 3000,
                    offset: 0,
                },
                searchParams.get("country_code") || "IN",
                undefined,
                searchParams.get("language_code") || "hi",
                undefined,
                undefined,
                undefined,
                'pooja'
            )

            setStoreList(storeList?.data?.data?.store_list)
        } catch (error) {
            message.error({
                content: 'Failed to fetch store list',
                duration: 3,
            })
            console.log(error)
        }
    }), [monetisationPujaApi.storesApi, searchParams])

    useEffect(() => {
        getStoreList()
    }, [getStoreList])

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [currentFilter])

    useEffect(() => {
        if (!isSubmittingRef?.current) {
            if (currentFilter && isEdit) {
                filterForm?.setFieldsValue(currentFilter);
            } else {
                filterForm?.resetFields();
                filterForm?.setFieldsValue({ category_id: category?.id })
            }
        }
        // if (category?.viewType === 'GRID') {
        //     setIsImageRequired(true)
        // }
    }, [category, currentFilter, filterForm, isEdit])

    useEffect(() => {
        setIsEdit(searchParams?.get('edit-filter') === 'true')
    }, [searchParams])

    const handleCreateUpdateFilter = useCallback((async (payload: any, id?: string) => {
        if (isEdit) {
            try {
                await monetisationPujaApi.filtersApi.updateFilter(id!, payload);
                message.success({ content: "Filter updated Successfully", duration: 3 });
                filterForm?.resetFields();
                handleCloseForm(true)
            } catch (error: any) {
                filterForm?.resetFields();
                message.error({ content: `Error while updating Filter — ${error?.response?.status?.toString()}`, duration: 3 });
                console.log({ error });
            }
        } else {
            try {
                await monetisationPujaApi.filtersApi.createFilter(payload);
                message.success({ content: "Filter created Successfully", duration: 3 });
                filterForm?.resetFields();
                handleCloseForm(true)
            } catch (error: any) {
                filterForm?.resetFields();
                message.error({ content: `Error while creating Filter — ${error?.response?.status?.toString()}`, duration: 3 });
                console.log({ error });
            }
        }
        // setTimeout(() => {
        //     handleCloseForm(true)
        // }, 5000)
        isSubmittingRef.current = false;
    }), [filterForm, handleCloseForm, isEdit, monetisationPujaApi.filtersApi])

    const handleDeleteFilter = useCallback((async (values: Filter) => {
        try {
            await monetisationPujaApi.filtersApi.deleteFilter(values?.id);
            message.success({ content: "Filter deleted", duration: 3 });
            handleCloseForm(true)
        } catch (error: any) {
            message.error({ content: `Error while deleting Filter — ${error?.response?.status?.toString()}`, duration: 3 });
            console.log({ error });
        }
    }), [handleCloseForm, monetisationPujaApi.filtersApi])

    const submitForm = useCallback((() => {
        isSubmittingRef.current = true;
        filterForm
            .validateFields()
            .then((values: Filter) => {
                const payload: any = prepareFilterPayload(values)
                if (isEdit) {
                    handleCreateUpdateFilter(payload, values?.id);
                } else {
                    handleCreateUpdateFilter(payload);
                }
            })
            .catch((error) => {
                console.log("Filter form error", error);
                isSubmittingRef.current = false;
            });
    }), [filterForm, handleCreateUpdateFilter, isEdit])

    const deleteForm = useCallback((() => {
        isSubmittingRef.current = true;
        filterForm
            .validateFields()
            .then((values: Filter) => {
                handleDeleteFilter(values);
            })
            .catch((error) => {
                console.log("Filter form error", error);
                isSubmittingRef.current = false;
            });
    }), [filterForm, handleDeleteFilter])

    if (loading) {
        return <Col style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '20%' }}>
            <Spin indicator={<CustomLoader />} />
        </Col>
    }


    return (
        <Form
            form={filterForm}
            initialValues={currentFilter}
            layout="vertical"
            onFinish={submitForm}
            onValuesChange={() => setIsEnabled(true)}
            style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "start" }}
        >
            <Col style={{ width: "100%", border: "1.5px dashed #d9d9d9", padding: "16px" }}>
                <div>
                    <Row justify='space-between' style={{ display: 'flex' }}>
                        {
                            isEdit &&
                            <div style={{ flexBasis: '48%' }}>
                                <Form.Item
                                    label="Filter ID"
                                    name="id"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </div>
                        }
                        <div style={{ flexBasis: '48%' }}>
                            <Form.Item
                                label="Category ID"
                                name="category_id"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input disabled />
                            </Form.Item>
                        </div>
                    </Row>

                    <Row justify='space-between' style={{ display: 'flex' }}>
                        <div style={{ flexBasis: '48%' }}>
                            <Form.Item
                                label="Name(Hindi)"
                                name="name"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: "Please input name of the Filter!" }]}
                            >
                                <Input placeholder="Enter Hindi Filter name" />
                            </Form.Item>
                        </div>
                        <div style={{ flexBasis: '48%' }}>
                            <Form.Item
                                label="Name(English)"
                                name="name_en"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: "Please input name of the Filter!" }]}
                            >
                                <Input placeholder="Enter English Filter name" />
                            </Form.Item>
                        </div>
                    </Row>

                    <Row justify='space-between' style={{ display: 'flex' }}>
                        <div style={{ flexBasis: '48%' }}>
                            <Form.Item
                                label="Description (Hindi)"
                                name="description"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: false }]}
                            >
                                <Input placeholder="Enter Hindi description" />
                            </Form.Item>
                        </div>
                        <div style={{ flexBasis: '48%' }}>
                            <Form.Item
                                label="Description (English)"
                                name="description_en"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                dependencies={['description']}
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!getFieldValue('description') || value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Description (English) is required if Description (Hindi) is provided.'));
                                        },
                                    }),
                                ]}
                            >
                                <Input placeholder="Enter English description" />
                            </Form.Item>
                        </div>
                    </Row>

                    <Row justify="space-between">
                        <Form.Item
                            label="Image"
                            name="image"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            valuePropName="fileList"
                            rules={[
                                {
                                    min: isImageRequired ? 1 : 0,
                                    max: 1,
                                    validator: async (rules: any, values: any) => {
                                        if (values?.length < rules.min) {
                                            return Promise.reject('Please upload Image')
                                        }
                                    },
                                },
                            ]}
                        >
                            <S3Upload cdnUrl={YODA_CDN_URL} preSignUrl={PUJA_PRESIGN_URL} min={isImageRequired ? 1 : 0} max={1} />
                        </Form.Item>
                        <Form.Item
                            label="Date"
                            name={["meta_data", "date"]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[{ required: false, message: "Please input Date!" }]}
                        >
                            <DatePicker />
                        </Form.Item>
                        <Form.Item
                            label={"Priority"}
                            name={"priority"}
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Row>

                    <Row justify="space-between">
                        <Form.Item
                            label="Slug"
                            name="slug"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                { required: true, message: "Please input the name of the Slug!" },
                                {
                                    validator: (_, value) => {
                                        if (category?.viewType === 'MASTER_SLUG') {
                                            return value && value.startsWith("popular-")
                                                ? Promise.resolve()
                                                : Promise.reject(new Error('Slug must start with "popular-"'));
                                        }
                                        return Promise.resolve(); // Skip validation for other cases
                                    },
                                },
                            ]}
                        >
                            <Input disabled={isEdit} placeholder="Enter slug" />
                        </Form.Item>
                        <Form.Item
                            label="Filter type"
                            name="filter_type"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Select disabled={isEdit} placeholder='Filter type' style={{ width: '150px' }}>
                                <Select.Option value='DEFAULT'>DEFAULT</Select.Option>
                                <Select.Option value='JOURNEY'>JOURNEY</Select.Option>
                                {
                                    category?.viewType === 'MASTER_SLUG' &&
                                    <Select.Option value='MASTER_SLUG'>MASTER SLUG</Select.Option>
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={"Active"}
                            name={"active"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Row>
                </div>
                {/* JourneyMetaData */}
                <Form.Item
                    noStyle
                    shouldUpdate={true}
                >
                    {({ getFieldValue }) =>
                        getFieldValue(['filter_type']) === 'JOURNEY' &&
                        <JourneyMetaData formInstance={filterForm} storeList={storeList} />
                    }
                </Form.Item>
                {/* Linked store Ids */}
                <Form.Item
                    noStyle
                    shouldUpdate={true}
                >
                    {({ getFieldValue }) =>
                        getFieldValue(['filter_type']) === 'MASTER_SLUG' &&
                        <LinkedStoreIds formInstance={filterForm} storeList={storeList} />
                    }
                </Form.Item>
            </Col>


            <Row style={{ width: '100%' }} justify="space-between">
                <Button ghost type="primary" style={{ marginTop: "20px", width: '48%' }} htmlType="submit" disabled={!isEnabled}>
                    {isEdit ? "Update" : "Create"}
                </Button>
                {
                    category?.viewType !== 'MASTER_SLUG' &&
                    <Button onClick={deleteForm} danger style={{ marginTop: "20px", width: '48%', marginLeft: '5px', display: isEdit ? 'block' : 'none' }} htmlType="button" disabled={!isEnabled}>
                        Delete
                    </Button>
                }
            </Row>
        </Form>
    );
};

export default React.memo(FilterForm);
