import { Form, Row, Switch } from "antd"
import React from "react"
import Cards from "./Cards"
import S3Upload from "@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload"

interface Props {
    cdnUrl: string
    preSignUrl: string
    type: string
}

const FestivalHeroSection: React.FC<Props> = ({ cdnUrl, preSignUrl, type }) => {
    return (
        <div style={{
            border: '1px dashed #e0dcdc',
            marginRight: '0px',
            width: '100%',
            padding: '10px',
        }}>
            <Row style={{ width: '100%' }} justify="space-between">
                <Form.Item
                    label={`Background image`}
                    name={['data', 'bg_img_url']}
                    labelCol={{ span: 32 }}
                    wrapperCol={{ span: 32 }}
                    rules={[
                        {
                            required: false,
                            min: 0,
                            max: 1,
                            validator: async (rules: any, values: any) => {
                                if (values?.length < rules.min) {
                                    return Promise.reject('Upload Media')
                                }
                            },
                        },
                    ]}
                    valuePropName='fileList'
                >
                    <S3Upload
                        cdnUrl={cdnUrl}
                        preSignUrl={preSignUrl}
                        min={0}
                        max={1}
                    />
                </Form.Item>
                <Form.Item
                    label={`Background image desktop`}
                    name={['data', 'bg_img_url_desktop']}
                    labelCol={{ span: 32 }}
                    wrapperCol={{ span: 32 }}
                    rules={[
                        {
                            required: false,
                            min: 0,
                            max: 1,
                            validator: async (rules: any, values: any) => {
                                if (values?.length < rules.min) {
                                    return Promise.reject('Upload Media')
                                }
                            },
                        },
                    ]}
                    valuePropName='fileList'
                >
                    <S3Upload
                        cdnUrl={cdnUrl}
                        preSignUrl={preSignUrl}
                        min={0}
                        max={1}
                    />
                </Form.Item>
            </Row>

            <div style={{ marginTop: '20px' }}>
                <span>Cards:</span>
                <Form.List name={['data', 'cards']}>
                    {(fields, { add, remove, move }) => (
                        <>
                            <Row justify="space-between" style={{ width: '100%', marginTop: '10px' }}>
                                <Cards
                                    onSortEnd={({ oldIndex, newIndex }) => {
                                        console.log('oldIndex', oldIndex, 'newIndex', newIndex)
                                        move(oldIndex, newIndex)
                                    }}
                                    distance={1}
                                    helperClass='sortableHelper'
                                    fields={fields}
                                    add={add}
                                    remove={remove}
                                    cdnUrl={cdnUrl}
                                    preSignUrl={preSignUrl}
                                    datatype='Cards'
                                />
                            </Row>
                        </>
                    )}
                </Form.List>
                <Form.Item
                    label='Show ticker'
                    name={['data', 'metadata', 'show_ticker']}
                    rules={[
                        {
                            required: false,
                            message: 'Please enable show ticker',
                        },
                    ]}
                    valuePropName='checked'
                    style={{ marginTop: '20px' }}
                >
                    <Switch />
                </Form.Item>
            </div>
        </div>
    )
}

export default FestivalHeroSection