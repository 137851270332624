import React, { useEffect, useState } from 'react'
import { useForm } from 'antd/lib/form/Form'
import { message } from 'antd'
import { useAppContext } from '@/components/AppContext'
import { CreateSlpFilter, SlpFilters } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { formatFilterCreateOrUpdate } from '../../utils/helper'
import FilterCreation from '../CreateFilterForm'
interface Props {
    showFilterCreation: boolean
    isEditJourney: boolean
    closeFilterCreation: () => void
    formData?: SlpFilters
    filterList: SlpFilters[]
}

const CreateFilter: React.FC<Props> = ({
    showFilterCreation,
    closeFilterCreation: closeFilterCreation,
    isEditJourney,
    formData,
    filterList
}) => {
    const { networkInstance } = useAppContext()
    const { monetisationOfferingsApi } = networkInstance.clientWithHeaders

    const [editFormData, setEditFormData] = useState<any>()
    const [createFilterForm] = useForm()

    useEffect(() => {
        if (isEditJourney) {
            const editForm = {
                ...formData,
                media: {
                    media_type: formData?.media?.media_type,
                    media_url: [formData?.media?.media_url]
                }
            }
            setEditFormData(editForm)
            createFilterForm.setFieldsValue(editForm)
        } else {
            setTimeout(() => {
                createFilterForm.resetFields()
            }, 300)
            setEditFormData({})
        }
    }, [isEditJourney, formData, createFilterForm])

    const handleCreateOrUpdate = async (payload: CreateSlpFilter) => {
        if (isEditJourney) {
            try {
                const id = formData?.id || ''
                await monetisationOfferingsApi?.slpFilterApi?.updateFilters(
                    { ...payload, ...{ id: id } },
                    id,
                )
                message.success('filter updated successfully')
                createFilterForm.resetFields()
                closeFilterCreation()
            } catch (error: any) {
                message.error({
                    content: 'Error While updating filter',
                    duration: 2,
                })
            }
        } else {
            try {
                message.loading({ content: 'Creating filter...', key: 'create-filter' })
                await monetisationOfferingsApi?.slpFilterApi?.createFilters(
                    payload,
                )
                message.success({
                    content: 'Filter Created Successfully',
                    duration: 2,
                })
                createFilterForm.resetFields()
                closeFilterCreation()
            } catch (error: any) {
                message.error({
                    content: 'Error While creating Filter',
                    duration: 2,
                })
            }
        }
    }

    const formSubmithandler = async () => {
        try {
            const values = await createFilterForm.validateFields()
            let payload
            if (isEditJourney) {
                payload = formatFilterCreateOrUpdate(values)
                handleCreateOrUpdate(payload)
            } else {
                payload = formatFilterCreateOrUpdate(values)
                handleCreateOrUpdate(payload)
            }
        } catch (error) {
            console.error('Error preparing payload:', error)
        }
    }

    return (
        <FilterCreation
            visible={showFilterCreation}
            onClose={closeFilterCreation}
            formSubmithandler={formSubmithandler}
            form={createFilterForm}
            initialValues={editFormData}
            isEditJourney={isEditJourney}
            filterList={filterList}
        />
    )
}

export default CreateFilter