import { Form, Row, Col, Button, Input } from "antd"
import React from "react"
import S3Upload from '@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload'
import { DeleteOutlined, HolderOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { SortableContainer, SortableElement } from "react-sortable-hoc"

interface Props {
    fields: any
    cdnUrl: string
    preSignUrl: string
    add: () => void
    remove: (item: any) => void
    datatype: string
}

interface FormItemsProps {
    index: number
    field: any
    remove: (item: any) => void
    cdnUrl: string
    preSignUrl: string
    itemIndex: number
}
const FormItems = (props: FormItemsProps) => {
    const { itemIndex, field, remove, cdnUrl, preSignUrl } = props;
    return (
        <div style={{
            border: '1px solid #e0dcdc',
            marginRight: '0px',
            marginBottom: '10px',
            padding: '10px 0px',
            width: '100%',
            position: 'relative',
            zIndex: 10000000,
            backgroundColor: 'white',
            cursor: 'grab'
        }}>
            <Row style={{ width: '100%', justifyContent: 'space-between', alignItems: "start", paddingLeft: '10px', paddingRight: "10px" }}>
                <HolderOutlined />
                <Row style={{ justifyContent: 'space-between', width: '100%', marginTop: '20px', marginBottom: '10px' }}>
                    <Col span={6}>
                        <Form.Item
                            label={`Image`}
                            name={[field.name, 'image_url']}
                            labelCol={{ span: 32 }}
                            wrapperCol={{ span: 32 }}
                            rules={[
                                {
                                    required: true,
                                    min: 1,
                                    max: 1,
                                    validator: async (rules: any, values: any) => {
                                        if (values?.length < rules.min) {
                                            return Promise.reject('Please upload Image')
                                        }
                                    },
                                },
                            ]}
                            valuePropName='fileList'
                        >
                            <S3Upload
                                cdnUrl={cdnUrl}
                                preSignUrl={preSignUrl}
                                min={1}
                                max={1}
                            />
                        </Form.Item>
                    </Col>
                    <Button
                        style={{ flexBasis: '8%' }}
                        onClick={() => {
                            remove(field.name)
                        }}
                        type='ghost'
                        icon={<DeleteOutlined />}
                        size='middle'
                        danger
                    ></Button>
                </Row>

                <Row style={{ width: '100%' }} justify="space-between">
                    <Form.Item
                        label={`Service title`}
                        name={[field.name, 'service_title']}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ flexBasis: '45%' }}
                        rules={[
                            {
                                required: false,
                                message: 'Enter Service title',
                            },
                        ]}
                    >
                        <Input placeholder='Please type Service title' />
                    </Form.Item>
                    <Form.Item
                        label={`Deeplink`}
                        name={[field.name, 'deeplink']}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ flexBasis: '45%' }}
                        rules={[
                            {
                                required: false,
                                message: 'Enter Deeplink',
                            },
                        ]}
                    >
                        <Input placeholder='Please type Deeplink' />
                    </Form.Item>
                </Row>
            </Row>


        </div>
    )
}

const SortableFormItems = SortableElement(FormItems);

const Services: React.FC<Props> = ({ fields, add, remove, cdnUrl, preSignUrl, datatype }) => {
    return (
        <>
            <Row justify="space-between" style={{ width: '100%' }}>
                {fields && fields.map((field: any, index: number) => {
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <SortableFormItems
                            index={index}
                            itemIndex={index}
                            field={field}
                            remove={remove}
                            cdnUrl={cdnUrl}
                            preSignUrl={preSignUrl}
                        />
                    )
                })}
                <Col span={24} style={{ paddingTop: '10px' }}>
                    <Row justify='start'>
                        <Col span={4}>
                            <Button
                                onClick={() => {
                                    add()
                                }}
                                type='ghost'
                                icon={<PlusCircleOutlined />}
                                size='middle'
                            >
                                {datatype}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default SortableContainer(Services)