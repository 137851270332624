import { CreateSlpFilter } from '@a4b/api/src/modules/Monetisation/Offerings/types'

export const formatFilterCreateOrUpdate = (formData: any): CreateSlpFilter => {
    const formatedObject: CreateSlpFilter = {
        name: formData?.name,
        display_names: formData?.display_names,
        media: {
            media_type: formData?.media?.media_type,
            media_url: formData?.media?.media_url?.[0],
        },
        position: formData?.position,
        active: formData?.active
    }
    return formatedObject
}