import { Form, Row } from "antd"
import React from "react"
import Props from "./Props"

interface ValuePropsProps {
    cdnUrl: string
    preSignUrl: string
    type: string
}

const ValueProps: React.FC<ValuePropsProps> = ({ cdnUrl, preSignUrl, type }) => {
    return (
        <div style={{
            border: '1px dashed #e0dcdc',
            marginRight: '0px',
            width: '100%',
            padding: '10px',
        }}>
            <div style={{ marginTop: '20px' }}>
                <span>Props:</span>
                <Form.List name={['data', 'props']}>
                    {(fields, { add, remove, move }) => (
                        <>
                            <Row justify="space-between" style={{ width: '100%', marginTop: '10px' }}>
                                <Props
                                    onSortEnd={({ oldIndex, newIndex }) => {
                                        console.log('oldIndex', oldIndex, 'newIndex', newIndex)
                                        move(oldIndex, newIndex)
                                    }}
                                    distance={1}
                                    helperClass='sortableHelper'
                                    fields={fields}
                                    add={add}
                                    remove={remove}
                                    cdnUrl={cdnUrl}
                                    preSignUrl={preSignUrl}
                                    datatype='Prop'
                                />
                            </Row>
                        </>
                    )}
                </Form.List>
            </div>
        </div>
    )
}

export default ValueProps