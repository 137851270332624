import React, { useCallback, useEffect, useState } from 'react'
import { message, Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useAppContext } from '@/components/AppContext'
import { YODA_CDN_URL, PUJA_PRESIGN_URL, A4B_APPS } from '@/utils/constants'
import TempleCreation from '../components/StoreForm'
import StoreCloneModal from '@a4b/ui/src/modules/monetisation/puja/molecules/StoreCloneModal'
import { LanguageState } from '@a4b/api/src/modules/Monetisation/Puja/types'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import { formatCreateTempleApi, parseCreateTempleApiEdit } from '../utils/helper'
import { debounce } from 'lodash'
import usePujaStore from '../../../stores/puja-store'
import ConfirmationPopup from '../components/ConfirmationPopup'
import { CustomLoader } from '@/components/Loader'

interface Props {
  isEditJourney: boolean
  currentStep: number
  storeId: string
  nextHandler: (store_id?: string) => void
  previousHandler: () => void
  languageState: LanguageState
}

const TempleCreationStep: React.FC<Props> = ({
  currentStep,
  isEditJourney,
  storeId,
  previousHandler,
  nextHandler,
  languageState
}) => {
  const { networkInstance, countryState, setLanguageContext, setCountryState, app } = useAppContext()
  const { experiment, variant } = usePujaStore()
  const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi
  const [templeFormInstance] = useForm()
  const [searchParams, setSearchParams] = useSearchParams()

  const [slugState, setSlugState] = useState<boolean>(false)
  const [storeData, setStoreData] = useState<any>()
  const [initialStoreData, setInitialStoreData] = useState<any>(undefined)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [showCloneModal, setShowCloneModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [cloneFormInstance] = useForm()
  const { setExperiment, setVariant } = usePujaStore()

  const fetchStoreData = useCallback(async () => {
    try {
      setIsLoading(true);
      if (storeId) {
        const storeResponse = await monetisationPujaApi.storesApi.getStoreById(storeId, countryState?.countryCode, undefined, experiment, variant);
        const parsedData = parseCreateTempleApiEdit(storeResponse?.data?.data, languageState);

        setSearchParams(
          createSearchParams({
            service_type: parsedData?.service_type as any,
            edit: searchParams.get('edit') || '',
            store_id: searchParams.get("store_id") || "",
            language_code: languageState?.monetisationLanguage,
            active: storeResponse?.data?.data?.active ? "true" : "false",
            country_code: countryState?.countryCode
          })
        );

        templeFormInstance.setFieldsValue(parsedData);
        setInitialStoreData(parsedData);
      }
    } catch (error) {
      message.error('Error while fetching store data', 3);
      console.log(error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 300)
    }
  }, [monetisationPujaApi.storesApi, storeId, countryState?.countryCode, templeFormInstance, experiment, variant]);

  useEffect(() => {
    if (isEditJourney) {
      fetchStoreData();
    }
  }, [isEditJourney, fetchStoreData]);

  useEffect(() => {
    !isEditJourney && templeFormInstance.setFieldsValue({
      service_type: 'pooja',
      temple_media: {
        media_type: 'image'
      }
    })
  }, [countryState?.countryCode, isEditJourney, searchParams, templeFormInstance])

  const onFinish = (values: any) => { }
  const onFinishFailed = (error: any) => { }

  const handleStoreCreateOrUpdate = async () => {
    if (isEditJourney) {
      try {
        if (!slugState) {
          await monetisationPujaApi.storesApi.updateStore(
            storeData,
            storeId,
            experiment,
            variant
          )
          message.success({ content: 'Temple Updated Successfully', duration: 2 })
          nextHandler()
        } else {
          message.error({ content: 'Please Enter Valid slug', duration: 2 })
        }
      } catch (error: any) {
        message.error('Error while updating temple' + error?.message, 3)
      }
    } else {
      try {
        if (!slugState) {
          const createResponse = await monetisationPujaApi.storesApi.createStore(
            storeData,
          )
          message.success({ content: 'Temple Created Successfully', duration: 2 })
          setSearchParams(
            createSearchParams({
              edit: searchParams.get('edit') || "true",
              store_id: createResponse?.data?.data?.store_id,
              current_step: currentStep.toString(),
              service_type: storeData?.service_type,
            }),
          )
          setLanguageContext({ ...languageState, isLanguageSelectionActive: false })
          setCountryState({ ...countryState, isCountryCodeActive: false })
          nextHandler(createResponse?.data?.data?.store_id)
        } else {
          message.error({ content: 'Please enter valid slug', duration: 2 })
        }
      } catch (error: any) {
        console.log(error)
        message.error('Error while creating temple' + error?.message, 3)
      }
    }
  }

  const handleOk = () => {
    setShowConfirmation(false)
    handleStoreCreateOrUpdate()
  }

  const handleCancel = () => {
    setShowConfirmation(false)
  }

  const handleCloneModal = () => {
    setShowCloneModal((prevState) => (!prevState))
  }

  const updateStoreByDestinationId = async (data: any, values: any) => {
    const storePayload = {
      ...data,
      language_code: values?.destination_language,
      country_code: values?.destination_country
    }
    try {
      await monetisationPujaApi.storesApi.updateStore(
        storePayload,
        storeId,
      )
      message.success({ content: `Temple Cloned Successfully to language:${values?.destination_language}, country: ${values?.destination_country} `, duration: 2 })
      setShowCloneModal((prevState) => (!prevState))
    } catch (error: any) {
      message.error('Error while cloning temple' + error?.message, 3)
    }
  }

  const getStoreBySourceId = async (values: any) => {
    const countryCode = values?.source_country
    const languageCode = values?.source_language
    try {
      const storeResponse = await monetisationPujaApi.storesApi.getStoreById(
        storeId,
        countryCode,
        languageCode
      )
      const storeData = storeResponse?.data?.data
      if (
        languageCode === storeData?.language_code &&
        countryCode === storeData?.country_code
      ) {
        updateStoreByDestinationId(storeResponse?.data?.data, values)
      } else {
        message.error(`No data available for language: ${languageCode}, country: ${countryCode}`, 3)
      }
    } catch (error) {
      message.error('Error while fetching store by source Id', 3)
    }
  }

  const handleCloneSubmit = () => {
    cloneFormInstance
      .validateFields()
      .then((values: any) => {
        getStoreBySourceId(values)
      })
  }

  const handleTempleCreateOrUpdate = async (values: any) => {
    searchParams.set("service_type", values?.service_type)
    searchParams.set("store_type", values?.store_type)
    searchParams.set("language_code", languageState?.monetisationLanguage)
    searchParams.set("country_code", countryState?.countryCode)
    setSearchParams(searchParams)

    const isTrueSet = (searchParams.get("active")?.toLowerCase() === 'true')
    if (isEditJourney) {
      const formData = formatCreateTempleApi({
        ...values,
        position: templeFormInstance.getFieldValue("position"),
        id: storeId,
      }, languageState, isTrueSet, isEditJourney, countryState?.countryCode)
      setStoreData({ ...formData, country_code: countryState?.countryCode })
      setShowConfirmation(true)
    } else {
      const formData = formatCreateTempleApi(
        values,
        languageState,
        isTrueSet,
        isEditJourney,
        countryState?.countryCode)
      setStoreData({ ...formData, country_code: countryState?.countryCode })
      setShowConfirmation(true)
    }
  }
  const handleTempleCreate = () => {
    templeFormInstance
      .validateFields()
      .then((values: any) => {
        handleTempleCreateOrUpdate(values)
      })
      .catch(error => {
        console.log('Temple Creation error')
      })
  }

  const validateSlugField = async (slug: string) => {
    if (slug) {
      const slugResponse = await monetisationPujaApi.storesApi.getSlugDuplicate(slug)
      setSlugState(slugResponse?.data?.data?.is_duplicate)
      return slugResponse?.data?.data?.is_duplicate
    }
    return
  }
  const debouncedHandler: any = debounce(validateSlugField, 1000)

  const onValuesChange = (values: any) => {
    debouncedHandler(values?.slug)
  }

  useEffect(() => {
    setExperiment(undefined)
    setVariant(undefined)
  }, [])

  if (isLoading) {
    return <div style={{ height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Spin indicator={<CustomLoader />} />
    </div>
  }

  return (
    <>
      <TempleCreation
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        cdnUrl={YODA_CDN_URL}
        preSignUrl={PUJA_PRESIGN_URL}
        form={templeFormInstance}
        initialStoreData={initialStoreData}
        submitFormHandler={handleTempleCreate}
        isEditJourney={isEditJourney}
        currentStep={currentStep}
        previousHandler={previousHandler}
        nextHandler={() => nextHandler()}
        onValuesChange={onValuesChange}
        slugState={slugState}
        handleCloneModal={handleCloneModal}
        acceptLanguage={languageState?.monetisationLanguage}
      />

      {storeData &&
        <ConfirmationPopup
          storeData={storeData}
          visible={showConfirmation}
          handleOk={handleOk}
          handleCancel={handleCancel}
          title={'Store'}
        />
      }
      <StoreCloneModal
        form={cloneFormInstance}
        showCloneModal={showCloneModal}
        handleCloneModal={handleCloneModal}
        handleCloneSubmit={handleCloneSubmit}
      />
    </>
  )
}

export default TempleCreationStep
