//types
import { Shrine } from '@/../../../packages/a4b-api/src/modules/Temple/types'
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { PlusSquareOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col, Image, Pagination,
  PaginationProps,
  Row,
  Space, Table,
  TablePaginationConfig,
  Tabs,
  Tag
} from 'antd'

import styled from 'styled-components'

import React, { useCallback, useEffect, useState } from 'react'


import moment from 'moment'
import CreateStory from './CreateStory'
const defaultPageSize = 10
const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const Label = styled.span`
  font-size: 14px;
  padding-left: 10px;
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`
const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 100%;
`
const ManageStories: React.FC = () => {
  const { networkInstance } = useAppContext()
  const [storiesData, setStories] = useState<Shrine.Stories[]>()
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: defaultPageSize,
  })
  const [totalRecords, setTotalRecords] = useState(defaultPageSize);
  const [currentStory, setCurrentStory] = useState();
  const [showStoryCreation, setShowStoryCreation] = React.useState(false)
  const [isEditJourney, setIsEditJourney] = React.useState(false)
  const [isCloneJourney, setIsCloneJourney] = React.useState(false)
  const [tabType, setTabType] = React.useState("upcoming")




  const closeStoryCreation = () => {
    setShowStoryCreation(false)
    isEditJourney && setIsEditJourney(false)
    isCloneJourney && setIsCloneJourney(false)
    setCurrentStory(undefined)
    getStories({
      limit: pagination.pageSize,
      offset: 0,
      post_type: tabType
    })
  }

  const triggerEdit = (story: any) => {
    setCurrentStory(story)
    setIsEditJourney(true)
    setShowStoryCreation(true)
  }

  const triggerClone = (story: any) => {
    setCurrentStory(story)
    setIsCloneJourney(true)
    setShowStoryCreation(true)
  }

  const getStories = useCallback(async (paginationData: any) => {
    try {
      const stories =
        await networkInstance.clientWithHeaders.shrineApi.getStories(paginationData)
      if (stories?.data?.data?.length >= (pagination.pageSize || defaultPageSize)) {
        setTotalRecords(((pagination.current || 0) + 1) * (pagination.pageSize || defaultPageSize))
      }

      setStories(stories?.data?.data)

    } catch (error) {
      console.log('Cors error:', error)
    }
  }, [networkInstance.clientWithHeaders.shrineApi, pagination])

  useEffect(() => {
    getStories({
      limit: (pagination.pageSize),
      offset: ((pagination.current || 0) - 1) * (pagination.pageSize || defaultPageSize),
      post_type: tabType
    })
  }, [getStories, networkInstance.clientWithHeaders.shrineApi.getStories, pagination, tabType])

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (
    current: number,
    pageSize: number,
  ) => {
    setPagination({ current: ((current || 0)), pageSize })
    setTotalRecords(pageSize * 2)
  }

  const onFinishFailed = (error: any) => {
    //console.log('Item Creation Form Error==>', error)
  }

  const onItemCreateHandler = () => {
    getStories({
      limit: pagination.pageSize,
      offset: 0,
      post_type: tabType
    })
  }

  const dataColumns = [
    {
      title: 'Header Text',
      dataIndex: 'header_texts',
      key: 'header_texts',
      width: '15%',
      render: (text: any, record: any, index: any) => <span>{text?.hi}</span>,
    },
    {
      title: 'Description',
      dataIndex: 'description_texts',
      key: 'description_texts',
      width: '15%',
      render: (text: any, record: any, index: any) => <span>
        {text?.hi}
        <br></br>
        <Tag>{record.id}</Tag>
      </span>,
    },
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      key: 'start_time',
      width: '10%',
      render: (text: any, record: any, index: any) => <span>{text > 0 ? moment.unix(text)?.format('DD-MM-YYYY HH:mm:ss') : text}</span>,
    },
    {
      title: 'End Time',
      dataIndex: 'end_time',
      key: 'end_time',
      width: '10%',
      render: (text: any, record: any, index: any) => <span>{text > 0 ? moment.unix(text)?.format('DD-MM-YYYY HH:mm:ss') : text}</span>,
    },
    {
      title: 'Order',
      dataIndex: 'order',
      width: '8%',
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon.path',
      width: '10%',
      render: (text: any, record: any, index: any) => {
        return (
          (text?.type === 'IMAGE' && <Image src={text?.path} />) ||
          (text?.type === 'video' && (
            <video width={'100px'} height={'50px'} src={text?.path} />
          ))
        )
      },
    },

    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      width: '10%',
      render: (text: any, record: any, index: any) => <span>{text?.includes("@") ? text?.split("@")?.[0] : text}</span>,
    },
    {
      title: 'Updated By',
      dataIndex: 'updated_by',
      key: 'updated_by',
      width: '10%',
      render: (text: string, record: any, index: any) => <span>{text?.includes("@") ? text?.split("@")?.[0] : text}</span>,
    },
    {
      title: 'Last Updated',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: '10%',
      render: (text: any, record: any, index: any) =>
        <span>{text > 0 ? moment(text)?.format('DD-MM-YYYY HH:mm:ss') : text}</span>,
    },
    {
      title: 'Service Type',
      dataIndex: 'service_type',
      key: 'service_type',
      width: '15%',
      render: (text: any, record: any, index: any) =>
        <span>{text}</span>,
    },
    {
      title: 'Action',
      key: `action_${+new Date()}`,
      width: '5%',
      render: (text: any, record: any, index: any) => {
        return (
          <Row style={{ width: 100 }}>
            {<Col span={12}>
              <Button type='link' onClick={() => triggerEdit(record)}>Edit</Button>
              <Button type='link' onClick={() => triggerClone(record)}>Clone</Button>
            </Col>}
          </Row>
        )
      },
    },
  ]

  const onChange = (key: string) => {
    setTabType(key)
  };

  return (
    <Container
      title='Manage Stories'
      style={{ margin: '10px' }}
      extra={
        <Row style={{ width: 200 }} justify='space-between'>
          <Col span={8}>
            <Button type='primary' onClick={() => setShowStoryCreation(true)}>
              <PlusSquareOutlined /> Create Story

            </Button>
          </Col>
        </Row>
      }
    >
      <Space direction={'vertical'} style={{ width: '100%' }}></Space>
      <BodyContainer>
        <Tabs onChange={onChange} style={{ width: "100%" }}>
          <Tabs.TabPane tab="Upcoming" key="upcoming">
            <Table
              style={{ width: '100%' }}
              dataSource={storiesData}
              columns={dataColumns}
              pagination={false}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Live" key="live">
            <Table
              style={{ width: '100%' }}
              dataSource={storiesData}
              columns={dataColumns}
              pagination={false}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Expired" key="Expired">
            <Table
              style={{ width: '100%' }}
              dataSource={storiesData}
              columns={dataColumns}
              pagination={false}
            />
          </Tabs.TabPane>
        </Tabs>
      </BodyContainer>

      <LabelRightAlign>
        <Pagination
          pageSizeOptions={[
            '10',
            '20',
            '30',
            '40',
            '50',
            '100',
            '150',
            '200',
          ]}
          onChange={onShowSizeChange}
          pageSize={pagination.pageSize}
          defaultCurrent={1}
          total={totalRecords}
          showSizeChanger
          current={pagination.current}
        />
      </LabelRightAlign>
      <CreateStory
        showItemCreation={showStoryCreation}
        closeStoryCreation={closeStoryCreation}
        createItemCallback={onItemCreateHandler}
        UpdateItemCallback={onFinishFailed}
        isEditJourney={isEditJourney}
        isCloneJourney={isCloneJourney}
        formData={currentStory}
      />
    </Container>
  )
}

export default withDefaultLayout(ManageStories)
