import { Form, Row, Col, Button, Input } from "antd"
import React from "react"
import { DeleteOutlined, HolderOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { SortableContainer, SortableElement } from "react-sortable-hoc"

interface Props {
    fields: any
    cdnUrl: string
    preSignUrl: string
    add: () => void
    remove: (item: any) => void
    datatype: string
}

interface FormItemsProps {
    index: number
    field: any
    remove: (item: any) => void
    cdnUrl: string
    preSignUrl: string
    itemIndex: number
}
const FormItems = React.memo((props: FormItemsProps) => {
    const { field, remove } = props;
    return (
        <Row style={{ width: '100%', alignItems: "center", paddingLeft: '10px', paddingRight: "10px", marginBottom: '20px', marginTop: '20px' }}>
            <HolderOutlined />
            <Row style={{ justifyContent: 'space-between', alignItems: "center", width: '90%', marginLeft: '20px' }}>
                <Form.Item
                    // label={`Pointers`}
                    name={[field.name]}
                    labelCol={{ span: 32 }}
                    wrapperCol={{ span: 32 }}
                    style={{ flexBasis: '90%', marginBottom: '0px' }}
                    rules={[
                        {
                            required: true,
                            message: 'Enter point',
                        },
                    ]}
                >
                    <Input placeholder='Enter point' style={{ width: '100%' }} />
                </Form.Item>
                <Button
                    onClick={() => {
                        remove(field.name)
                    }}
                    type='ghost'
                    icon={<DeleteOutlined />}
                    size='middle'
                    danger
                ></Button>
            </Row>
        </Row>
    )
})

const SortableFormItems = SortableElement(FormItems);

const Pointers: React.FC<Props> = ({ fields, add, remove, cdnUrl, preSignUrl, datatype }) => {
    return (
        <>
            <div style={{
                border: '1px solid #e0dcdc',
                marginRight: '0px',
                marginBottom: '10px',
                padding: '10px',
                width: '100%',
                position: 'relative',
                zIndex: 10000000,
                backgroundColor: 'white',
                cursor: 'grab'
            }}>
                <Row justify="space-between" style={{ width: '100%' }}>
                    {fields && fields.map((field: any, index: number) => {
                        return (
                            // eslint-disable-next-line react/jsx-key
                            <SortableFormItems
                                index={index}
                                itemIndex={index}
                                field={field}
                                remove={remove}
                                cdnUrl={cdnUrl}
                                preSignUrl={preSignUrl}
                            />
                        )
                    })}
                    <Col span={24} style={{ paddingTop: '10px' }}>
                        <Row justify='start'>
                            <Col span={4}>
                                <Button
                                    onClick={() => {
                                        add()
                                    }}
                                    type='ghost'
                                    icon={<PlusCircleOutlined />}
                                    size='middle'
                                >
                                    {datatype}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default SortableContainer(Pointers)