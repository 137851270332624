import { useSyncExternalStore } from 'use-sync-external-store/shim'
import { create } from 'zustand'

interface PujaState {
  experiment: string | undefined
  setExperiment: (experiment: string | undefined) => void
  variant: string | undefined
  setVariant: (variant: string | undefined) => void
}

const usePujaStore = create<PujaState>(set => ({
  experiment: undefined,
  setExperiment: experiment => set({ experiment: experiment }),
  variant: undefined,
  setVariant: variant => set({ variant: variant }),
}))

export default usePujaStore
