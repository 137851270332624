import { Form, Input, Row } from "antd"
import React from "react"
import Media from "./Media"
import S3Upload from "@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload"

interface Props {
    cdnUrl: string
    preSignUrl: string
    type: string
}

const FestivalSMExperience: React.FC<Props> = ({ cdnUrl, preSignUrl, type }) => {
    return (
        <div style={{
            border: '1px dashed #e0dcdc',
            marginRight: '0px',
            width: '100%',
            padding: '10px',
        }}>
            <Row style={{ width: '100%' }} justify="space-between">
                <Form.Item
                    label={`Title`}
                    name={['data', 'title']}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ flexBasis: '40%' }}
                    rules={[
                        {
                            required: true,
                            message: 'Enter Title',
                        },
                    ]}
                >
                    <Input placeholder='Enter Title' />
                </Form.Item>

                <Form.Item
                    label={`Sub Title`}
                    name={['data', 'sub_title']}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ flexBasis: '45%' }}
                    rules={[
                        {
                            required: false,
                            message: 'Enter subtitle',
                        },
                    ]}
                >
                    <Input placeholder='Please type Sub Title' />
                </Form.Item>
            </Row>

            <div style={{ marginTop: '20px' }}>
                <span>Media:</span>
                <Form.List name={['data', 'media']}>
                    {(fields, { add, remove, move }) => (
                        <>
                            <Row justify="space-between" style={{ width: '100%', marginTop: '10px' }}>
                                <Media
                                    onSortEnd={({ oldIndex, newIndex }) => {
                                        console.log('oldIndex', oldIndex, 'newIndex', newIndex)
                                        move(oldIndex, newIndex)
                                    }}
                                    distance={1}
                                    helperClass='sortableHelper'
                                    fields={fields}
                                    add={add}
                                    remove={remove}
                                    cdnUrl={cdnUrl}
                                    preSignUrl={preSignUrl}
                                    datatype='Media'
                                />
                            </Row>
                        </>
                    )}
                </Form.List>
                <Form.Item
                    label='Play Icon URL'
                    name={['data', 'metadata', 'play_icon_url']}
                    rules={[
                        {
                            required: false,
                            message: 'Please input Play Icon URL',
                        },
                    ]}
                    style={{ marginTop: '20px' }}
                    valuePropName='fileList'
                >
                    <S3Upload
                        cdnUrl={cdnUrl}
                        preSignUrl={preSignUrl}
                        min={1}
                        max={1}
                    />
                </Form.Item>
            </div>
        </div>
    )
}

export default FestivalSMExperience