import { Badge, Button, Col, Row, Tag, Typography, message, Modal } from 'antd'
import styled from 'styled-components'

import React from 'react'
import { Link } from 'react-router-dom'

import { Store } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { useAppContext } from '../../../../../components/AppContext'

const { Title } = Typography
const { confirm } = Modal

interface Props {
  data: Store
  link: string
  onBatchGenerated: () => void
}

const Container = styled.div`
  padding: 10px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  .ant-card-body {
    flex-grow: 1;
  }
  flex-direction: row;
  &:hover {
    cursor: pointer;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`

const ContentWrapper = styled(Title)`
  margin-bottom: 0 !important;
  margin-top: 0;
`

const SubTitleWrapper = styled.span`
  color: #434343;
`

const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 100%;
`

const Anchor = styled(Link)`
  margin-bottom: 20px;
  display: block;
`

const TempleListItemInfo: React.FC<Props> = ({
  data: { id, name, city, store_type, service_type, total_active_batches },
  link,
  onBatchGenerated
}) => {
  const [areBatchesGenerating, setAreBatchesGenerating] = React.useState(false)

  const { networkInstance, monetisationRoles } = useAppContext()
  const { monetisationOfferingsApi } = networkInstance.clientWithHeaders

  const generateBatch = async () => {
    setAreBatchesGenerating(true)
    try {
      await monetisationOfferingsApi.batchApi.generateBatches({
        store_id: id || '',
        user_email: monetisationRoles?.email || '',
      })
      message.success({
        content: 'Batches generated successfully',
        duration: 3,
      })
      onBatchGenerated()
    } catch (error) {
      message.error({
        content: 'Error while generating batch',
        duration: 3,
      })
    } finally {
      setAreBatchesGenerating(false)
    }
  }

  const showConfirm = () => {
    confirm({
      title: 'Are you sure you want to generate batches?',
      content: `This will create new batches for the temple "${name}".`,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        generateBatch()
      },
    })
  }

  const RowContainer = (
    <Container>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        style={{ width: '100%' }}
      >
        <Col className='gutter-row' span={8}>
          <InfoWrapper>
            <ContentWrapper level={5}>{name}</ContentWrapper>
            {<SubTitleWrapper>{city}</SubTitleWrapper>}
          </InfoWrapper>
        </Col>
        <Col className='gutter-row' span={4}>
          <InfoWrapper>
            <ContentWrapper level={5}>
              {total_active_batches}
            </ContentWrapper>
            <SubTitleWrapper>Active Batches</SubTitleWrapper>
          </InfoWrapper>
        </Col>
        <Col className='gutter-row' span={4}>
          <InfoWrapper>
            <ContentWrapper level={5}>
              &nbsp;
            </ContentWrapper>
            <SubTitleWrapper>
              <Tag color='blue'>  {service_type}</Tag>
            </SubTitleWrapper>
          </InfoWrapper>
        </Col>
        <Col className='gutter-row' span={4}>
          <LabelRightAlign>
            <Button type='text'>View Batches &rarr;</Button>
          </LabelRightAlign>
        </Col>
        {service_type === 'offerings' && (
          <Col span={4} style={{ marginTop: '10px' }}>
            <Button
              type='primary'
              loading={areBatchesGenerating}
              ghost
              onClick={(e) => {
                e.preventDefault()
                showConfirm()
              }
              }
            >
              Generate Batch
            </Button>
          </Col>
        )}
      </Row>
    </Container>
  )

  return (
    <Anchor to={link} key={link}>
      {store_type === 'mixed' ? (
        <Badge.Ribbon color='orange' text='Mixed Store'>
          {RowContainer}
        </Badge.Ribbon>
      ) : (
        RowContainer
      )}
    </Anchor>
  )
}

export default TempleListItemInfo
