import React from 'react';
import { Form } from 'antd';
import styled from 'styled-components';
import { FormInstance } from 'antd/es/form';
import { CreateStore } from '@a4b/api/src/modules/Monetisation/Puja/types';

const Block = styled.div`
  background: #f5f5f5;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const Subtitle = styled.h4`
  color: #8c8c8c !important;
`;


interface LinkedStoreIdsProps {
    formInstance: FormInstance
    storeList: CreateStore[]
}

const LinkedStoreIds: React.FC<LinkedStoreIdsProps> = ({ formInstance, storeList }) => {
    // Get the store IDs from the form
    const storeIds = formInstance?.getFieldValue?.('master_slug_data');

    // Map store IDs to their respective names and display as name - id
    const linkedStores = storeIds?.linked_store_ids?.map((storeId: string) => {
        const store = storeList?.find((store: CreateStore) => store?.id === storeId)
        return store ? `${store?.title} - ${store?.id}` : `${storeId} (Not Found)`
    });
    return (
        <div style={{ marginTop: '10px' }}>
            <Form.Item label={<Subtitle>Linked Stores</Subtitle>}>
                {linkedStores && linkedStores.length > 0 ? (
                    linkedStores.map((storeInfo: string, index: number) => (
                        <Block>
                            <div key={index} style={{ marginBottom: '5px' }}>
                                {storeInfo}
                            </div>
                        </Block>
                    ))
                ) : (
                    <div>No linked stores found.</div>
                )}
            </Form.Item>
        </div >

    );
};

export default LinkedStoreIds;
