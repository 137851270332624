/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { Button, Card, Col, Drawer, Form, Input, InputNumber, Row, Select, Switch } from 'antd'
import { YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants'
import S3Upload from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'
import { SlpFilters } from '@/../../../packages/a4b-api/src/modules/Monetisation/Offerings/types'

const { Option } = Select
interface Props {
    onClose: () => void
    visible: boolean
    formSubmithandler: (values: any) => void
    form: any
    initialValues: any
    isEditJourney: boolean
    filterList: SlpFilters[]
}
const FilterCreation: React.FC<Props> = ({
    onClose,
    visible,
    formSubmithandler,
    form,
    initialValues,
    isEditJourney,
    filterList
}) => {
    const validateNameUniqueness = (initialValue?: string) => {
        return async (_: any, value: string) => {
            if (initialValue !== undefined && initialValue === value) {
                return Promise.resolve();
            }

            if (filterList?.some((filter) => filter?.name === value)) {
                return Promise.reject(new Error('Name should be unique'));
            }
            return Promise.resolve();
        };
    };

    return (
        <Drawer
            title={isEditJourney ? 'Update Filter' : 'Create Filter'}
            placement='right'
            onClose={onClose}
            visible={visible}
            destroyOnClose={true}
            width={'45%'}
        >
            <Form
                form={form}
                layout='vertical'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={initialValues}
            >
                <Form.Item
                    label='Name'
                    name='name'
                    labelCol={{ span: 14 }}
                    wrapperCol={{ span: 14 }}
                    rules={[{ required: true, message: 'Please enter filter name!' }, { validator: validateNameUniqueness(initialValues?.name) }]}
                >
                    <Input placeholder='Enter User Name' maxLength={12} />
                </Form.Item>
                <Form.Item
                    label="Display Name"
                    labelCol={{ span: 24 }}>
                    <Card bordered={true}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="English"
                                    name={["display_names", "en"]}
                                    labelCol={{ span: 18 }}
                                    wrapperCol={{ span: 18 }}
                                    rules={[{ required: true, message: "Please enter English display name!" }]}>
                                    <Input placeholder="Enter Display Name English" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Hindi"
                                    name={["display_names", "hi"]}
                                    labelCol={{ span: 18 }}
                                    wrapperCol={{ span: 18 }}
                                    rules={[{ required: true, message: "Please enter Hindi display name!" }]}>
                                    <Input placeholder="Enter Display Name Hindi" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Bengali"
                                    name={["display_names", "bn"]}
                                    labelCol={{ span: 18 }}
                                    wrapperCol={{ span: 18 }}
                                    rules={[{ required: false, message: "Please enter Bengali display name!" }]}>
                                    <Input placeholder="Enter Display Name Bengali" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Gujarati"
                                    name={["display_names", "gu"]}
                                    labelCol={{ span: 18 }}
                                    wrapperCol={{ span: 18 }}
                                    rules={[{ required: false, message: "Please enter Gujarati display name!" }]}>
                                    <Input placeholder="Enter Display Name Gujarati" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Marathi"
                                    name={["display_names", "mr"]}
                                    labelCol={{ span: 18 }}
                                    wrapperCol={{ span: 18 }}
                                    rules={[{ required: false, message: "Please enter Marathi display name!" }]}>
                                    <Input placeholder="Enter Display Name Marathi" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Haryana"
                                    name={["display_names", "hi-hr"]}
                                    labelCol={{ span: 18 }}
                                    wrapperCol={{ span: 18 }}
                                    rules={[{ required: false, message: "Please enter Hindi-Haryana display name!" }]}>
                                    <Input placeholder="Enter Display Name Haryana" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Rajasthan"
                                    name={["display_names", "hi-rj"]}
                                    labelCol={{ span: 18 }}
                                    wrapperCol={{ span: 18 }}
                                    rules={[{ required: false, message: "Please enter Hindi-Rajasthan display name!" }]}>
                                    <Input placeholder="Enter Display Name Rajasthan" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Bihar"
                                    name={["display_names", "hi-bj"]}
                                    labelCol={{ span: 18 }}
                                    wrapperCol={{ span: 18 }}
                                    rules={[{ required: false, message: "Please enter Hindi-Bihar display name!" }]}>
                                    <Input placeholder="Enter Display Name Bihar" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Form.Item>
                <Form.Item
                    label="Media Type"
                    name={["media", "media_type"]}
                    labelCol={{ span: 14 }}
                    wrapperCol={{ span: 14 }}
                    rules={[{ required: true, message: "Please select media type!" }]}
                >
                    <Select placeholder="Select Media Type">
                        <Option value="image">Image</Option>
                        <Option value="lottie">GIF</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name={['media', 'media_url']}
                    label="Media url"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                        {
                            min: 1,
                            max: 1,
                            validator: async (rules: any, values: any) => {
                                if (values?.length < rules.min) {
                                    return Promise.reject('Please upload media')
                                }
                            },
                        },
                    ]}
                    valuePropName='fileList'
                >
                    <S3Upload
                        cdnUrl={YODA_CDN_URL}
                        preSignUrl={YODA_PRESIGN_URL}
                        min={1}
                        max={1}
                    />
                </Form.Item>
                <Form.Item
                    label="Position"
                    name="position"
                    labelCol={{ span: 14 }}
                    wrapperCol={{ span: 14 }}
                    rules={[
                        { required: true, message: "Please enter a position!" },
                        { type: "number", min: 0, message: "Position must be a non-negative number!" },
                    ]}
                >
                    <InputNumber placeholder="Enter Position" style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                    label="Active"
                    name="active"
                    labelCol={{ span: 14 }}
                    wrapperCol={{ span: 14 }}
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ paddingTop: '30px' }}
                >
                    {isEditJourney ? <Button type='primary' onClick={formSubmithandler}>
                        Update Filter
                    </Button> : <Button type='primary' onClick={formSubmithandler}>
                        Create Filter
                    </Button>}
                </Form.Item>
            </Form>
        </Drawer>
    )
}
export default FilterCreation
