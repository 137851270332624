import { YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants';
import S3Upload, { AllowedFormats } from '@a4b/ui/src/modules/monetisation/molecules/S3Upload';
import React from 'react';
import { Modal, Row, Col, Typography, Form, Button, message } from 'antd';

interface Props {
  visible: boolean;
  onClose: () => void;
  storeDetails: any;
  handleSubmitStoreMedia: (payload: any) => void;
  handleClearStoreMedia: () => void;
}

const StoreMediaModal: React.FC<Props> = ({
  visible,
  onClose,
  storeDetails,
  handleSubmitStoreMedia,
  handleClearStoreMedia,
}) => {
  const [form] = Form.useForm();
  const [introVideoFile, setIntroVideoFile] = React.useState(null);
  const [outroVideoFile, setOutroVideoFile] = React.useState(null);

  const handleValueChange = (file: any, fieldName: string) => {
    if (fieldName === 'intro_video_url') {
      setIntroVideoFile(file?.[0] || null);
    } else if (fieldName === 'outro_video_url') {
      setOutroVideoFile(file?.[0] || null);
    }
  };

  const onFinish = () => {
    const payload = {
      intro_video_url: introVideoFile || null,
      outro_video_url: outroVideoFile || null,
    };

    handleSubmitStoreMedia(payload);
    form.resetFields();
  };

  const confirmClearMedia = () => {
    Modal.confirm({
      title: 'Are you sure?',
      content: 'This action will remove both the intro and outro videos from the store. This cannot be undone.',
      okText: 'Yes, Clear Media',
      cancelText: 'Cancel',
      onOk: () => {
        handleClearStoreMedia();
        form.resetFields(); // Reset form after clearing
      },
    });
  };

  return (
    <Modal
      title="Manage Store Media"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <div style={{ marginBottom: '20px' }}>
        <Typography.Title level={5}>Preview Videos</Typography.Title>
        <Row gutter={16}>
          <Col span={12}>
            <Typography.Text>Intro Video:</Typography.Text>
            {storeDetails?.intro_video_url ? (
              <video
                width="100%"
                controls
                src={storeDetails?.intro_video_url}
                style={{ border: '1px solid #ddd', borderRadius: '4px' }}
              />
            ) : (
              <Typography.Text type="secondary"> Not available.</Typography.Text>
            )}
          </Col>
          <Col span={12}>
            <Typography.Text>Outro Video:</Typography.Text>
            {storeDetails?.outro_video_url ? (
              <video
                width="100%"
                controls
                src={storeDetails?.outro_video_url}
                style={{ border: '1px solid #ddd', borderRadius: '4px' }}
              />
            ) : (
              <Typography.Text type="secondary"> Not available.</Typography.Text>
            )}
          </Col>
        </Row>
      </div>

      <Form layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
            //   label="Intro Video"
              name="intro_video_url"
              valuePropName="fileList"
              rules={[
                {
                  min: 0,
                  max: 1,
                  validator: async (rules: any, values: any) => {
                    if (values?.length < rules.min) {
                      return Promise.reject('Upload intro video');
                    }
                  },
                },
              ]}
            >
              <S3Upload
                cdnUrl={YODA_CDN_URL}
                preSignUrl={YODA_PRESIGN_URL}
                min={0}
                max={1}
                onChange={(e) => handleValueChange(e, 'intro_video_url')}
                allowedFileExtension={[AllowedFormats.MP4]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
            //   label="Outro Video"
              name="outro_video_url"
              valuePropName="fileList"
              rules={[
                {
                  min: 0,
                  max: 1,
                  validator: async (rules: any, values: any) => {
                    if (values?.length < rules.min) {
                      return Promise.reject('Upload outro video');
                    }
                  },
                },
              ]}
            >
              <S3Upload
                cdnUrl={YODA_CDN_URL}
                preSignUrl={YODA_PRESIGN_URL}
                min={0}
                max={1}
                onChange={(e) => handleValueChange(e, 'outro_video_url')}
                allowedFileExtension={[AllowedFormats.MP4]}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end" gutter={16}>
          <Col>
            <Button danger onClick={confirmClearMedia}>
              Clear Media
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              Upload Media
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default StoreMediaModal;
