import React, { useEffect, useState } from 'react'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import Stepper from '@/components/ui/Stepper'
// import CreateItem from '../../CreateItem'
import AssigningOfferStep from './screens/AssignInventoriesStep'
import FaqsAndReviewStep from './screens/FaqsAndReviewStep'
import TempleCreationStep from './screens/StoreCreationStep'
import { A4B_APPS } from '@/utils/constants'
import StoreFilter from './components/StoreFilter'
import usePujaStore from '../../stores/puja-store'

interface Props { }

const CreateTempleScreen: React.FC<Props> = () => {
  const { languageState, app } = useAppContext()
  const isSrimandir = app === A4B_APPS.SRIMANDIR
  // const [showItemCreation, setShowItemCreation] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [currentStore, setCurrentStore] = useState('')
  const [isEditJourney, setIsEditJourney] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const { setExperiment, setVariant } = usePujaStore()

  const getPageBreadcrumbs = () => [
    {
      path: isSrimandir ? '/monetisation/puja/temples' : '/astro/puja/temples',
      breadcrumbName: 'Manage Stores',
    },
    {
      path: isSrimandir ? '/monetisation/puja/temple/create' : '/monetisation/astro/puja/temple/create',
      breadcrumbName: 'Create Store',
    },
  ]

  useEffect(() => {
    const current_step = searchParams.get('current_step')
    setCurrentStep(Number(current_step))

    const isEdit = searchParams.get('edit')
    if (!isEdit) {
      return
    }
    setIsEditJourney(true)
  }, [searchParams])


  useEffect(() => {
    const store_id = searchParams.get('store_id') || ''
    setCurrentStore(store_id)
  }, [])

  const next = (store_id = '') => {
    store_id && setCurrentStore(store_id)
    setTimeout(() => {
      setCurrentStep(currentStep + 1)
    }, 100)
    setSearchParams(
      createSearchParams({
        edit: searchParams.get('edit') || '',
        store_id: store_id || currentStore,
        current_step: (currentStep + 1).toString(),
        service_type: searchParams.get('service_type') || '',
        store_type: searchParams.get('store_type') || '',
      }),
    )
  }

  const prev = () => {
    setCurrentStep(currentStep - 1)
    setSearchParams(
      createSearchParams({
        edit: searchParams.get('edit') || '',
        store_id: currentStore,
        current_step: (currentStep - 1).toString(),
        service_type: searchParams.get('service_type') || '',
        store_type: searchParams.get('store_type') || '',
      }),
    )
  }

  // const closeHubForm = () => {
  //   setShowItemCreation(false)
  // }

  const nextHandler = (store_id?: string) => {
    next(store_id)
  }
  const previousHandler = () => {
    prev()
  }

  const stepList = [
    {
      title: 'Setup Store',
      description: 'Add Store information',
      content: (
        <TempleCreationStep
          currentStep={currentStep}
          isEditJourney={isEditJourney}
          storeId={currentStore}
          previousHandler={previousHandler}
          nextHandler={nextHandler}
          languageState={languageState}
        />
      ),
    },
    {
      title: 'Inventories',
      description: 'Add Packages & Add-ons',
      content: (
        <AssigningOfferStep
          currentStep={currentStep}
          isEditJourney={isEditJourney}
          storeId={currentStore}
          previousHandler={previousHandler}
          nextHandler={nextHandler}
          languageState={languageState}
        />
      ),
    },
    {
      title: 'Faq and Review',
      description: 'Publish or save store',
      content: (
        <FaqsAndReviewStep
          currentStep={currentStep}
          isEditJourney={isEditJourney}
          storeId={currentStore}
          previousHandler={previousHandler}
          nextHandler={nextHandler}
          languageState={languageState}
        />
      ),
    },
  ]
  // const itemCreateOrUpdateHandler = () => { }
  return (
    <div style={{ padding: '0 40px' }}>
      <AdminBreadcrumb routes={getPageBreadcrumbs()} />
      <Stepper
        steps={stepList}
        currentStep={currentStep}
        title={isEditJourney ? <StoreFilter /> : 'Create Store'}
      />
      {/* <CreateItem
        showItemCreation={showItemCreation}
        closeItemCreation={closeHubForm}
        createItemCallback={itemCreateOrUpdateHandler}
        UpdateItemCallback={itemCreateOrUpdateHandler}
        isEditJourney={isEditJourney}
      /> */}
    </div>
  )
}

export default withDefaultLayout(CreateTempleScreen)
