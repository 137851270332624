import { CheckCircleFilled, CloseCircleFilled, DoubleRightOutlined, EyeInvisibleOutlined, EyeOutlined, FieldTimeOutlined, PlusSquareOutlined, } from '@ant-design/icons'
import {
    Button,
    Card,
    Col,
    Dropdown,
    Menu,
    message,
    Modal,
    Row,
    Segmented,
    Select,
    Space,
    Table,
    Tag
} from 'antd'
import styled from 'styled-components'

import React, { useEffect, useState, useCallback } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import type { TablePaginationConfig } from 'antd/es/table'
import { AstroAnalytics, AstroDetails, Astrologer } from '@a4b/api/src/modules/Monetisation/Astrologer/types'
import { useSearchParams } from "react-router-dom";
import moment from 'moment'
import { useAppContext } from '@/components/AppContext'
import { A4B_APPS } from '@/utils/constants'
import MonetisationAstroApi from '@a4b/api/src/modules/Monetisation/Astrologer'
import { agentPayloadData } from '../../../CreateAstrologer/screens/CreateAstrologerStep/helper'

const statusColor: any = {
    online: "#b7eb8f",
    offline: "#ffa39e",
    engaged: "#fffb8f",
    archived: ""
}

interface Props {
    astroList: AstroDetails[]
    handleTableChange: (paginationParams: TablePaginationConfig) => void
    pagination: TablePaginationConfig
    updateStatus: (id: string, status: string) => Promise<void>
    segment: AstrologerSegment,
    filter: Tfilter,
    setFilter: React.Dispatch<React.SetStateAction<Tfilter>>
    astroAgentAnalytics: {
        [key: string]: AstroAnalytics;
    } | undefined
    astroAnalytics: {
        [key: string]: AstroAnalytics;
    } | undefined
}


export enum AstrologerSegment {
    LIST = "Astrologer list",
    ANALYTICS = "Astrologer analytics"
}

export const FILTERS = ["today", "week", "month"];
export type Tfilter = "today" | "week" | "month";

export const FILTER_COLOR_MAPPING: {
    [key: string]: string;
} = {
    today: "#bae0ff",
    week: "#f4ffb8",
    month: "#b5f5ec"
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.51);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  border: 1px solid rgba(255, 255, 255, 0.83);

  .ant-card-body {
    flex-grow: 1;
  }
`

const Bar = styled.div<{ backgroundColor: string }>`
    height: 20px;
    background-color: ${props => props.backgroundColor};
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    align-items: center;
    padding: 0 5px;
    margin-top: 110px;
    margin-bottom: 110px;
    position: relative;
    flex-basis: 160px;
    flex-grow: 0;
    flex-shrink: 0;
`

const BarContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 300px;
    overflow-x: auto;
`
const StyledP = styled.p`
    &:first-letter {
        text-transform: uppercase;
    }
`

const StyledTable = styled(Table)`
    .table-tencent {
        background: linear-gradient(90deg,#91caff,#e6f4ff,#91caff);
    }
    .table-default {
        background: linear-gradient(90deg,#ffc069,#fff7e6,#ffc069);
    }
`
const STATUS_TO_COLOR_CODE_MAPPING: {
    [key: string]: string;
} = {
    online: "#d9f7be",
    offline: "#ffccc7",
    engaged: "#ffffb8",
}

const timeLineTopStyle: React.CSSProperties = { position: "absolute", top: "-99px", display: "flex", flexDirection: "column", alignItems: "center" };
const timeLineBottomStyle: React.CSSProperties = { position: "absolute", top: "22px", display: "flex", flexDirection: "column-reverse", alignItems: "center" };

const ManageAstroTable: React.FC<Props> = ({
    astroList,
    handleTableChange,
    pagination,
    updateStatus,
    segment,
    filter,
    setFilter,
    // astroAgentAnalytics,
    astroAnalytics
}) => {
    const location = useLocation();
    const { networkInstance, countryState, app, languageState } = useAppContext()
    const { monetisationAstroApi, monetisationNewAstroApi } = networkInstance.clientWithHeaders
    const [updateStatusData, setUpdateDataStatus] = useState<{ id: string, status: string }>();
    const [queryParams, setQueryParams] = useSearchParams();
    const [isUserNumberHidden, setIsUserNumberHidden] = useState<number[]>([]);
    const isSrimandirApp = app === A4B_APPS.SRIMANDIR
    const api: MonetisationAstroApi = (isSrimandirApp) ? monetisationAstroApi : monetisationNewAstroApi
    const languageCode = isSrimandirApp ? languageState?.monetisationLanguage : 'en'
    const countryCode = isSrimandirApp ? countryState?.countryCode : 'US'
    const [showCopyAgentModal, setShowCopyAgentModal] = useState(false);
    const [selectedAgentId, setSelectedAgentId] = useState<string | null>(null);
    const [copyCountryCode, setCopyCountryCode] = useState('')
    const [astroAgentAnalytics, setAstroAgentAnalytics] = useState<{
        [key: string]: AstroAnalytics
    }>()
    const [expandedRows, setExpandedRows] = useState<string[]>([]);

    const getAgentAnalytics = useCallback(async (agentId: string) => {
        if (segment === AstrologerSegment.ANALYTICS) {
            try {
                const response = await api.agentApi.getAgentAnalytics(agentId, filter);
                setAstroAgentAnalytics(prevState => ({
                    ...prevState,
                    [agentId]: response?.data?.data
                }));
            } catch (error) {
                message.error('Error while fetching Agent analytics', 3);
            }
        }
    }, [segment, api.agentApi, filter]);

    useEffect(() => {
        if (segment === AstrologerSegment.ANALYTICS) {
            expandedRows.forEach(agentId => {
                if (!astroAgentAnalytics?.[agentId]) {

                    getAgentAnalytics(agentId);
                }
            });
        }
    }, [astroAgentAnalytics, expandedRows, getAgentAnalytics, segment]);

    const isMonetizationPage = location.pathname.includes('monetisation');


    const getAgentDetails = async (agentId: string | null) => {
        if (!agentId) {
            return null;
        }
        try {
            const agentResponse: any = await api.agentApi.getAgentById(agentId, countryState?.countryCode, false);
            return agentResponse?.data?.data;
        } catch (error: any) {
            message.error('Error while fetching Agent', 2);
            return null;
        }
    };

    const menu = (record: any) => {
        return (
            <Menu onClick={(e) => handleMenuClick(e, record)}>
                {isMonetizationPage ? (
                    <>
                        <Menu.Item key="US">Copy to Astro-Now US</Menu.Item>
                        <Menu.Item key="IN">Copy to Astro-Now India</Menu.Item>
                    </>
                ) : (
                    <>
                        <Menu.Item key="IN">Copy to Sri Mandir India</Menu.Item>
                        <Menu.Item key="US">Copy to Sri Mandir US</Menu.Item>
                    </>
                )}
            </Menu>
        );
    };

    const handleMenuClick = (e: any, record: any) => {
        setSelectedAgentId(record?.id);
        setCopyCountryCode(e?.key)
        setShowCopyAgentModal(true);
    };


    let dataColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,

        },
        {
            title: "Profile Pic",
            dataIndex: "profile_picture",
            key: "profile_picture",
            render: (text: string, record: any) => {
                // eslint-disable-next-line no-lone-blocks
                {
                    return segment === AstrologerSegment.LIST ?
                        <img src={text} alt="profile_pic" style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "100%",
                            padding: "1px",
                            border: `3px solid ${statusColor[record.status]}`,
                            opacity: record.status === "archived" ? 0.5 : 1
                        }} />
                        : <div style={{ background: statusColor[record.status] }}>
                            <img src={text} alt="profile_pic" style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "100%",
                                padding: "1px",
                                border: `3px solid ${statusColor[record.status]}`,
                                opacity: record.status === "archived" ? 0.5 : 1
                            }} />
                        </div>
                }

            },

        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',

        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',

        },
        {
            title: "Label",
            dataIndex: "label",

        },
        {
            title: "Call",
            dataIndex: "call",
            render: (text: boolean) => {
                return <div>
                    {
                        text ? <CheckCircleFilled style={{ color: "green" }} /> : <CloseCircleFilled style={{ color: "red" }} />
                    }
                </div>
            },

        },
        {
            title: "Chat",
            dataIndex: "chat",
            render: (text: boolean) => {
                return <div>
                    {
                        text ? <CheckCircleFilled style={{ color: "green" }} /> : <CloseCircleFilled style={{ color: "red" }} />
                    }
                </div>
            },

        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',

        },
        {
            title: "Active time",
            key: "agent_stats",
            dataIndex: "id",
            render: (id: string) => {
                return <div>
                    {
                        astroAgentAnalytics && astroAgentAnalytics[id] &&
                        <div>{astroAgentAnalytics[id].agent_stats[0].value}</div>
                    }
                </div>
            },

        },
        {
            title: "Time spent on consultation",
            key: "agent_stats",
            dataIndex: "id",
            render: (id: string) => {
                return <div>
                    {
                        astroAgentAnalytics && astroAgentAnalytics[id] &&
                        <div>{astroAgentAnalytics[id].agent_stats[1].value}</div>
                    }
                </div>
            },

        },
        {
            title: "Completed consultation",
            key: "agent_stats",
            dataIndex: "id",
            sorter: (a: any, b: any) => {
                const aValue = parseInt(astroAgentAnalytics?.[a.id]?.agent_stats?.[2]?.value ?? "0");
                const bValue = parseInt(astroAgentAnalytics?.[b.id]?.agent_stats?.[2]?.value ?? "0");
                return aValue - bValue;
            },
            render: (id: string) => {
                return <div>
                    {
                        astroAgentAnalytics && astroAgentAnalytics[id] &&
                        <div>
                            {astroAgentAnalytics[id].agent_stats[2].value}
                        </div>
                    }
                </div>
            },

        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            width: 140,
            sorter: (a: any, b: any) => a.status.localeCompare(b.status),
            render: (text: any, record: any) => {
                let tagColor = "green"
                if (text === 'offline') {
                    tagColor = "red"
                } else {
                    tagColor = "cyan"
                }

                return <Select
                    disabled={segment === AstrologerSegment.ANALYTICS}
                    style={{ width: "120px", border: "none" }}
                    onChange={(value) => {
                        setUpdateDataStatus({ id: record.id, status: value });
                    }}
                    value={text}
                >
                    <Select.Option value='online'><Tag style={{ color: "black" }} color={statusColor["online"]}>Online</Tag></Select.Option>
                    <Select.Option value='offline'> <Tag style={{ color: "black" }} color={statusColor["offline"]}>Offline</Tag></Select.Option>
                    <Select.Option value='engaged'><Tag style={{ color: "black" }} color={statusColor["engaged"]}>Engaged</Tag></Select.Option>
                    <Select.Option value='archived'><Tag style={{ color: "black" }} color={statusColor["archived"]}>Archived</Tag></Select.Option>
                </Select>

            },

        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (text: string, record: any) => {
                const link = isSrimandirApp ? `/monetisation/astrologer/create?edit=true&agent_id=${record.id}` : `/astro/astrologer/create?edit=true&agent_id=${record.id}`
                return <Link to={link}>
                    <Button type='link' color='cyan' style={{ cursor: 'pointer' }}>Edit</Button>
                </Link>
            },

        },
        {
            title: '',
            dataIndex: "",
            key: "copyAstrologer",
            // width: 180,
            render: (text: string, record: any) => {
                return (
                    <Dropdown overlay={menu(record)}>
                        <Button type='link' color='cyan' style={{ cursor: 'pointer' }}>Copy</Button>
                    </Dropdown>
                )
            }
        },
    ]

    if (segment === AstrologerSegment.LIST) {
        dataColumns = dataColumns.filter((column) => {
            return (column.key !== "agent_stats")
        })
    } else {
        dataColumns = dataColumns.filter((column) => {
            return (column.key !== "id" && column.key !== "position" && column.key !== "edit" && column.key !== "status")
        })
    }


    const onModalOk = async () => {
        setShowCopyAgentModal(false);

        if (selectedAgentId) {
            const agentDetails = await getAgentDetails(selectedAgentId);
            const { countryCode, ...updatedAgentDetailsWithoutCountryCode } = agentDetails;


            if (agentDetails) {
                const updatedAgentDetails = { ...updatedAgentDetailsWithoutCountryCode, country_code: copyCountryCode, app_code: isMonetizationPage ? 'astro' : 'srimandir' };

                try {
                    const putResponse = await api.agentApi.copyAgentById(selectedAgentId, updatedAgentDetails, countryCode);
                    message.success(putResponse?.data?.data)
                } catch (error: any) {
                    message.error('Error while copying Agent', 2);
                }
            } else {
                message.error('Agent details not found', 2);
            }
        }
    };

    const onModalCancel = () => {
        setShowCopyAgentModal(false);
    };

    return (
        <>

            <Container
                title={<div>
                    {
                        AstrologerSegment.ANALYTICS === segment &&

                        <div style={{ marginBottom: "20px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: "20px" }}>
                                {astroAnalytics && Object.keys(astroAnalytics).map((filter) => {
                                    const data = astroAnalytics[filter].agent_stats.map((stat) => (
                                        {
                                            key: stat.key,
                                            name: stat.key,
                                            active_time: stat.value
                                        }
                                    ));
                                    return (
                                        <Card style={{ flexGrow: "1", background: FILTER_COLOR_MAPPING[filter] }} key={filter}>
                                            <p style={{ textAlign: "center" }}>{filter.toUpperCase()}</p>

                                            <Table size='small' columns={[
                                                {
                                                    title: "Name",
                                                    dataIndex: "name",
                                                },
                                                {
                                                    title: "Active time",
                                                    dataIndex: "active_time",
                                                }
                                            ]} dataSource={data} bordered pagination={false} showHeader={false}></Table>
                                        </Card>
                                    );
                                })}
                            </div>
                        </div>
                    }
                    <Segmented
                        defaultValue={segment}
                        style={{ marginBottom: 8 }}
                        onChange={(value: any) => {
                            queryParams.set('segment', value)
                            setQueryParams(queryParams)
                        }}
                        options={[AstrologerSegment.LIST, AstrologerSegment.ANALYTICS]}
                    />
                    {
                        segment === AstrologerSegment.ANALYTICS &&
                        <>
                            <label style={{ paddingLeft: '10px' }}>Filter: </label>
                            <Select value={filter} style={{ width: "200px" }} onChange={(filter: Tfilter) => {
                                setFilter(filter);
                            }} >
                                <Select.Option value='today'>Today</Select.Option>
                                <Select.Option value='week'>Week</Select.Option>
                                <Select.Option value='month'>Month</Select.Option>
                            </Select>
                        </>
                    }

                </div>}
                style={{ width: '100%' }}
                extra={
                    segment === AstrologerSegment.LIST &&
                    <Row>
                        <Col>
                            <Button type='primary'>
                                <Link to={isSrimandirApp ? '/monetisation/astrologer/create' : '/astro/astrologer/create'}>
                                    <PlusSquareOutlined /> Create Astrologer
                                </Link>
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <Modal title="Change status" visible={!!updateStatusData} onOk={() => {
                    updateStatus(updateStatusData?.id as string, updateStatusData?.status as string)
                    setUpdateDataStatus(undefined);
                }} onCancel={() => {
                    setUpdateDataStatus(undefined);
                }}>
                    <p>Are you sure you want to change status  <b>{updateStatusData?.status}</b> ?</p>
                </Modal>
                <Space direction={'vertical'} style={{ width: '100%' }}></Space>
                <Row>
                    <Col span={28}>
                        <Table
                            sticky={true}
                            rowKey={(record: any) => record?.id}
                            columns={dataColumns}
                            size='small'
                            bordered
                            dataSource={astroList?.filter((astro) => astro.status !== "archived")}
                            pagination={pagination}
                            onChange={handleTableChange}
                            expandable={
                                filter === "today" && segment === AstrologerSegment.ANALYTICS ?
                                    {
                                        expandedRowRender: (record) => {
                                            if (!astroAgentAnalytics || !astroAgentAnalytics[record.id]) {
                                                return <div>Loading...</div>;
                                            }
                                            const analytics = astroAgentAnalytics[record.id];
                                            return <>
                                                {analytics?.agent_timeline?.length > 0 &&
                                                    <BarContainer>
                                                        {
                                                            analytics.agent_timeline.map((timeline, index) => {
                                                                if (index === analytics.agent_timeline.length - 1) return null;
                                                                const time1 = moment(analytics.agent_timeline[index].created_at);
                                                                const time2 = moment(analytics.agent_timeline[index + 1].created_at);

                                                                const diffInMinutes = time2.diff(time1, 'minutes');
                                                                return <Bar key={index} backgroundColor={STATUS_TO_COLOR_CODE_MAPPING[timeline.status]}>
                                                                    <div style={index % 2 === 0 ? timeLineTopStyle : timeLineBottomStyle}>
                                                                        <Card size='small' style={{ background: "#f5f5f5", fontSize: "12px" }}>
                                                                            <StyledP> {timeline.status} for {diffInMinutes} minutes </StyledP>
                                                                        </Card>
                                                                        <div style={{ width: '1px', height: "40px", background: "#ccc" }}></div>
                                                                    </div>
                                                                    <div><FieldTimeOutlined /> {moment(analytics.agent_timeline[index].created_at).format("HH:mm")}</div>
                                                                    <div> <DoubleRightOutlined /></div>
                                                                    <div><FieldTimeOutlined /> {moment(analytics.agent_timeline[index].created_at).format("HH:mm")}</div>
                                                                </Bar>
                                                            })
                                                        }
                                                    </BarContainer>
                                                }
                                                <hr />
                                                <StyledTable
                                                    rowClassName={(record: any, index) => record?.service_provider === 'tencent' ? 'table-tencent' : 'table-default'}
                                                    size='small' columns={[
                                                        {
                                                            title: "Call Id",
                                                            dataIndex: "id",
                                                        },
                                                        {
                                                            title: "User Id",
                                                            dataIndex: "user_id",
                                                        },
                                                        {
                                                            title: "User number",
                                                            dataIndex: "user_number",
                                                            render: (text: string, record, index) => {
                                                                const isIndex = isUserNumberHidden.includes(index)
                                                                return (
                                                                    <div>
                                                                        {
                                                                            !isIndex ?
                                                                                <span >{"* ".repeat(12)}</span> :
                                                                                <span >{text}</span>

                                                                        }
                                                                        <Button type="link" icon={!isIndex ? <EyeOutlined /> : <EyeInvisibleOutlined />} onClick={() => {
                                                                            setIsUserNumberHidden(isIndex ?
                                                                                isUserNumberHidden.filter((i) => i !== index) : [...isUserNumberHidden, index]
                                                                            )
                                                                        }} />
                                                                    </div>
                                                                );
                                                            }

                                                        },
                                                        {
                                                            title: "Agent number",
                                                            dataIndex: "agent_number",
                                                        },
                                                        {
                                                            title: "Duration (Sec)",
                                                            dataIndex: "duration",
                                                        },
                                                        {
                                                            title: "Charge",
                                                            dataIndex: "charge",
                                                        },
                                                        {
                                                            title: "Status",
                                                            dataIndex: "status",
                                                        },
                                                        {
                                                            title: "Service provider",
                                                            dataIndex: "service_provider",
                                                        },
                                                        {
                                                            title: "App version",
                                                            dataIndex: "app_version",

                                                        },
                                                        {
                                                            title: "Created at",
                                                            dataIndex: "created_at",
                                                            render: (text: string) => {
                                                                return moment(text).format("YYYY-MM-DD HH:mm:ss")
                                                            }
                                                        }
                                                    ]} dataSource={analytics.calls} ></StyledTable>
                                            </>
                                        },
                                        onExpand: (expanded, record) => {
                                            setExpandedRows(prev =>
                                                expanded
                                                    ? [...prev, record.id]
                                                    : prev.filter(id => id !== record.id)
                                            );
                                        }
                                    } : undefined
                            }
                        />
                    </Col>
                </Row>
            </Container >
            <Modal
                title={isMonetizationPage ? "Copy to Astro Now" : "Copy to Sri Mandir"}
                visible={showCopyAgentModal}
                onOk={onModalOk}
                onCancel={onModalCancel}
                okText="Yes, Copy"
            >
                <p>Are you sure you want to copy to {isMonetizationPage ? "Astro Now" : "Sri Mandir"}?</p>
            </Modal>
        </>
    )
}

export default ManageAstroTable