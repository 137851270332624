import React from 'react'
import type { SelectProps } from 'antd';
import styled from 'styled-components'
import TextArea from 'antd/lib/input/TextArea'
import { Button, Col, Form, Input, InputNumber, Row, Select, Card, Space, Checkbox } from 'antd'
import S3Upload, { AllowedFormats } from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'
import Gallery from '@a4b/ui/src/modules/monetisation/molecules/Astrologer/Gallery'
import Certificates from '@a4b/ui/src/modules/monetisation/molecules/Astrologer/Certificates'
import { SUPPORTED_LANGUAGES_LIST } from '@a4b/ui/src/modules/admin/constants';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const options: SelectProps['options'] = []

const { Option } = Select

const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 10px;
`
const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
interface TempleCreateOrUpdateProps {
    cdnUrl: string
    preSignUrl: string
    form: any
    nextHandler: () => void
    previousHandler: () => void
    currentStep: number
    isEditJourney: boolean
    submitFormHandler: () => void
    acceptLanguage: string,
    variant: VARIANT_TYPES
}

export enum VARIANT_TYPES {
    CONTROLED = 'CONTROLLED',
    VARIANT = 'VARIANT',
}

const languages: any = {
    en: [
        {
            id: 'Hindi',
            value: 'Hindi'
        },
        {
            id: 'English',
            value: 'English'
        },
        {
            id: 'Kannada',
            value: 'Kannada'
        },
        {
            id: 'Telugu',
            value: 'Telugu'
        },
        {
            id: 'Tamil',
            value: 'Tamil'
        },
    ],
    hi: [
        {
            id: 'हिंदी',
            value: 'हिंदी'
        },
        {
            id: 'अंग्रेज़ी',
            value: 'अंग्रेज़ी'
        },
        {
            id: 'कन्नड़',
            value: 'कन्नड़'
        },
        {
            id: 'तेलुगु',
            value: 'तेलुगु'
        },
        {
            id: 'तमिल',
            value: 'तमिल'
        },
    ]
}

const AstrologerCreation: React.FC<TempleCreateOrUpdateProps> = ({
    cdnUrl,
    preSignUrl,
    form,
    previousHandler,
    nextHandler,
    currentStep,
    isEditJourney,
    submitFormHandler,
    acceptLanguage,
    variant
}) => {
    return (
        <Container
            title='Manage Astrologer'
            style={{ width: '100%' }}
        >
            <Row>
                <Col span={24}>
                    <Form
                        form={form}
                        layout='vertical'
                        name='create temple'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                    >
                        <Row>
                            <Col span={11}>
                                <Row justify='space-between'>
                                    <Form.Item
                                        label={`Profile pic`}
                                        name='profile_pic'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[
                                            {
                                                min: 1,
                                                max: 1,
                                                validator: async (rules: any, values: any) => {
                                                    if (values?.length < rules.min) {
                                                        return Promise.reject('Upload Profile pic')
                                                    }
                                                },
                                            },
                                        ]}
                                        valuePropName='fileList'
                                    >
                                        <S3Upload
                                            disabled={variant === VARIANT_TYPES.VARIANT}
                                            cdnUrl={cdnUrl}
                                            preSignUrl={preSignUrl}
                                            min={1}
                                            max={1}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={`Profile banner`}
                                        name='profile_banner'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[
                                            {
                                                min: 1,
                                                max: 1,
                                                validator: async (rules: any, values: any) => {
                                                    if (values?.length < rules.min) {
                                                        return Promise.reject('Upload Profile banner')
                                                    }
                                                },
                                            },
                                        ]}
                                        valuePropName='fileList'
                                    >
                                        <S3Upload
                                            cdnUrl={cdnUrl}
                                            preSignUrl={preSignUrl}
                                            min={1}
                                            max={1}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={`Intro audio`}
                                        name='intro_audio'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[
                                            {
                                                min: 1,
                                                max: 1,
                                                validator: async (rules: any, values: any) => {
                                                    if (values?.length < rules.min) {
                                                        return Promise.reject('Upload Intro audio')
                                                    }
                                                },
                                            },
                                        ]}
                                        valuePropName='fileList'
                                    >
                                        <S3Upload
                                            cdnUrl={cdnUrl}
                                            preSignUrl={preSignUrl}
                                            min={1}
                                            max={1}
                                            allowedFileExtension={[AllowedFormats.MP3_VIDEO, AllowedFormats.MP3_AUDIO]}
                                        />
                                    </Form.Item>
                                </Row>
                                <Form.Item
                                    label='User Id'
                                    name='user_id'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        { required: true, message: 'Please enter User Id' },
                                        {
                                            validator: (_, value) => {
                                                const isNumeric = /^\d+$/.test(value)
                                                if (isNumeric) {
                                                    return Promise.resolve()
                                                }
                                                if (!isNumeric) {
                                                    return Promise.reject('User Id should only contain numbers')
                                                }
                                            },
                                        },
                                    ]}
                                >
                                    <InputNumber disabled={isEditJourney} style={{ width: '49%' }} placeholder='Enter User Id' />
                                </Form.Item>

                                <Row justify='space-between' style={{ marginTop: '15px' }}>
                                    <Form.Item
                                        label='Name'
                                        name='name'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        style={{ width: '49%' }}
                                        rules={[
                                            { required: true, message: 'Please input Name' },
                                        ]}
                                    >
                                        <Input placeholder='Enter Name' />
                                    </Form.Item>
                                    {/* <Form.Item
                                        label='Display Name'
                                        name='display_name'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        style={{ width: '49%' }}
                                        rules={[
                                            { required: true, message: 'Please input Display Name' },
                                        ]}
                                    >
                                        <Input placeholder='Enter temple Display Name' />
                                    </Form.Item> */}
                                </Row>
                                <Row>
                                    <Form.List name="display_names" initialValue={[{ language: 'hi' }, { language: 'en' }]}>
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <Space key={key} style={{ display: 'flex', minHeight: '70px', width: "100%", alignItems: 'center', padding: '15px', background: '#f0f0f0', borderBottom: '1px solid #fff' }} align="baseline">
                                                        <Form.Item
                                                            label={'Select Language'}
                                                            {...restField}
                                                            name={[name, 'language']}
                                                            rules={[{
                                                                required: true, validator(rule, value, callback) {
                                                                    if (form.getFieldValue('display_names').filter((title: any) => title.language === value).length > 1) {
                                                                        callback("Names for this language is already added!")
                                                                    } else {
                                                                        callback(undefined)
                                                                    }
                                                                },
                                                            }]}
                                                        >
                                                            <Select
                                                                style={{ width: '180px' }}
                                                                placeholder={'Select language'}
                                                            >
                                                                {SUPPORTED_LANGUAGES_LIST.map(language =>
                                                                    <Select.Option key={language.value} value={language.value}>
                                                                        {language.alt_name} ({language.name})
                                                                    </Select.Option>
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item
                                                            label={'Display name'}
                                                            {...restField}
                                                            name={[name, 'name']}
                                                            rules={[{ required: true }]}
                                                        >
                                                            <Input placeholder="Name" />
                                                        </Form.Item>
                                                        {!['hi', 'en'].includes(form.getFieldValue(['display_names', name, 'language'])) && <Button style={{ border: '1px solid light' }} onClick={() => remove(name)}>
                                                            <MinusCircleOutlined /> remove display name
                                                        </Button>
                                                        }
                                                    </Space>
                                                ))}
                                                <Form.Item>
                                                    <Button style={{ width: '200px' }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                        Add display name
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Row>
                                <Form.Item
                                    label='Description'
                                    name='description'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Description',
                                        },
                                    ]}
                                >
                                    <TextArea placeholder='Enter Description' />
                                </Form.Item>
                                <Form.Item
                                    label='Education'
                                    name='education'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input education',
                                        },
                                    ]}
                                >
                                    <TextArea placeholder='Enter education' />
                                </Form.Item>
                                <Row justify='space-between'>
                                    <Form.Item
                                        label='Phone'
                                        name='phone'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        style={{ width: '49%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input phone',
                                            },
                                        ]}
                                    >
                                        <Input disabled={variant === VARIANT_TYPES.VARIANT} addonBefore="+91" placeholder='Enter phone' />
                                    </Form.Item>
                                    <Form.Item
                                        label='E-mail'
                                        name='email'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        style={{ width: '49%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input email',
                                            },
                                        ]}
                                    >
                                        <Input disabled={variant === VARIANT_TYPES.VARIANT} placeholder='Enter email' />
                                    </Form.Item>
                                </Row>
                                <Form.Item
                                    label='Languages known'
                                    name='languages_spoken'
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 22 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select languages',
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="tags"
                                        size='middle'
                                        placeholder="Please select language"
                                        defaultValue={[]}
                                        tokenSeparators={[',']}
                                        style={{ width: '110%' }}
                                        allowClear
                                        options={options}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label='Expertise'
                                    name='expertise'
                                    labelCol={{ span: 28 }}
                                    wrapperCol={{ span: 22 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select expertise',
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="tags"
                                        size='middle'
                                        placeholder="Please select expertise"
                                        defaultValue={[]}
                                        tokenSeparators={[',']}
                                        style={{ width: '110%' }}
                                        allowClear
                                        options={options}
                                    />
                                </Form.Item>

                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Form.Item name={"call"} label={"Call"} valuePropName='checked'>
                                        <Checkbox></Checkbox>
                                    </Form.Item>

                                    <Form.Item name={"chat"} label={"Chat"} valuePropName='checked'>
                                        <Checkbox></Checkbox>
                                    </Form.Item>

                                    <Form.Item style={{ flexBasis: "200px" }} name={"label"} label={"Label"}>
                                        <Select style={{ width: '100%' }}>
                                            <Option value={""}>{""}</Option>
                                            {
                                                acceptLanguage === 'hi' ? <>
                                                    <Option value={"लोकप्रिय"}>लोकप्रिय</Option>
                                                    <Option value={"प्रसिद्ध"}>प्रसिद्ध</Option>
                                                    <Option value={"सर्वश्रेष्ठ"}>सर्वश्रेष्ठ</Option>
                                                </> :
                                                    <>
                                                        <Option value={"Top Choice"}>Top Choice</Option>
                                                        <Option value={"Celebrity"}>Celebrity</Option>
                                                        <Option value={"Genius"}>Genius</Option>
                                                    </>
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name={"wait_list_enabled"} label={"Wait list enabled"} valuePropName='checked'>
                                        <Checkbox></Checkbox>
                                    </Form.Item>
                                    {
                                        variant === VARIANT_TYPES.VARIANT &&
                                        <Form.Item hidden name={"variant_enabled"} label={"Variant enabled"} valuePropName='checked'>
                                            <Checkbox disabled ></Checkbox>
                                        </Form.Item>
                                    }
                                </div>
                            </Col>
                            <Col span={1}></Col>
                            <Col span={11}>
                                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                    <Form.Item
                                        label='Base charge'
                                        name='base_charge'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[
                                            { required: true, message: 'Please Base Charge' },
                                        ]}
                                    >
                                        <InputNumber style={{ width: 130 }} placeholder='Enter Base Charge' />
                                    </Form.Item>
                                    <Form.Item
                                        label='Actual Charge'
                                        name='actual_charge'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[
                                            { required: true, message: 'Please input Actual Charge' },
                                        ]}
                                    >
                                        <InputNumber style={{ width: 130 }} placeholder='Enter Actual Charge' />
                                    </Form.Item>
                                    <Form.Item
                                        label='Experience'
                                        name='experience'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[
                                            { required: true, message: 'Please input Experience' },
                                        ]}
                                    >
                                        <InputNumber disabled={variant === VARIANT_TYPES.VARIANT} style={{ width: 120 }} placeholder='Enter Experience' />
                                    </Form.Item>
                                    <Form.Item
                                        label='Currency'
                                        name='currency'
                                    >
                                        <Select
                                            placeholder='Select Currency'
                                            style={{ width: 150 }}
                                            disabled={variant === VARIANT_TYPES.VARIANT}
                                        >
                                            <Option value='INR'>INR</Option>
                                            <Option value='USD'>USD</Option>
                                        </Select>
                                    </Form.Item>
                                </Row>
                                <Row justify='space-between'>
                                    <Form.Item
                                        label='Rating'
                                        name='rating'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[
                                            { required: true, message: 'Please input Rating' },
                                        ]}
                                    >
                                        <InputNumber disabled={variant === VARIANT_TYPES.VARIANT} defaultValue={0} />
                                    </Form.Item>
                                    <Form.Item
                                        label='Status'
                                        name='status'
                                        rules={[{ required: true, message: 'Select Status' }]}
                                        style={{ width: '25%' }}
                                    >
                                        <Select
                                            placeholder='Select Status'
                                            disabled={isEditJourney}
                                        >
                                            <Option value='online'>Online</Option>
                                            <Option value='offline'>Offline</Option>
                                            <Option value='engaged'>Engaged</Option>
                                            <Option value='archived'>Archived</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label='Position'
                                        name='position'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        rules={[
                                            { required: true, message: 'Please Position' },
                                        ]}
                                    >
                                        <InputNumber style={{ width: 150 }} placeholder='Enter Position' />
                                    </Form.Item>
                                </Row>
                                <Row style={{ gap: "20px" }}>
                                    <Form.Item name={"total_calls"} label={"Total calls"}>
                                        <InputNumber disabled={variant === VARIANT_TYPES.VARIANT} min={0}></InputNumber>
                                    </Form.Item>

                                    <Form.Item name={"total_reviews"} label={"Total reviews"}>
                                        <InputNumber min={0}></InputNumber>
                                    </Form.Item>

                                    <Form.Item name={"total_chats"} label={"Total chats"}>
                                        <InputNumber disabled={variant === VARIANT_TYPES.VARIANT} min={0}></InputNumber>
                                    </Form.Item>
                                    <Form.Item name={"app_code"} label={"App Name"}>
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Row>

                                <Form.Item
                                    label={`Gallery`}
                                    name='gallery'
                                    rules={[
                                        { required: true },
                                    ]}
                                >
                                    <Form.List name={'gallery'}>
                                        {(fields, { add, remove }) => {
                                            return (
                                                <Gallery disabled={variant === VARIANT_TYPES.VARIANT} fields={fields} add={add} remove={remove} cdnUrl={cdnUrl} preSignUrl={preSignUrl} />
                                            )
                                        }}
                                    </Form.List>
                                </Form.Item>
                                <Form.Item
                                    label={`Certificates`}
                                    name='certificates'
                                    rules={[
                                        { required: true },
                                    ]}
                                >
                                    <Form.List name={'certificates'} >
                                        {(fields, { add, remove }) => {
                                            return (
                                                <Certificates disabled={variant === VARIANT_TYPES.VARIANT} fields={fields} add={add} remove={remove} cdnUrl={cdnUrl} preSignUrl={preSignUrl} />
                                            )
                                        }}
                                    </Form.List>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form >
                </Col >
                <Col span={24}>
                    <LabelRightAlign>
                        <Space>
                            {currentStep > 0 && (
                                <Button type='primary' onClick={previousHandler}>
                                    Previous
                                </Button>
                            )}
                            {isEditJourney && currentStep !== 1 && (
                                <Button type='primary' onClick={submitFormHandler}>
                                    Update
                                </Button>
                            )}
                            {currentStep < 3 && currentStep !== 1 && !isEditJourney ? (
                                <Button type='primary' onClick={submitFormHandler}>
                                    Submit
                                </Button>
                            ) : (
                                currentStep !== 2 && (
                                    <Button type='primary' onClick={nextHandler}>
                                        Next
                                    </Button>
                                )
                            )}
                        </Space>
                    </LabelRightAlign>
                </Col>
            </Row >
        </Container>

    )
}
export default AstrologerCreation
