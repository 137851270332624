import { Form, Input, Row } from "antd"
import React from "react"


const TextSection: React.FC = () => {
    return (
        <div style={{
            border: '1px dashed #e0dcdc',
            marginRight: '0px',
            width: '100%',
            padding: '10px',
        }}>
            <Row style={{ width: '100%' }} justify="space-between">
                <Form.Item
                    label={`Title`}
                    name={['data', 'title']}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ flexBasis: '40%' }}
                    rules={[
                        {
                            required: false,
                            message: 'Enter Title',
                        },
                    ]}
                >
                    <Input placeholder='Enter Title' />
                </Form.Item>

                <Form.Item
                    label={`Description`}
                    name={['data', 'description']}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ flexBasis: '45%' }}
                    rules={[
                        {
                            required: false,
                            message: 'Enter description',
                        },
                    ]}
                >
                    <Input placeholder='Please type description' />
                </Form.Item>
            </Row>
        </div>
    )
}

export default TextSection