import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { CreateItem, Pagination, Product, ProductData, Response } from './types'

class ProductApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  createItem = (payload: CreateItem) =>
    this.api.post<Response<any>>(
      `/gw1/puja/internal/v1/products/create-product`,
      payload,
    )
  updateItem = (payload: CreateItem, product_id: string) =>
    this.api.put<Response<any>>(
      `/gw1/puja/internal/v1/products/${product_id}/update-product`,
      payload,
    )

  getProductList = (
    paginationProps: Pagination,
    experimentCode?: string,
    variantCode?: string,
  ) => {
    const params: Record<string, string | Pagination> = {
      ...(paginationProps && { paginationProps: paginationProps }),
      ...(experimentCode && { experiment: experimentCode }),
      ...(variantCode && { variant: variantCode }),
    }
    return this.api.get<Response<Product[]>>(
      `/gw1/puja/internal/v1/products/product-list`,
      {
        params,
      },
    )
  }
}

export default ProductApi
