//context
import { useAppContext } from '@/components/AppContext'
//hoc
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import { message } from 'antd'
import { useForm } from 'antd/lib/form/Form'

//hooks
import { useEffect, useState, useCallback } from 'react'
import { CONTENT_CDN_URL, CONTENT_TOI_PRESIGN_URL } from '@/utils/constants'

//organisms
import ManageTempleCreateOrUpdate from '@a4b/ui/src/modules/monetisation/molecules/Temple/ManageTempleCreateOrUpdate'
import { useNavigate, useSearchParams } from 'react-router-dom'

//types
import { parseCities, parseStores, parseTempleByIdResponse, payloadTempleData } from './helper'
import type { TablePaginationConfig } from 'antd/es/table'
import { CreateStore } from '@a4b/api/src/modules/Monetisation/Offerings/types'

const getPageBreadcrumbs = () => [
    {
        path: '/temples/manage-temples',
        breadcrumbName: 'Manage Temple',
    },
    {
        path: '/temples/create',
        breadcrumbName: 'Create Temple',
    },
]

const ManageTempleCreate = () => {
    const [templeForm] = useForm()
    const navigate = useNavigate()
    const { networkInstance, countryState } = useAppContext()
    const { clientWithHeaders } = networkInstance
    const { monetisationOfferingsApi } = clientWithHeaders;
    const [searchParams, setSearchParams] = useSearchParams()
    const [templeId] = useState<string>(searchParams.get('temple_id') as string)
    const [language, setLanguage] = useState<string>(searchParams.get('lang') as string)
    const [cityList, setCityList] = useState<any>()
    const [StoreList, setStoreList] = useState<any>()
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 1000,
    })

    const getCityList = useCallback((async () => {
        try {
            const CityListResponse = await clientWithHeaders.contentApi.temples.getCityList(true)
            const parsedCityResponse = parseCities(CityListResponse?.data?.data?.Cities, language)
            setCityList(parsedCityResponse)
        } catch (error) {
            message.error('Error while fetching Cities', 3)
        }
    }), [clientWithHeaders.contentApi.temples, language])

    useEffect(() => {
        getCityList()
    }, [getCityList, language])

    const getTempleByID = useCallback(async (lang: string) => {
        try {
            if (!templeId || !language) {
                return
            }
            //getAllCities
            const CityListResponse = await clientWithHeaders.contentApi.temples.getCityList(true)
            const parsedCityResponse = parseCities(CityListResponse?.data?.data?.Cities, language)
            setCityList(parsedCityResponse)

            //getAllTemples
            const storeList = await monetisationOfferingsApi.storesApi.getStoreList(undefined,'dropdown', countryState?.countryCode, true, language)
            const parsedStoreResponse = parseStores(storeList?.data?.data?.store_list)
            setStoreList(parsedStoreResponse)

            //getTempleById
            const TempleResponse = await clientWithHeaders.contentApi.temples.getTempleById(lang, templeId)
            const parsedTempleResponse = parseTempleByIdResponse(TempleResponse?.data)
            templeForm.setFieldsValue(parsedTempleResponse)
        } catch (error: any) {
            if (error.response.data.error.message === `no data found in this language=${lang}`) {
                return
            } else {
                message.error(error.message, 2)
            }
        }
    }, [templeId, language, clientWithHeaders.contentApi.temples, monetisationOfferingsApi.storesApi, pagination.pageSize, countryState?.countryCode, templeForm])

    useEffect(() => {
        getTempleByID(language)
    }, [getTempleByID, language])

    const getStoreList = useCallback((async () => {
        try {
            const storeList = await monetisationOfferingsApi.storesApi.getStoreList(undefined,'dropdown', countryState?.countryCode)
            const positionData: any = {}
            storeList?.data?.data?.store_list.map(
                (item: CreateStore) => (positionData[item.id] = item?.position || 0),
            )
        } catch (error) {
            message.error({
                content: 'Error while fetching store list',
                duration: 3,
            })
            console.log(error)
        }
    }), [monetisationOfferingsApi.storesApi, pagination.pageSize, countryState?.countryCode])

    useEffect(() => {
        getStoreList()
    }, [getStoreList, countryState?.countryCode])

    const handleTempleCreateOrUpdate = async (values: any) => {
        if (templeId) {
            //Update journey
            try {
                const PayloadTemple = payloadTempleData(values, language)
                await clientWithHeaders.contentApi.temples.updateTemple(PayloadTemple, templeId)
                message.success('Temple updated successfully', 2)
                navigate("/temples/manage-temples")
            } catch (error) {
                message.error('Error while updating temple', 2)
            }
        } else {
            //Create journey
            try {
                const PayloadTemple = payloadTempleData(values, language)
                await clientWithHeaders.contentApi.temples.createTemple(PayloadTemple)
                message.success('Temple created successfully', 2)
                navigate("/temples/manage-temples")
            } catch (error) {
                message.error('Error while creating temple', 2)
            }
        }
    }

    const submitFormHandler = () => {
        templeForm
            .validateFields()
            .then((values: any) => {
                const slugValidationPattern = /^[a-z0-9]+(?:-[a-z0-9]+)*$/
                slugValidationPattern.test(values?.slug) ? handleTempleCreateOrUpdate(values) : message.warning('slug pattern is incorrect', 3)
            })
            .catch(error => {
                console.log('City Creation error')
            })
    }

    const handleLanguageChange = (language: string) => {
        setLanguage(language)
        searchParams.set('lang', language)
        setSearchParams(searchParams)
        getTempleByID(language)
    }

    return (
        <div style={{ padding: '0 40px' }}>
            <AdminBreadcrumb routes={getPageBreadcrumbs()} />
            <ManageTempleCreateOrUpdate
                cdnUrl={CONTENT_CDN_URL}
                preSignUrl={CONTENT_TOI_PRESIGN_URL}
                form={templeForm}
                submitFormHandler={submitFormHandler}
                cityList={cityList!}
                StoreList={StoreList}
                language={language}
                handleLanguageChange={handleLanguageChange}
            />
        </div>
    )
}

export default withDefaultLayout(ManageTempleCreate)
