/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unused-vars */
//constants
import { PlusOutlined } from '@ant-design/icons'
import { Modal, Progress, Tooltip, Upload, UploadProps, message } from 'antd'
import type { RcFile, UploadFile } from 'antd/lib/upload'
import axios, { AxiosRequestHeaders } from 'axios'
import jwt_decode from "jwt-decode";

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import update from 'immutability-helper';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface PresignedResponse {
  url: string
  attachmentId: string
}
interface Response<T> {
  data: T
}

const type = 'DragableUploadList';

export enum AllowedFormats {
  'JPEG' = 'image/jpeg',
  'JPG' = 'image/jpg',
  'PNG' = 'image/png',
  'WEBP' = 'image/webp',
  'GIF' = 'image/gif',
  'MP4' = 'video/mp4',
  'MP3_AUDIO' = 'audio/mpeg',
  'MP3_VIDEO' = 'video/mpeg',
}
interface Props {
  preSignUrl: string
  min: number
  max: number
  value?: string[]
  onChange?: (value: any) => void
  cdnUrl: string
  fileList?: any
  preserveFilename?: boolean
  preserveRawFilename?: boolean
  allowedFileExtension?: string[]
  considerOnlyExtension?: boolean
  headers?: any
  maxSizeInKiloBytes?: number
  templeCode?: string
  disabled?: boolean
}
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })
interface DragableUploadListItemProps {
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  file: UploadFile;
  fileList: UploadFile[];
  moveRow: (dragIndex: any, hoverIndex: any) => void;
}

const DragableUploadListItem = ({
  originNode,
  moveRow,
  file,
  fileList,
}: DragableUploadListItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const index = fileList.indexOf(file);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>;
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', height: '100%' }}
    >
      {file.status === 'error' ? errorNode : originNode}
    </div>
  );
};

const S3Upload: React.FC<Props> = ({
  preSignUrl,
  cdnUrl,
  onChange,
  max = 1,
  fileList,
  preserveFilename,
  allowedFileExtension,
  preserveRawFilename,
  considerOnlyExtension = false,
  headers,
  maxSizeInKiloBytes = 200000,
  templeCode = null,
  disabled = false,
}) => {
  const [uploadedFileList, setUploadedFile] = useState<any[]>([])
  const [previewVisible, setPreviewVisible] = useState(false)
  const [uploadStarted, setUploadStarted] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [progress, setProgress] = useState(0)


  useEffect(() => {
    if (fileList && !uploadStarted) {
      // Convert fileList to an array if it is string
      const fileArray = Array.isArray(fileList) ? fileList : [fileList];

      const form =
        fileArray?.map((item: string) => {
          const lastIndex = item?.lastIndexOf('/');
          const fileName = item?.slice(lastIndex + 1);
          return {
            uid: uuidv4(),
            status: 'done',
            ...(item?.includes('/content/temple/')
              ? {
                name: considerOnlyExtension ? item : fileName,
                url: item,
              }
              : {
                name: considerOnlyExtension ? item : fileName,
                url: considerOnlyExtension ? item : `${cdnUrl}/${fileName}`,
              }),
            key: fileName,
          };
        }) || [];

      setUploadedFile(form);
      onChange?.(fileArray);
    }
    if (!fileList) {
      onChange?.([])
    }
  }, [fileList])


  let actionUrl = preSignUrl || ""
  let fileName = ''
  const uploadProps = {
    async customRequest({ onSuccess, onError, file, onProgress }: any) {
      if (!file?.name) {
        return {
          abort() {
            console.log('File not found.')
          },
        }
      }

      if (preserveFilename) {
        const split = file?.name.split('.')
        const splicedStr = split
          .slice(0, split.length - 1)
          .join('.')
          .replaceAll(' ', '_')
        fileName = `${splicedStr}_${Date.now()
          .toString()
          .slice(-4)
          }.${file?.name?.split('.')?.pop()}`
        if (allowedFileExtension?.length) {
          if (!allowedFileExtension?.includes(file?.type)) {
            message.warn("Please upload proper file format.")
            return
          }
        }
      } else if (preserveRawFilename) {
        fileName = file?.name
        if (fileName?.split('').filter((item) => item.includes(".")).length > 1) {
          message.warn("File name should contain only 1 dot at last.Please alter and upload again")
          return
        }
        if (allowedFileExtension?.length) {
          if (!allowedFileExtension?.includes(file?.type)) {
            message.warn("Please upload proper file format.")
            return
          }
        }
        // if ((file?.size / 1024) > maxSizeInKiloBytes) {
        //   message.warn(`Please updoad files less than: ${maxSizeInKiloBytes} Kilo Bytes`)
        //   return
        // }
      } else {
        fileName = `${+new Date()}.${file?.name?.split('.')?.pop()}`
      }
      actionUrl = actionUrl + (considerOnlyExtension ? file?.name?.split('.')?.pop() : fileName)
      if (templeCode) {
        actionUrl = `${actionUrl}&temple_code=${templeCode}&asset_type=avatar`
      }
      try {
        /*
          This code needs refactor later on as this duplicate the header. 
          Ideally the axios client should be a singleton object but that is not the case right now.
        */
        const token = JSON.parse(localStorage.getItem('userinfo') || '{}')?.access_token
        const jwtRes: any = token ? jwt_decode(token) : {}
        const _headers: AxiosRequestHeaders = {
          'x-afb-app-ver': '1.0.0',
          'x-afb-device-id': window.navigator.userAgent,
          'x-afb-platform': 'web',
          'Authorization': 'Bearer ' + token
        }
        if (jwtRes.sub) {
          _headers['X-AFB-R-UID'] = jwtRes.sub;
        }
        const presignedUrl = await axios.get<Response<any>>(
          actionUrl, { headers: { ...headers, ..._headers } }
        )
        const res = await axios.put(presignedUrl?.data?.data?.url, file, {
          headers: { 'Content-Type': file?.type },
          onUploadProgress: (event: any) => {
            const percent = Math.floor((event.loaded / event.total) * 100)
            setProgress(percent)
            if (percent === 100) {
              setTimeout(() => setProgress(0), 1000)
            }
            onProgress({ percent: (event.loaded / event.total) * 100 })
          },
        })

        onSuccess(res)
        setUploadStarted(true)
        const latestUpdatedUpload = [
          ...uploadedFileList,
          {
            uid: `${uuidv4()}_${fileName}`,
            name: considerOnlyExtension ? presignedUrl?.data?.data?.attachmentId : fileName,
            status: 'done',
            ...(presignedUrl?.data?.data?.path?.startsWith('/content/temple/') ? {
              url: considerOnlyExtension ? cdnUrl + new URL(presignedUrl?.data?.data?.url).pathname : `${cdnUrl}${presignedUrl?.data?.data?.path}`,
            } : {
              url: considerOnlyExtension ? cdnUrl + new URL(presignedUrl?.data?.data?.url).pathname : `${cdnUrl}/${fileName}`,
            }
            ),
            type: file?.type,
          },
        ]
        setUploadedFile(latestUpdatedUpload)

        onSuccess(file)
        const uploadedList = latestUpdatedUpload.map((item: any) => item.url)
        onChange?.(uploadedList)
      } catch (error) {
        const latestUpdatedUpload = [
          ...uploadedFileList,
          {
            uid: `${uuidv4()}_${fileName}`,
            name: fileName,
            status: 'error',
            response: 'upload error',
            url: `${cdnUrl}/${fileName}`,
            type: file?.type,
          },
        ]
        setUploadedFile(latestUpdatedUpload)
        onError('Upload failed')
        return {
          abort() {
            console.log('Failed while generating presigned url.')
          },
        }
      }

      return {
        abort() {
          console.log('upload progress is aborted.')
        },
      }
    },
  }
  const handleRemove: UploadProps['onRemove'] = (fileList: any) => {
    const filteredFileList = uploadedFileList?.filter(
      (file: any) => file.uid != fileList.uid,
    )
    setUploadedFile([...filteredFileList])
    const uploadedList = filteredFileList?.map((item: any) => item.url)
    onChange?.(uploadedList)
  }
  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewVisible(true)
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
    )
  }
  const handleCancel = () => setPreviewVisible(false)

  const beforeUpload = (file: RcFile) => {
    if (allowedFileExtension?.length) {
      if (!allowedFileExtension?.includes(file?.type)) {
        message.warn(`Please updoad proper file formats like : ${allowedFileExtension.join(",")}`)
        return false
      } else if ((file?.size / 1024) > maxSizeInKiloBytes) {
        message.warn(`Please updoad files less than : ${maxSizeInKiloBytes} Kilo Bytes`)
        return false
      } else {
        return true
      }
    }
  }

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = fileList[dragIndex];
      const tmp = uploadedFileList[hoverIndex]
      uploadedFileList[hoverIndex] = uploadedFileList[dragIndex]
      uploadedFileList[dragIndex] = tmp;
      setUploadedFile(
        [...uploadedFileList]
      );
      onChange?.(uploadedFileList.map((item: any) => item.url))
      setUploadStarted(true)
    },
    [uploadedFileList],
  );

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Upload
          disabled={disabled}
          onRemove={handleRemove}
          fileList={uploadedFileList}
          listType={'picture-card'}
          beforeUpload={beforeUpload}
          {...uploadProps}
          itemRender={(originNode, file, currFileList) => (
            <DragableUploadListItem
              originNode={originNode}
              file={file}
              fileList={currFileList}
              moveRow={moveRow}
            />
          )}
        >
          {uploadedFileList?.length < max ? (
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          ) : (
            ''
          )}
        </Upload>
        {progress > 0 ? <Progress percent={progress} /> : null}
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt='image preview' style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </DndProvider>
    </>
  )
}

export default S3Upload