import { convertMediaUrlsToArrays } from '@/utils/helper'

export const festivalHeroSectionMediaKeys = [
  'bg_img_url',
  'bg_img_url_desktop',
  'img_url',
  'mini_img_url',
]

export const festivalSMExperienceMediaKeys = [
  'media_url',
  'media_thumbnail',
  'play_icon_url',
]

export const valuePropsMediaKeys = ['icon_url']

export const festivalTrustMarkerMediaKeys = [
  'pointer_icon_url',
  'media_url',
  'media_thumbnail',
]

export const festivalServicesMediaKeys = ['image_url']
export const topCategoriesMediaKeys = ['media_url', 'thumbnail']

export const parseDataOnEdit = (sectionResponse: any) => {
  if (!sectionResponse?.data?.data) return sectionResponse?.data?.data
  const sectionType = sectionResponse.data.data?.section_type
  const itemType = sectionResponse.data.data?.item_type
  const data = sectionResponse.data.data.data
  const parsedResponse = {
    ...sectionResponse.data.data,
    target_config: JSON.stringify(
      { ...sectionResponse.data.data.target_config },
      null,
      '\t',
    ),
  }

  if (sectionType === 'static_ui') {
    switch (itemType) {
      case 'festival_hero_section':
        return {
          ...parsedResponse,
          data: convertMediaUrlsToArrays(data, festivalHeroSectionMediaKeys),
        }
      case 'festival_sm_experience':
        return {
          ...parsedResponse,
          data: convertMediaUrlsToArrays(data, festivalSMExperienceMediaKeys),
        }
      case 'value_props':
        return {
          ...parsedResponse,
          data: convertMediaUrlsToArrays(data, valuePropsMediaKeys),
        }
      case 'festival_trust_marker':
        return {
          ...parsedResponse,
          data: convertMediaUrlsToArrays(data, festivalTrustMarkerMediaKeys),
        }
      case 'festival_services':
        return {
          ...parsedResponse,
          data: convertMediaUrlsToArrays(data, festivalServicesMediaKeys),
        }
      case 'top_categories_static':
        return {
          ...parsedResponse,
          data: convertMediaUrlsToArrays(data, topCategoriesMediaKeys),
        }
      default:
        return parsedResponse
    }
  }

  switch (data?.type) {
    case 'info_media':
      const infoMedia =
        data?.info_media?.map((item: any) => ({
          ...item,
          media_url: [item?.media_url],
          thumbnail: [item?.thumbnail],
        })) || []
      return {
        ...parsedResponse,
        data: { ...data, info_media: infoMedia },
      }

    case 'info_media_web':
      const infoMediaWeb = {
        title: data?.info_media_web?.title,
        sub_title: data?.info_media_web?.sub_title,
        media_list:
          data?.info_media_web?.media_list?.map((item: any) => ({
            ...item,
            media_url: [item?.media_url],
            thumbnail: [item?.thumbnail],
          })) || [],
      }
      return {
        ...parsedResponse,
        data: { ...data, info_media_web: infoMediaWeb },
      }

    case 'previous_pooja_media':
      const previousMedia =
        data?.media_cards?.map((item: any) => ({
          ...item,
          media: {
            ...item?.media,
            media_url: [item?.media?.media_url],
            media_thumbnail: [item?.media?.media_thumbnail],
          },
        })) || []
      return {
        ...parsedResponse,
        data: { ...data, media_cards: previousMedia },
      }

    case 'banner_items':
      const poojaItems =
        data?.pooja_items?.map((item: any) => ({
          ...item,
          media_url: [item?.media_url],
        })) || []
      const offeringItems =
        data?.offering_items?.map((item: any) => ({
          ...item,
          media_url: [item?.media_url],
        })) || []
      return {
        ...parsedResponse,
        data: {
          ...data,
          pooja_items: poojaItems,
          offering_items: offeringItems,
        },
      }

    case 'today_importance':
      const todayImportance = {
        ...data?.today_importance,
        media: {
          ...data?.today_importance?.media,
          media_url: [data?.today_importance?.media?.media_url],
        },
      }
      return {
        ...parsedResponse,
        data: { ...data, today_importance: todayImportance },
      }

    case 'srimandir_services':
      const srimandirServices =
        data?.srimandir_services?.map((item: any) => ({
          ...item,
          media: {
            ...item?.media,
            media_url: [item?.media?.media_url],
            media_type: [item?.media?.media_type],
          },
          icon_url: [item?.icon_url],
        })) || []
      return {
        ...parsedResponse,
        data: { ...data, srimandir_services: srimandirServices },
      }

    case 'glimpses_of_services':
      const glimpsesOfServices =
        data?.media_cards?.map((item: any) => ({
          ...item,
          media: {
            ...item?.media,
            media_url: [item?.media?.media_url],
            media_thumbnail: [item?.media?.media_thumbnail],
          },
        })) || []
      return {
        ...parsedResponse,
        data: { ...data, media_cards: glimpsesOfServices },
      }

    case 'associated_temples':
      const associatedTemples =
        data?.media_cards?.map((item: any) => ({
          ...item,
          media: {
            ...item?.media,
            media_url: [item?.media?.media_url],
          },
        })) || []
      return {
        ...parsedResponse,
        data: { ...data, media_cards: associatedTemples },
      }

    case 'associated_pilgrims':
      const associatedPilgrims =
        data?.media_cards?.map((item: any) => ({
          ...item,
          media: {
            ...item?.media,
            media_url: [item?.media?.media_url],
          },
        })) || []
      return {
        ...parsedResponse,
        data: { ...data, media_cards: associatedPilgrims },
      }

    case 'previous_order_recommendation_card':
      const previousOrder =
        data?.banner_large_items?.map((item: any) => ({
          ...item,
          media: {
            ...item?.media,
            media_url: [item?.media?.media_url],
            media_thumbnail: [item?.media?.media_thumbnail],
          },
        })) || []
      return {
        ...parsedResponse,
        data: { ...data, banner_large_items: previousOrder },
      }

    case 'banner_large':
      const bannerLarge =
        data?.banner_large?.map((item: any) => ({
          ...item,
          media: {
            ...item?.media,
            media_url: [item?.media?.media_url],
            media_type: item?.media?.media_type,
          },
        })) || []
      return {
        ...parsedResponse,
        data: { ...data, banner_large: bannerLarge },
      }

    case 'highlighted_pooja':
      const highlightedPooja =
        data?.highlighted_pooja?.map((item: any) => ({
          ...item,
          media: {
            ...item?.media,
            media_url: [item?.media?.media_url],
            media_type: item?.media?.media_type,
          },
        })) || []
      return {
        ...parsedResponse,
        data: { ...data, highlighted_pooja: highlightedPooja },
      }

    default:
      return parsedResponse
  }
}
