import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { PlusSquareOutlined } from '@ant-design/icons'
import { Card, Col, Pagination, Select, Space, Spin } from 'antd'
import OrderInfoContainer from '@a4b/ui/src/modules/monetisation/molecules/OrderInfoContainer'
import TempleListItemInfo from './TempleListItemInfo'
import { STORE_SERVICE_TYPE, Store, Stores } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { Input } from 'antd'

const { Search } = Input
interface FiltersType {
  serviceType: STORE_SERVICE_TYPE | undefined
  storeId: string | undefined
}
interface Props {
  data: Stores | undefined
  hubId: string
  hubName: string | null
  paginationOnChange: (page: number, pageSize: number) => void
  currentPage: number
  totalRecords: number
  dataRecords: Store[]
  showAddNewTemple: boolean
  pageSize: number,
  updateFilters: (type: string, value: string) => void
  filters: FiltersType
  isLoading: boolean
  getHubManagersList: () => void
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const ButtonContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: start;
`
const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 100%;
`
const TempleListPage: React.FC<Props> = ({
  data,
  hubName,
  hubId,
  paginationOnChange,
  currentPage,
  totalRecords,
  dataRecords,
  showAddNewTemple,
  pageSize,
  updateFilters,
  filters,
  isLoading,
  getHubManagersList
}) => {

  const refreshTempleListingData = async () => {
    getHubManagersList()
  }

  return (
    <Container
      title={<div style={{ display: "flex", justifyContent: "space-between" }}>
        {`Hub Name : ${hubName}`}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '100%', marginRight: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <label style={{ marginRight: '5px' }}> Search By: </label>
            <Search
              placeholder='Store Id'
              style={{ marginRight: '10px', width: '400px' }}
              onSearch={(value: string) => {
                updateFilters('storeId', value)
              }}
              // value={filters?.storeId}
              allowClear
            />
          </div>
          <div style={{ width: '100%' }}>
            {/* <label style={{ marginRight: '5px' }}> Filters: </label> */}
            <Select style={{ width: '150px' }} value={filters?.serviceType} allowClear placeholder="Service type" defaultValue="offerings" onChange={(value: string) => {
              updateFilters('serviceType', value)
            }}>
              <Select.Option value="pooja">Pooja</Select.Option>
              <Select.Option value="offerings">Offering</Select.Option>
            </Select>
          </div>
        </div>
      </div>}
      style={{ width: '100%', height: '100%' }}
    >
      <Space direction={'vertical'} style={{ width: '100%' }}></Space>
      {
        isLoading ?
          <Spin /> :
          <OrderInfoContainer
            activeBatchesCount={data?.total_active_batches || 0}
          ></OrderInfoContainer>
      }
      {showAddNewTemple && (
        <ButtonContainer>
          <Link to='/monetisation/offerings/temple/create'>
            <PlusSquareOutlined /> Add new Temple
          </Link>
        </ButtonContainer>

      )}
      {
        isLoading ?
          <Col style={{ width: '100%', minHeight: '50vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Spin />
          </Col> :
          <>
            {dataRecords.map((item: Store) => (
              <TempleListItemInfo
                data={item}
                key={item.id}
                link={`/monetisation/offerings/hub/${hubId}/temple/${item.id}/batch?name=${item.name}`}
                onBatchGenerated={refreshTempleListingData}
              />
            ))}
            <LabelRightAlign>
              <Pagination
                defaultPageSize={pageSize}
                defaultCurrent={1}
                current={currentPage}
                total={totalRecords}
                onChange={paginationOnChange}
                showSizeChanger
              />
            </LabelRightAlign>
          </>
      }
    </Container>
  )
}

export default TempleListPage
