import React, { useState } from 'react'
import styled from 'styled-components'
import { CheckCircleOutlined, CloseCircleOutlined, PlusSquareOutlined } from '@ant-design/icons'
import { Button, Card, Col, Input, Space, Table, Image, Spin } from 'antd'
import { SlpFilters } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { renderDateTime } from '@/components/TableHelpers/ColumnHelper'

interface Props {
    loading: boolean
    onCreateNew: () => void
    triggerEdit: (filter: SlpFilters) => void
    dataSource: any[]
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`

const ManageFiltersPage: React.FC<Props> = ({
    loading,
    onCreateNew,
    triggerEdit,
    dataSource,
}) => {
    const [searchFilter, setSearchFilter] = useState<string>('')

    const filteredFilters = dataSource?.filter(data =>
        data?.name?.toLowerCase()?.includes(searchFilter?.toLowerCase())
    )

    const filterByNameInput = () => {
        return <Input
            placeholder="Search by name"
            value={searchFilter}
            onChange={(e: any) => {
                setSearchFilter(e?.target?.value);
            }}
            size="small"
            style={{ width: '130px', marginTop: '10px', height: '30px' }}
        />
    };

    const dataColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: '14%',
            ellipsis: true,
            render: (text: any,) => {
                return <span>{text}</span>
            },
        },
        {
            title: <div> Name <br></br> {filterByNameInput()}</div>,
            dataIndex: 'name',
            key: 'name',
            width: '15%',
            ellipsis: true,
            render: (text: any,) => {
                return <span>{text}</span>
            },
        },
        {
            title: 'Display name',
            dataIndex: 'display_names',
            key: 'display_names',
            width: '16%',
            render: (displayName: any) => {
                return <span>{displayName?.en}</span>;
            },
        },
        {
            title: 'Media',
            dataIndex: ['media', 'media_url'],
            key: 'media_url',
            width: '12%',
            render: (text: any,) => {
                return <Image width={50} height={50} src={text}></Image>
            },
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            width: '9%',
            render: (active: boolean) => {
                return active ? (
                    <CheckCircleOutlined style={{ color: 'green' }} />
                ) : (
                    <CloseCircleOutlined style={{ color: 'red' }} />
                );
            },
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
            width: '9%'
        },
        {
            title: "Created at",
            dataIndex: 'created_at',
            render: renderDateTime,
            key: 'created_at',
            width: 150,
        },
        {
            title: 'Edit',
            key: 'edit',
            width: '9%',
            render: (text: any, record: any,) => (
                <Col span={12}>
                    <Button type='link' onClick={() => triggerEdit(record)}>
                        Edit
                    </Button>
                </Col>
            ),
        }
    ]
    return (
        <Spin spinning={loading}>
            <Container title='Manage Filters' style={{ width: '100%' }}>
                <Space direction={'vertical'} style={{ width: '100%' }}></Space>
                <BodyContainer>
                    <Button type='text' onClick={onCreateNew}>
                        <PlusSquareOutlined /> Create Filter
                    </Button>
                    <Table
                        columns={dataColumns}
                        dataSource={filteredFilters}
                        pagination={{ defaultPageSize: 8 }}
                    />
                </BodyContainer>
            </Container>
        </Spin>
    )
}

export default ManageFiltersPage
