import { Form, Input, Row } from "antd"
import React from "react"
import Categories from "./categories"

interface Props {
    type: string
}

const TopCategoriesStatic: React.FC<Props> = ({ type }) => {
    return (
        <div style={{
            border: '1px dashed #e0dcdc',
            marginRight: '0px',
            width: '100%',
            padding: '10px',
        }}>
            <div style={{ marginTop: '20px' }}>
                <strong>Top Categories Static:</strong>
                <Form.Item
                    label={`Title`}
                    name={['data', 'title']}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ flexBasis: '40%', marginTop: '20px' }}
                    rules={[
                        {
                            required: false,
                            message: 'Enter Title',
                        },
                    ]}
                >
                    <Input placeholder='Enter Title' />
                </Form.Item>
                <Form.List name={['data', 'categories']}>
                    {(fields, { add, remove, move }) => (
                        <>
                            <Row justify="space-between" style={{ width: '100%', marginTop: '10px' }}>
                                <Categories
                                    onSortEnd={({ oldIndex, newIndex }) => {
                                        console.log('oldIndex', oldIndex, 'newIndex', newIndex)
                                        move(oldIndex, newIndex)
                                    }}
                                    distance={1}
                                    helperClass='sortableHelper'
                                    fields={fields}
                                    add={add}
                                    remove={remove}
                                    datatype='Top category static'
                                />
                            </Row>
                        </>
                    )}
                </Form.List>
            </div>
        </div>
    )
}

export default TopCategoriesStatic