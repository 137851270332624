import React from 'react';
import {
    Button,
    Card,
    Row,
} from 'antd';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';

interface Props {
    setShowCategoryDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
    padding: 0px
  }
`;

const CategoryListHeader: React.FC<Props> = ({
    setShowCategoryDrawer
}) => {
    const [searchParams, setSearchParams] = useSearchParams()

    return (
        <Container
            title={
                <Row style={{ width: '100%', fontSize: '15px' }}>
                    Manage categories
                </Row>
            }
            style={{ width: '100%', marginBottom: '20px' }}
            extra={
                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Button ghost type='primary' onClick={() => {
                        const newSearchParams = new URLSearchParams(searchParams.toString())
                        newSearchParams.set('edit', 'false')
                        setSearchParams(newSearchParams)
                        setShowCategoryDrawer(true)
                    }}>
                        Create category
                    </Button>
                </Row>
            }
        />
    );
};

export default CategoryListHeader;
