import { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'

class SlpFiltersApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getFilters = () => (
    this.api.get<any>(`/gw2/yoda/internal/v1/filters`)
  )

  createFilters = (payload: any) => (
    this.api.post<any>(`/gw2/yoda/internal/v1/filters`, payload)
  )

  updateFilters = (payload: any, filter_id: any) => (
    this.api.put<any>(
      `/gw2/yoda/internal/v1/filters/${filter_id}`,
      payload,
    )
  )
}

export default SlpFiltersApi