import { AxiosRequestConfig } from 'axios'

import AgentApi from '../Astrologer/Agent'
import PagesApi from '../Astrologer/Pages'
import ReviewsApi from '../Astrologer/Reviews'
import SectionsApi from '../Astrologer/Sections'
import BatchApi from './BatchApi'
import CampaignsApi from './Campaigns'
import FaqsApi from './FaqsApi'
//api
import HubApi from './Hub'
import OrdersApi from './Orders'
import PageInfoApi from './PageInfo'
import Product from './Product'
import ReviewApi from './ReviewApi'
import SchedulerApi from './Scheduler'
import StepsApi from './StepsApi'
import StoresApi from './StoresApi'
import SystemApi from './System'
import TutorialApi from './Tutorials'
import UserApi from './UserApi'
import UserSegmentsApi from './UserSegmentsApi'
import TemplesApi from './TemplesApi'
import SlpFiltersApi from './SlpFiltersApi'

class MonetisationOfferingsApi {
  hub: HubApi
  storesApi: StoresApi
  productApi: Product
  pageInfoApi: PageInfoApi
  tutorialApi: TutorialApi
  faqsApi: FaqsApi
  stepsApi: StepsApi
  batchApi: BatchApi
  reviewApi: ReviewApi
  orderApi: OrdersApi
  systemApi: SystemApi
  campaignsAPi: CampaignsApi
  userSegmentsApi: UserSegmentsApi
  schedulerApi: SchedulerApi
  agentApi: AgentApi
  pagesApi: PagesApi
  reviewsApi: ReviewsApi
  sectionsApi: SectionsApi
  templesApi: TemplesApi
  slpFilterApi: SlpFiltersApi

  constructor(
    config: AxiosRequestConfig,
    monetisationExtraHeaders?: {
      'Accept-Language': string
    },
  ) {
    // Updating headers for monetization
    config.headers = { ...config.headers, ...monetisationExtraHeaders }

    this.hub = new HubApi(config)
    this.storesApi = new StoresApi(config)
    this.productApi = new Product(config)
    this.pageInfoApi = new PageInfoApi(config)
    this.tutorialApi = new TutorialApi(config)
    this.faqsApi = new FaqsApi(config)
    this.stepsApi = new StepsApi(config)
    this.batchApi = new BatchApi(config)
    this.reviewApi = new ReviewApi(config)
    this.orderApi = new OrdersApi(config)
    this.systemApi = new SystemApi(config)
    this.campaignsAPi = new CampaignsApi(config)
    this.userSegmentsApi = new UserSegmentsApi(config)
    this.schedulerApi = new SchedulerApi(config)
    this.agentApi = new AgentApi(config)
    this.pagesApi = new PagesApi(config)
    this.reviewsApi = new ReviewsApi(config)
    this.sectionsApi = new SectionsApi(config)
    this.templesApi = new TemplesApi(config)
    this.slpFilterApi = new SlpFiltersApi(config)
  }
}

export default MonetisationOfferingsApi
